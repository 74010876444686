import React, { useState } from 'react'
import { IconButton, Box } from '@material-ui/core'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import SwipeableViews from 'react-swipeable-views'

import { useStyles } from './Carousel.styles'


interface CarouselProps {
    items: JSX.Element[]
}

const Carousel: React.FC<CarouselProps> = (props) => {
    const { items } = props
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)

    const handleLeftArrowClick = () => {
        setActiveStep(activeStep > 0 ? activeStep - 1 : activeStep)
    }

    const handleRightArrowClick = () => {
        setActiveStep(activeStep < items.length - 1 ? activeStep + 1 : activeStep)
    }

    const handleStepChange = (step: number) => {
        setActiveStep(step)
    }
    
    return (
        <Box className={classes.panel} display="flex" flexDirection="row" alignItems="center">
            <IconButton disabled={activeStep === 0} onClick={handleLeftArrowClick}>
                <ArrowLeftIcon />
            </IconButton>
            <SwipeableViews
                axis="x"
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {items}
                {/* {items.map((step, index) =>  Math.abs(activeStep - index) <= 2 ? step : null)} */}
            </SwipeableViews>
            <IconButton disabled={activeStep === items.length - 1} onClick={handleRightArrowClick}>
                <ArrowRightIcon />
            </IconButton>
        </Box>
    )
}

export default Carousel
