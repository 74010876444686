import { FormControl, InputLabel } from "@material-ui/core"
import Select, { SelectProps as MuiSelectProps } from '@material-ui/core/Select'
import React from "react"
import { ECInput } from "./ECTextField"

type ECSelectProps = MuiSelectProps & {
    label?: string
    helperText?: string
}

const ECSelect: React.FC<ECSelectProps> = ({
    id,
    label,
    className,
    ...props
}) => {
    
    return (
        <FormControl className={className} fullWidth={true}>
            <InputLabel shrink id={`${id}.label`}>{label}</InputLabel>
            <Select
                id={id}
                key={id}
                {...props}
                input={<ECInput />}
            >
                {props.children}
            </Select>
        </FormControl>
    )
}

export default ECSelect
