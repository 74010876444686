import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Divider, IconButton } from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'

import SubCategory from 'models/SubCategory'
import Category from 'models/Category';
import { useStyles } from './SubCategoryView.styles'

interface SubCategoryViewProps {
    categories: SubCategory[]
    onCategoryClick: (category: Category) => void
}

const SubCategoryView: React.FC<SubCategoryViewProps> = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    
    const handleArrowClick = () => {
        setOpen(!open)
    }

    const handleCategoryClick = (category: Category) => {
        props.onCategoryClick(category)
    }
    
    if (props.categories.length === 0) {
        return null
    }

    return (
        <Box className={classes.panel}>
            <Typography className={classes.title} variant="body1">Category</Typography>
            <IconButton className={classes.downArrow} onClick={handleArrowClick}>
                <ArrowDownIcon 
                    color="primary"
                    style={{
                        transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: '0.2s',
                    }}
                />
            </IconButton>
            <Divider />
            {open &&
                <List dense component="div" role="list">
                    {props.categories.map(category => (
                        <ListItem 
                            key={category.id} 
                            role="listitem" 
                            style={{cursor: 'pointer'}} 
                            onClick = {() => handleCategoryClick(category)}
                            disableGutters
                        >
                            <ListItemText primary={category.name} />
                            <Typography>({category.count})</Typography>
                        </ListItem>
                    ))}
                </List>
            }
        </Box>
    )
}

export default SubCategoryView
