import RestUtils from 'utils/RestUtils'
import Course from 'models/Course'

export interface PrivateCoursesListResponse {
    courses: Course[]
}

export async function getInterestingCourses(): Promise<PrivateCoursesListResponse> {
    const url = `/training/interestingcourses`
    const { data } = await RestUtils.get<PrivateCoursesListResponse>(url)
    return data
}
