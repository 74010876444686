import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { customTheme } from 'styles/baseTheme'

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        color: customTheme.headerTextColor,
        '& h4': {
            marginTop: '1rem',
            marginBottom: '0.5rem',
            fontWeight: 500
        }
    },
    row: {
        marginTop: '0.5rem'
    },
    label: {
        whiteSpace: 'nowrap',
        color: 'inherit',
        paddingLeft: '0.5rem'
    },
    value: {
        whiteSpace: 'nowrap',
        color: 'inherit',
        fontWeight: 700,
        paddingLeft: '0.5rem'
    },
    separator: {
        color: 'inherit',
        fontWeight: 700,
        paddingLeft: '0.5rem'
    },
    courseImage: {
        padding: 16,
        backgroundColor: theme.palette.background.default,
        backgroundSize: 'contain',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        minWidth: 160,
        minHeight: 90,
        maxWidth: 180,
        boxShadow: 'none'
    },
}))
