import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginTop: 30,
    },
    section: {
        marginTop: 30
    },
    grid: {
        marginTop: 0
    }
}))
