import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Box, Container, Typography } from '@material-ui/core'
import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { fetchInterestingCourses } from 'slices/registerInterestSlice';
import AppHeader from 'components/widgets/AppHeader'
import CompactCourseListView from 'components/courses/CompactCourseListView'
import CourseSummary from 'models/CourseSummary'

interface RegisterInterestPageProps { }

const RegisterInterestPage: React.FC<RegisterInterestPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const [loadingCourses, setLoadingCourses] = useState(false)
    const courses = useSelector(
        (state: RootState) => state.interestingCourses.courses
    )
    const handleInterest = (course: CourseSummary) => {
        history.push(`/register/course`, { item: { course: course ,type: "RegisterInterest" }});
    }

    useEffect(() => {
        const loadAsync = async () => {
            setLoadingCourses(true)
            await dispatch(fetchInterestingCourses())
            setLoadingCourses(false)
        }
        loadAsync()
    }, [dispatch])

    function CourseListView() {
        return (
            <>
                <AppHeader>
                    <Typography style={{ textAlign: 'center' }} variant="h3">Register Interest in Courses</Typography>
                </AppHeader>
                <Box component="main">
                    <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                        {loadingCourses ? (
                            <LoadingWidget />
                        ) : (
                            <CompactCourseListView courses={courses} courseCount={courses.length} courseAction="Interested" onCourseClick={handleInterest} />
                        )}
                    </Container>
                </Box>
            </>
        );
    }

    return CourseListView();
}

export default RegisterInterestPage