import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Typography } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer';
import { fetchMembers } from 'slices/memberSlice';
import Config from 'config/Config'

import TopAppBar from 'components/widgets/TopAppBar'
import AppFooter from 'components/widgets/AppFooter'
import GoogleAnalyticsWidget from 'components/widgets/GoogleAnalyticsWidget'
import TrainingPage from 'screens/TrainingPage'
import CategoryPage from 'screens/CategoryPage'
import FeatureSheetPage from 'screens/FeatureSheetPage'
import CartPage from 'screens/CartPage'
import LoginPage from 'screens/LoginPage'
import ProfilePage from 'screens/ProfilePage'
import ChangePasswordPage from 'screens/ChangePasswordPage'
import RegisterInterestPage from 'screens/RegisterInterestPage'

import TransactionReportPage from 'screens/TransactionReportPage'
import TransactionDetailReportPage from 'screens/TransactionDetailReportPage'
import CreateContactPage from 'screens/CreateContactPage'
import ManageContactsPage from 'screens/ManageContactsPage'
import ShippingContactsPage from 'screens/ShippingContactPage'
import ThankYouPage from 'screens/ThankYouPage'
import OrderFinishPage from 'screens/OrderFinishPage'
import ContentPage from 'screens/ContentPage'
import HomePage from 'screens/HomePage'
import BundlePage from 'screens/BundlePage'
import BundleSummaryPage from 'screens/BundleSummaryPage'
import CreateBundlePage from 'screens/CreateBundlePage'
import CourseRequestPage from 'screens/CourseRequestPage'
import { fetchSettings } from 'slices/lookupsSlice'
import { fetchContent } from 'slices/contentSlice'
import { fetchOrder } from 'slices/orderSlice'
import PrivateCoursePage from 'screens/PrivateCoursePage'
import PrivateEventPage from 'screens/PrivateEventPage'
import SFReportListPage from 'screens/SFReportListPage'
import SFReportPage from 'screens/SFReportPage'
import DocumentsPage from 'screens/DocumentsPage'
import TrainingResourcesPage from 'screens/TrainingResourcesPage'
import LoadingWidget from 'components/widgets/LoadingWidget'

interface AppProps {}

const App: React.FC<AppProps> = (props) => {
    const dispatch = useThunkDispatch()
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const custom = useSelector(
        (state: RootState) => state.content.custom
    )
    
    useEffect(() => {
        if (member == null) {
            console.log("Checking for member...")
            dispatch(fetchMembers())
        }
    }, [dispatch, member])

    console.log("MEMBER", member)

    useEffect(() => {
        console.log("Loading settings...")
        dispatch(fetchSettings())
    }, [dispatch])

    useEffect(() => {
        console.log("Loading cart...")
        dispatch(fetchOrder())
    }, [dispatch])

    console.log("SETTINGS", settings);

    useEffect(() => {
        dispatch(fetchContent())
    }, [dispatch])

    const customContent = custom != null ? custom["HomePage"] : null
    const startPage = settings.reseller.portalType === "Reporting" ? SFReportListPage : customContent != null ? HomePage : TrainingPage

    // Wait until we know what portal type we have before rendering anything
    if (!settings.reseller.portalType) {
        return <LoadingWidget/>
    }
    
    const StartRoute = (props: {exact: boolean, path: string, redirect: string | undefined}) => {
        if (props.redirect) {
            return (
                <Route exact path={props.path} >
                    <Redirect to={props.redirect+''} />
                </Route>
            )
        } else {
            return (<Route exact path={props.path} component={startPage} />)
        }
    }

    return (
        <BrowserRouter basename={Config.BaseName}>
            <GoogleAnalyticsWidget />
            <React.Fragment>
                <TopAppBar/>
                <Switch>
                    <StartRoute exact path="/" redirect={settings.reseller.portalHomePage} />
                    <Route exact path={["/it-training", "/training"]} component={TrainingPage} />
                    <Route path="/(it-)?training/private-courses" component={PrivateCoursePage} />
                    <Route path="/(it-)?training/private-events/:courseId*" component={PrivateEventPage} />
                    <Route path="/(it-)?training/bundles/:courseId*" component={BundlePage} />
                    <Route path="/(it-)?training/:coursePath(.*)/:coursePrefix(.*)\-:courseId(\d+)\-detail.html" component={FeatureSheetPage} />
                    <Route path="/(it-)?training/:categoryId(\d+)" component={CategoryPage} />
                    <Route path="/(it-)?training/:urlSegment*" component={CategoryPage} />
                    <Route path="/(it-)?training-resources/:values*" component={TrainingResourcesPage} />
                    <Route path="/order/bundle/summary/:bundleId/:hashId?" component={BundleSummaryPage} />
                    <Route path="/order/bundle/:bundleId" component={CreateBundlePage} />
                    <Route path="/order/finish/:orderId?/:orderHash?" component={OrderFinishPage} />
                    <Route path="/order" component={CartPage} />
                    <Route path="/search" component={CategoryPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/account" component={ProfilePage} />
                    <Route path="/password" component={ChangePasswordPage} />
                    <Route path="/reports/transactions/:transactionId" component={TransactionDetailReportPage} />
                    <Route path="/reports/transactions?page=:page" component={TransactionReportPage} />
                    <Route path="/reports/transactions" component={TransactionReportPage} />
                    <Route path="/reports/:reportId" component={SFReportPage} />
                    <Route path="/documents" component={DocumentsPage} />
                    <Route path="/reports" component={SFReportListPage} />
                    <Route path="/contact" component={CreateContactPage} />
                    <Route path="/contacts" component={ManageContactsPage} />
                    <Route path="/shipping" component={ShippingContactsPage} />
                    <Route path="/content/:urlSegment" component={ContentPage} />
                    <Route path="/requests/course" component={CourseRequestPage} />
                    <Route path="/register/interest" component={RegisterInterestPage} />
                    <Route path="/register/course" component={CourseRequestPage} />
                    <Route path="/thankyou" component={ThankYouPage} />
                    <Route render={() => <Typography align="center" variant="h5" color="primary">Not Found</Typography>} />
                </Switch>
                <AppFooter/>
            </React.Fragment>
        </BrowserRouter>
    )
}

export default App
