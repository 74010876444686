import React from 'react';
import { Box, Typography, TypographyProps } from '@material-ui/core';

import CourseEvent, { DeliveryFormat } from 'models/CourseEvent'
import Popper from 'components/widgets/Popper'

interface EventPopperProps extends TypographyProps {
    event: CourseEvent
}

const EventPopper: React.FC<EventPopperProps> = (props) => {
    const { event, ...otherProps } = props

    const mvpContent = () => {
        return (
            <Popper {...otherProps} text="Remote Classroom">
                <Typography variant="h6" color="primary">Remote Classroom</Typography>
                <ul>
                    <li>Attend a live instructor-led class from an ExitCertified facility close to you - no matter where the instructor is located</li>
                    <li>Experience a fully interactive class with seamless live 2-way audio and video communication</li>
                    <li>Eliminate the travel time and costs associated with training in a different city</li>
                    <li>Experience hands-on labs with real-time support</li>
                    <li>Share ideas and questions with your fellow classmates in real time</li>
                </ul>
            </Popper>
        )
    }

    const iMvpContent = () => {
        return (
            <Popper {...otherProps} text="Live Virtual">
                <Typography variant="h6" color="primary">Live Virtual</Typography>
                <ul>
                    <li>Attend a live instructor-led class from the convenience of your office or home</li>
                    <li>Experience a fully interactive class with seamless live 2-way audio and video communication</li>
                    <li>Eliminate travel time and costs by joining a live instructor-led class from your office or home</li>
                    <li>Experience hands-on labs with real-time support</li>
                    <li>Share ideas and questions with your fellow classmates in real time</li>
                </ul>
            </Popper>
        )
    }

    const locationForEvent = (event: CourseEvent) => {
        if (event.deliveryFormat === DeliveryFormat.iMVP) {
            return iMvpContent()
        }

        return (
            <React.Fragment>
                {event.deliveryFormat === DeliveryFormat.MVP &&
                    <React.Fragment>
                        {mvpContent()}
                    </React.Fragment>
                }
                <Popper {...otherProps} text={event.locationName || event.city}>
                    <Typography variant="h6" color="primary">{event.city}</Typography>
                    <Box style={{marginTop: 20}}>
                        <Typography>{event.address}</Typography>
                        <Typography>{event.city}, {event.state}</Typography>
                        <Typography>{event.postalCode}</Typography>
                    </Box>
                    <Box style={{ marginTop: 20 }}>
                        <Box component="span" display="flex">
                            <Typography style={{fontWeight: 700}}>Telephone:</Typography>
                            &nbsp;
                            <Typography>{event.phone}</Typography>
                        </Box>
                        <Box component="span" display="flex">
                            <Typography style={{ fontWeight: 700 }}>Toll Free:</Typography>
                            &nbsp;
                            <Typography>{event.tollFree}</Typography>
                        </Box>
                    </Box>
                </Popper>
            </React.Fragment>
        )
    }

    return (
        <Box display="flex" flexDirection="column">
            {locationForEvent(event)}
        </Box>
    )
}

export default EventPopper
