import { combineReducers } from '@reduxjs/toolkit'

import addonsReducer from 'slices/addonSlice'
import categoriesReducer from 'slices/categoriesSlice'
import courseListReducer from 'slices/courseListSlice'
import courseReducer from 'slices/courseSlice'
import courseEventListReducer from 'slices/courseEventListSlice'
import orderReducer from 'slices/orderSlice'
import memberReducer from 'slices/memberSlice'
import contactListReducer from 'slices/contactListSlice'
import lookupsReducer from 'slices/lookupsSlice'
import paymentInfoReducer from 'slices/paymentInfoSlice'
import paymentReducer from 'slices/paymentSlice'
import paymentDataReducer from 'slices/paymentDataSlice'
import cartSubmitReducer from 'slices/cartSubmitSlice'
import selectionsReducer from 'slices/selectionsSlice'
import contentReducer from 'slices/contentSlice'
import reportReducer from 'slices/reportsSlice'
import bundleReducer from 'slices/bundleSlice'
import privateCoursesReducer from 'slices/privateCoursesSlice'
import privateEventsReducer from 'slices/privateEventsSlice'
import trainingResourcesReducer from 'slices/trainingResourcesSlice'
import registerInterestReducer from 'slices/registerInterestSlice';
import errorReducer from 'slices/errorSlice';

const rootReducer = combineReducers({
    addons: addonsReducer,
    categories: categoriesReducer,
    content: contentReducer,
    courseList: courseListReducer,
    course: courseReducer,
    courseEventList: courseEventListReducer,
    order: orderReducer,
    member: memberReducer,
    contacts: contactListReducer,
    lookups: lookupsReducer,
    paymentInfo: paymentInfoReducer,
    payment: paymentReducer,
    paymentData: paymentDataReducer,
    cartSubmit: cartSubmitReducer,
    selections: selectionsReducer,
    reports: reportReducer,
    bundles: bundleReducer,
    privateCourses: privateCoursesReducer,
    privateEvents: privateEventsReducer,
    trainingResources: trainingResourcesReducer,
    interestingCourses: registerInterestReducer,
    error: errorReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
