import React, { useEffect } from 'react'

interface FormStatusLoggerProps {
    values: any,
    errors: any
}

// This component is purely for debugging!
// With form validation it's possible that the form will be in error state
// but the error will not be visible on screen.
// This component will allow you to see the error state in the console.
// The component will only log when the error state changes

const FormStatusLogger: React.FC<FormStatusLoggerProps> = (props) => {
    
    // useEffect(() => {
    //     console.log("FORM VALUES", props.values)
    // }, [props.values])

    useEffect(() => {
        if (Object.keys(props.errors).length > 0) {
            console.log("FORM ERRORS", props.errors) 
        }
    }, [props.errors])

    return null
}

export default FormStatusLogger
