import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Container, Typography, Button } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { fetchBundles } from 'slices/bundleSlice'

interface BundlePageParams {
    courseId: string
}

interface BundlePageProps { }

const BundlePage: React.FC<BundlePageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const { courseId } = useParams()
    const bundles = useSelector(
        (state: RootState) => state.bundles.bundles
    )
    const [loadingBundles, setLoadingBundles] = useState(false)

    useEffect(() => {
        const loadAsync = async () => {
            setLoadingBundles(true)
            await dispatch(fetchBundles(courseId))
            setLoadingBundles(false)
        }
        loadAsync()
    }, [dispatch, courseId])

    const handleCustomizeBundle = (bundleId: number) => {
        history.push(`/order/bundle/${bundleId}`)
    }

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                <Typography style={{ textTransform: 'uppercase', textAlign: 'center' }} color="primary" variant="h3">Available Bundles</Typography>
                {loadingBundles ? (
                    <LoadingWidget />
                ) : (
                    bundles.map(bundle =>
                        <React.Fragment key={bundle.id}>
                            <Typography style={{ marginTop: 30, marginBottom: '2rem' }} variant="h4" color="primary">{bundle.name}</Typography>
                            <Box style={{ paddingBottom: '2rem', borderBottom: '2px solid #6a6a6a' }}>
                                <Typography component="p" variant="body1">{bundle.description}</Typography>
                                <Typography style={{ marginTop: '2rem', marginBottom: '1rem' }} variant="h6">Courses</Typography>
                                {bundle.allCourses.map(course =>
                                    <Typography style={{paddingTop: 4}} key={course.id} variant="body1" color="primary">{`${course.name} (${course.code})`}</Typography>
                                )}
                                <Button style={{ marginTop: '2rem' }} variant="contained" color="primary" onClick={e => handleCustomizeBundle(bundle.id)}>Customize Bundle</Button>
                            </Box>
                        </React.Fragment>
                    )
                )}
            </Container>
        </Box>
    )
}

export default BundlePage
