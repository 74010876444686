import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Container, Typography } from '@material-ui/core'
import { customTheme } from 'styles/baseTheme'

import { RootState } from 'app/rootReducer'
import AppHeader from 'components/widgets/AppHeader'
import AppCustomFooter from 'components/widgets/AppCustomFooter'
import BrandSearchGrid from 'components/training/BrandSearchGrid'
import BrandGrid from 'components/training/BrandGrid'
import SearchResults from 'components/training/SearchResults'

interface TrainingPageProps { }

const TrainingPage: React.FC<TrainingPageProps> = (props) => {
    const history = useHistory()
    const location = useLocation()
    const categories = useSelector(
        (state: RootState) => state.categories
    )
    const custom = useSelector(
        (state: RootState) => state.content.custom
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const [showAllBrands, setShowAllBrands] = useState(location.state?.showAllBrands)

    const handleSearch = (searchTerms: string) => {
        history.push(`/search?terms=${searchTerms}`)
    }

    const handleShowAllBrandsClick = () => {
        setShowAllBrands(!showAllBrands)
    }

    const customContent = custom != null ? custom["TrainingPage"] : null

    return (
        <>
            <AppHeader style={(customContent && customContent.backgroundImage) ? { backgroundImage: 'url(' + customContent.backgroundImage + ')' } : {}}>
                <Container maxWidth="lg" fixed={true}>
                    {custom != null && ( // custom is null until load attempt has completed. Don't render anything until we know for sure whether we have content
                        customContent && customContent.header ? (
                            <Box dangerouslySetInnerHTML={{ __html: customContent.header }} />
                        ) : (
                            <React.Fragment>
                                <Typography variant="h3" style={{ color: customTheme.headerTitleColor }}>Welcome!</Typography>
                                <Typography variant="body1" style={{ color: customTheme.headerTextColor }}>
                                    When you register for training, you're getting authorized content from the world's leading IT vendors. Taught by award-winning instructors, you'll receive superior training that leaves with the right skills to benefit your organization — and your career.
                                </Typography>
                            </React.Fragment>
                        )
                    )}
                    <BrandSearchGrid showAllBrands={showAllBrands} onShowAllBrandsClick={handleShowAllBrandsClick} onSearch={handleSearch} />
                </Container>
            </AppHeader>
            {showAllBrands &&
                <Box component="main" style={{ margin: '4rem' }}>
                    <Container maxWidth="lg" fixed={true}>
                        <BrandGrid categories={categories} />
                    </Container>
                </Box>
            }
            {settings.reseller.showCoursesListOnTrainingPage &&
                <SearchResults showAll={true} hideSearchInput={true} />
            }
            <AppCustomFooter>
                <Container maxWidth="lg" fixed={true}>
                    <>
                        {custom != null && (
                            customContent && customContent.footer && <Box dangerouslySetInnerHTML={{ __html: customContent.footer }} />
                        )}
                    </>
                </Container>
            </AppCustomFooter>
        </>
    )
}

export default TrainingPage
