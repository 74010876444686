import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Button, Grid, Box } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { useStyles } from './ManageContactsListView.styles'
import { RootState } from 'app/rootReducer';
import { fetchContacts, removeContact } from 'slices/contactListSlice';
import { setError } from 'slices/errorSlice'
import SearchBox from 'components/widgets/SearchBox'
import ContactCard from './ContactCard'
import Contact from 'models/Contact'
import AlertDialog from 'components/widgets/AlertDialog'
import ErrorMessageWidget from 'components/widgets/ErrorMessageWidget';

interface ManageContactsProps { }

const ManageContactsListView: React.FC<ManageContactsProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
    const contacts = useSelector(
        (state: RootState) => state.contacts
    )

    useEffect(() => {
        dispatch(fetchContacts())
    }, [dispatch])

    const handleSearch = (searchterms: string) => {
        dispatch(setError("Search is not available right now"))
    }

    const handleCreateContact = () => {
        console.log("Create student")
        history.push("/contact")
    }

    const handleEditContact = (contact: Contact) => {
        console.log("Editing contact", contact.id)
        history.push("/contact", {contact})
    }

    const handleDeleteContact = (contact: Contact) => {
        setSelectedContact(contact)
    }

    const handleYesButton = () => {
        if (selectedContact) {
            console.log("Deleting contact", selectedContact.id)
            dispatch(removeContact(selectedContact))
            setSelectedContact(null)
        }
    }

    const handleNoButton = () => {
        setSelectedContact(null)
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} style={{marginTop: '2rem', marginBottom: '2rem'}}>
                <Grid item className={classes.searchBox} xs={12} sm={8}>
                    <SearchBox label="Search Students" style={{ marginTop: 0, marginBottom: 0 }} onSearch={handleSearch} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box display="flex" style={{height: '100%'}} flexDirection="column" justifyContent="flex-end">
                        <Button
                            className={classes.createButton}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleCreateContact}>
                            Create new student
                        </Button>
                    </Box>
                </Grid>
                {contacts.map(contact =>
                    <Grid key={contact.id} item xs={12} sm={4}>
                        <ContactCard contact={contact} onEditContact={handleEditContact} onDeleteContact={handleDeleteContact} />
                    </Grid>
                )}
            </Grid>
            <AlertDialog 
                open={!!selectedContact}
                title="Delete Contact"
                message={`Are you sure you want to delete ${selectedContact?.firstName} ${selectedContact?.lastName}?`}
                onButton1Clicked={handleNoButton} 
                onButton2Clicked={handleYesButton}
            />
            <ErrorMessageWidget />
        </React.Fragment>
    )
}

export default ManageContactsListView
