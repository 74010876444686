import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    grid: {
        marginTop: 20,
        alignItems: "flex-end"
    },
    row: {
        marginTop: 20,
    },
    leftCardButton: {
        marginLeft: -8
    },
    cardButton: {
        marginTop: 4
    },
    redButton: {
        color: theme.palette.error.main
    },
    blueButton: {
        color: theme.palette.info.main
    }
}))