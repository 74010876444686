import { createMuiTheme } from '@material-ui/core/styles';
import Config from 'config/Config'
import basetheme from './basetheme.json'

if (Config.Theme) {
    console.log("Using reseller theme", Config.Theme);
}

const baseTheme = createMuiTheme(Config.Theme ? Config.Theme : basetheme);

console.log("Base theme", baseTheme);

export const customTheme = Config.CustomTheme ? Config.CustomTheme : basetheme.custom;

export default baseTheme;
