import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@material-ui/core'

import { useStyles } from "./YourContactInfoForm.styles"
import { RootState } from 'app/rootReducer'
import config from 'config/Config'
import FormTextField from 'components/widgets/form/FormTextField'
import { YourContactInfo } from 'models/Billing'

export interface YourContactInfoValues extends YourContactInfo { }

interface YourContactInfoFormProps {
}

const YourContactInfoForm = React.forwardRef<HTMLDivElement, YourContactInfoFormProps>((props, ref) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const member = useSelector(
        (state: RootState) => state.member.member
    )

    const handleLoginButton = () => {
        if (window.location.hostname === 'localhost') {
            console.log("LLLLLL0", location)
            history.push("/login", { action: 'login', next: location })
        } else {
            window.location.href = config.BaseName + "login"
        }
    }

    return (
        <React.Fragment>
            { member == null &&
                <Box className={classes.section}>
                    <Typography variant="h6">Your Contact Info</Typography>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography variant="body1">Just in case we need to contact you directly.</Typography>
                        <Button style={{marginLeft: 8}} color="primary" onClick={handleLoginButton}>Login/Register</Button>
                    </Box>
                    <Grid container spacing={4} className={classes.grid}>
                        <Grid item xs={4}>
                            <FormTextField
                                name="me.firstName"
                                placeholder="First Name"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormTextField
                                name="me.lastName"
                                placeholder="Last Name"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormTextField
                                name="me.email"
                                placeholder="Email"
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        </React.Fragment>
    )
})

export default YourContactInfoForm
