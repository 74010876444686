import RestUtils, { excludeCamelCaseConfig } from 'utils/RestUtils'
import TrainingResource from 'models/TrainingResource'

export interface TrainingResourceResponse extends TrainingResource {}

export async function getTrainingResources(): Promise<TrainingResourceResponse> {
    const url = "/training/resources"

    const { data } = await RestUtils.get<TrainingResourceResponse>(url, null, excludeCamelCaseConfig(["TypeFilters", "BrandFilters"]))
    return data
}
