import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { PrivateCoursesListResponse, getInterestingCourses } from 'api/registerInterestApi'
import { setError } from './errorSlice'

export type RegisterInterestState = PrivateCoursesListResponse | null

const initialState: RegisterInterestState = {
    courses: []
}

const registerInterestSlice = createSlice({
    name: 'registerInterest',
    initialState,
    reducers: {
        setInterestingCourses(state: RegisterInterestState, action: PayloadAction<PrivateCoursesListResponse>) {
            return action.payload
        }
    }
})

const { setInterestingCourses } = registerInterestSlice.actions

export default registerInterestSlice.reducer

export const fetchInterestingCourses = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const courses = await getInterestingCourses()
        dispatch(setInterestingCourses(courses))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
