import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    allbrandsButton: {
        whiteSpace: 'nowrap',
        minWidth: 'max-content',
        '@media(min-width: 600px)' : {
            marginLeft: '4rem'
        }
    },
}))

