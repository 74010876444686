import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {

    },
    tableHeaders: {
        backgroundColor: theme.palette.primary.main,
        '& th': {
            color: theme.palette.common.white,
        }
    },
    tableBody: {
        '& tr:nth-child(odd)': {
            backgroundColor: theme.palette.grey[100]
        }
    }
}))
