import React from 'react'
import Card from '@material-ui/core/Card'
import { CardContent, Grid, GridSize, Typography, Box } from '@material-ui/core'

import { useStyles } from './TransactionDetailItemCard.styles'
import { formatCurrency } from 'utils/currency'
import { TransactionDetailItem } from 'models/TransactionDetail'

interface TransactionDetailItemCardProps {
    transactionDetailItems: TransactionDetailItem[]
}

const TransactionDetailItemCard: React.FC<TransactionDetailItemCardProps> = (props) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            {props.transactionDetailItems.map(item => {
                const details = [
                    { label: "ID", value: item.id, width: 1, style: {} },
                    { label: "Title", value: item.title, width: 5,style: {} },
                    { label: "Code", value: item.code, width: 2,style: {} },
                    { label: "Sub Total", value: `${formatCurrency(item.subTotal, 'CAD')}`, width: 2, style: {} },
                    { label: "Tax", value: `${formatCurrency(item.tax, 'CAD')}`, width: 2, style: {} },
                ]
                const contact = item.student.contact
                
                const addresses = [
                    {
                        name: "Contact Address",
                        details: [
                            { label: "First Name", value: contact.firstName, style: {} },
                            { label: "Last Name", value: contact.lastName, style: {} },
                            { label: "Email", value: contact.email, style: {} },
                            { label: "Phone", value: contact.phone, style: {} },
                            { label: "Company", value: contact.company, style: {} },
                            { label: "Street", value: contact.street, style: {} },
                            { label: "", value: "", style: {} },
                            { label: "", value: "", style: {} },
                            { label: "City", value: contact.city, style: {} },
                            { label: "State", value: contact.state, style: {} },
                            { label: "Country", value: contact.country, style: {} },
                            { label: "Zip", value: contact.zip, style: {} },
                        ]
                    }
                ]

                const shipping = item.student.shipping

                if (shipping) {
                    addresses.push({
                            name: "Shipping Address",
                            details: [
                                { label: "First Name", value: shipping.firstName, style: {} },
                                { label: "Last Name", value: shipping.lastName, style: {} },
                                { label: "Email", value: shipping.email, style: {} },
                                { label: "Company", value: shipping.company, style: {} },
                                { label: "Phone", value: shipping.phone, style: {} },
                                { label: "Street", value: shipping.street, style: {} },
                                { label: "", value: "", style: {} },
                                { label: "", value: "", style: {} },
                                { label: "City", value: shipping.city, style: {} },
                                { label: "State", value: shipping.state, style: {} },
                                { label: "Country", value: shipping.country, style: {} },
                                { label: "Zip", value: shipping.zip, style: {} },
                            ]
                        })
                }

                return (
                    < Card key={item.id} className={classes.container} >
                        <CardContent>
                            <Box display="flex" justifyContent='space-between'>
                                <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold' }} variant="overline">Transaction Item</Typography>
                            </Box>
                            <Grid container justify="flex-start" spacing={4}>
                                {details.map((detail, index) => (
                                    <Grid key={detail.label} item xs={5} sm={detail.width as GridSize}>
                                        <Box display="flex" flexDirection="column">
                                            <Typography component="p" variant="overline">{detail.label.toUpperCase()}</Typography>
                                            <Typography style={detail.style} component="p" variant="body1">{detail.value}</Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                            {addresses.map(address =>
                                <React.Fragment key={address.name}>
                                    <Box className={classes.address} display="flex" justifyContent='space-between'>
                                        <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold' }} variant="overline">{address.name}</Typography>
                                    </Box>
                                    <Grid container justify="flex-start" spacing={1}>
                                        {address.details.map((addressItem, index) => (
                                            <Grid key={addressItem.label+index} item xs={5} sm={3}>
                                                <Box display="flex" flexDirection="column">
                                                    <Typography component="p" variant="overline">{addressItem.label.toUpperCase()}</Typography>
                                                    <Typography style={addressItem.style} component="p" variant="body1">{addressItem.value}</Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Box className={classes.htmlSection} display="flex" justifyContent='space-between'>
                                <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold' }} variant="overline">Course Information</Typography>
                            </Box>
                            <Box dangerouslySetInnerHTML={{ __html: item.htmlDetails }} />
                        </CardContent>
                    </Card>
                )
            })}

        </React.Fragment>
    )
}

export default TransactionDetailItemCard
