import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    '@global': {
        a: {
            color: theme.palette.primary.main,
            textDecoration: 'underline'
        }
    },
    root: {
        minWidth: 400
    },
    text: {
    },
    dropdown: {
    },
    readonly: {
    },
    buttonSection: {
        marginTop: 10,
        marginBottom: 10
    }
}))
