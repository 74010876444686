import React from 'react'
import { Box, Card, CardActions, CardContent, CardMedia, Typography, Button} from '@material-ui/core'

import { Resource} from 'models/TrainingResource'
import { useStyles } from './TrainingResourceCard.styles'

interface TrainingResourceCardProps {
    resource: Resource
    onClick: () => void
}

const TrainingResourceCard: React.FC<TrainingResourceCardProps> = (props) => {
    const classes = useStyles()
    const { resource } = props

    return (
        <Card className={classes.root}>
            <Box className={classes.header}>
                <CardMedia
                    image={resource.imageUrl}
                    title={resource.title}
                    className={classes.media}
                >
                    <div className={classes.darkMask} />
                </CardMedia>
                <Typography className={classes.typeLabel}>{resource.type}</Typography>
            </Box>
            <CardContent className={classes.content}>
                <Typography variant="body1">{resource.title}</Typography>
            </CardContent>
            <CardActions>
                <Button onClick={props.onClick}>{resource.buttonTitle}</Button>
            </CardActions>
        </Card>
    )
}

export default TrainingResourceCard
