import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: 30,
    },
    title: {
        marginTop: 10,
    }
}))
