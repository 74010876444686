import React from "react"
import { Box, Typography } from "@material-ui/core"
import { TreeView, TreeItem } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FolderIcon from '@material-ui/icons/Folder'
import DocumentIcon from '@material-ui/icons/Description'

import ReportFolder from "models/ReportFolder"

interface DocumentTreeViewProps {
    folders: ReportFolder[]
    onDocumentClick: (documentId: string) => void
 }

const DocumentTreeView: React.FC<DocumentTreeViewProps> = (props) => {
    const { folders, onDocumentClick } = props

    const handleDocumentClick = (documentId: string) => {
        onDocumentClick(documentId)
    }

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            defaultExpanded={folders.map(folder => folder.name)}
        >
            {folders.map((folder, index) => 
                <TreeItem style={{marginTop: 8}} key={index} nodeId={folder.name} label={
                    <Box display="flex" alignItems="center">
                        <FolderIcon style={{marginRight: 8}} color="primary"/>
                        <Typography>{folder.name}</Typography>
                    </Box>
                }>
                    {folder.documents.map((document, index) => 
                        <TreeItem style={{marginLeft: 6}} key={index} nodeId={document.name} label={document.name} icon={<DocumentIcon color="primary"/>} onClick={e => handleDocumentClick(document.id)}/>
                    )}
                </TreeItem> 
            )}        
        </TreeView>
    )
}

export default DocumentTreeView
