import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle }from '@material-ui/core'

interface AlertDialogProps { 
    open: boolean
    title: string
    message: string
    button1Text?: string
    button2Text?: string
    onButton1Clicked?: () => void
    onButton2Clicked?: () => void
}

const defaultProps: AlertDialogProps = {
    open: false,
    title: "",
    message: "",
    button1Text: "No",
    button2Text: "Yes"   
}

const AlertDialog: React.FC<AlertDialogProps> = (props) => {
    const handleButton1 = () => {
        props.onButton1Clicked?.()
    }

    const handleButton2 = () => {
        props.onButton2Clicked?.()
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleButton1}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleButton1} color="primary">
                    {props.button1Text}
                </Button>
                <Button onClick={handleButton2} color="primary" autoFocus>
                    {props.button2Text}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AlertDialog.defaultProps = defaultProps

export default AlertDialog
