import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Button, Container, Typography } from '@material-ui/core'

import config from 'config/Config'

interface ThankYouProps { }

const ThankYouPage: React.FC<ThankYouProps> = (props) => {
    const location = useLocation()
    const { title, text } = location.state

    const handleButtonClick = () => {
        window.location.href = (config.BaseName ? config.BaseName : "/")
    }

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', alignItems: "center", marginTop: 150, marginBottom: '2rem' }}>
                <Typography variant="h3" color="primary">{title ?? "Thank You!"}</Typography>
                <Typography variant="body1">{text}</Typography>
                <Button style={{ marginTop: 50 }} color="primary" variant="contained" onClick={handleButtonClick}>Search For More Training</Button>
            </Container>
        </Box>
    )
}

export default ThankYouPage
