import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { RootState } from 'app/rootReducer';
import { useThunkDispatch } from 'app/store';
import { fetchAddons } from 'slices/addonSlice';
import PriceRenderer from 'components/widgets/PriceRenderer'
import Course from 'models/Course';

interface AddonWidgetProps {
    itemID: number,
    hasAddons: (has: boolean) => void
}

const AddonsWidget: React.FC<AddonWidgetProps> = (props) => {
    const { itemID } = props
    const dispatch = useThunkDispatch()
    const history = useHistory()

    const addons = useSelector(
        (state: RootState) => state.addons
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )
    const order = useSelector(
        (state: RootState) => state.order
    )
    useEffect(() => {
        const loadAsync = async () => {
            if (itemID) {
                await dispatch(fetchAddons(itemID));

            }
        }
        loadAsync()
    }, [dispatch, itemID])

    const enroll = ((course: Course) => {
        if (settings.reseller.contactToEnrollEmail) {
            history.push("/requests/course", { item: { course } })
        } else {
            history.push("/order", { item: { course } })
        }
    });

    const inCart = ((course: Course) => {
        var in_cart = false;
        order.items.forEach((cartItem) => {
            if (cartItem && cartItem.course && course.id && course.id === cartItem.course.id) {
                in_cart = true;
            }
        });
        return in_cart;
    });

    const isCourseAddon = ((addon: Course) => {
        var addon_cart = false;
        if (addon.addonParent === props.itemID) {
            addon_cart = true;
        }
        return addon_cart;
    });

    var show_addons = false;
    addons.addons.forEach(course => {
        show_addons = (!inCart(course) && isCourseAddon(course));
    });
    props.hasAddons(show_addons);

    return (
        <>
            {(addons.addons.map((course, index) =>
                !inCart(course) && isCourseAddon(course) && (
                    <Box>
                        <Grid item >

                            <Box component="span" display="flex">
                                <Typography variant="body1" color='primary'>{course.name}</Typography>
                                <Typography style={{ fontWeight: 700, marginLeft: 7 }} variant="body1" color='secondary'>({course.code})</Typography>
                            </Box>
                        </Grid>
                        {course.formats.map((format, index) =>
                            <Grid item>
                                <Box component="span" display="flex" style={{ marginBottom: 7, marginTop: 7, justifyContent: 'space-between' }}>
                                    <Typography style={{ fontWeight: 700, marginRight: 7, display: 'flex', alignItems: 'center' }} variant="body1">{format.name}:
                                        {settings.reseller.hideAllPricing
                                            ? (<Typography className={format.label}>Price Not Available</Typography>)
                                            : (<>
                                                {format.prices.map((price, index) => (
                                                    <PriceRenderer sx={{ display: 'inline', marginLeft: 5 }} key={index} isFree={course.isFree} price={price} />
                                                ))}
                                            </>)
                                        }
                                    </Typography>
                                    <Button variant="contained" color='primary' style={{ height: 36.5 }} onClick={() => { enroll(course) }}>Add</Button>
                                </Box>
                            </Grid>
                        )
                        }
                    </Box>
                )
            ))}
        </>
    )

}

export default AddonsWidget