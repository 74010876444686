import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { customTheme } from 'styles/baseTheme'

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        color: customTheme.contentFooterTextColor,
        backgroundColor: customTheme.contentFooterColor,
        padding: '2rem'
    }
}))
