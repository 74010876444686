import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Container, Typography } from '@material-ui/core'

import { RootState } from 'app/rootReducer'
import ContactForm from 'components/contacts/ContactForm'
import NotificationContactsEditor from 'components/members/NotificationContactsEditor'
import LoadingWidget from 'components/widgets/LoadingWidget'

interface ProfilePageProps { }

const ProfilePage: React.FC<ProfilePageProps> = (props) => {
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column' }}>
                {member ?
                    <React.Fragment>
                        {settings.reseller.showAccountBillingForm && <ContactForm showBilling />}
                        {settings.reseller.enableNotificationContacts &&
                            <React.Fragment>
                                <Typography style={{marginTop: '2rem'}} variant="h6" color="primary">Notification Contacts</Typography>
                                <NotificationContactsEditor />
                            </React.Fragment>
                        }
                    </React.Fragment>
                :
                    <LoadingWidget />
                }
            </Container>
        </Box>
    )
}

export default ProfilePage
