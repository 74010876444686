import React from 'react';
import { Button, Card, CardContent, Grid, Typography, Box, Divider } from '@material-ui/core';

import { RootState } from 'app/rootReducer'
import CourseEvent from 'models/CourseEvent'
import { formatDate } from 'utils/date'
import Popper from 'components/widgets/Popper'
import EventPopper from 'components/widgets/EventPopper'
import { useSelector } from 'react-redux';

interface BundleEventListViewProps {
    events: CourseEvent[]
    eventCount: number,
    onSelectClick: (event: CourseEvent) => void,
}

const BundleEventListView: React.FC<BundleEventListViewProps> = (props) => {
    
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const handleSelectClick = (event: CourseEvent) => {
        props.onSelectClick(event)
    }

    return (
        <React.Fragment>
            <Box component="div" style={{ display: "flex" }}>
                <Typography variant="h5">Total Options:</Typography>
                &nbsp;
                <Typography variant="h5" color='primary'>{props.eventCount} options available</Typography>
            </Box>
            <Divider />
            {props.events.map((event, index) => (
                <Card key={index} style={{ marginTop: 30 }}>
                    <Box component="div" style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center' }}>
                        <CardContent>
                            <Typography variant="h6">{event.name}</Typography>
                            { event.gtr && !settings.reseller.hideGtrEventLabels &&
                            <Popper style={{ textTransform: 'uppercase' }} variant="overline" text="Guaranteed To Run">
                                <Typography variant="h6" color="primary">Guaranteed to Run</Typography>
                                <Box style={{marginTop: 20}}>
                                    <Typography variant="body1">Gain peace of mind knowing this course is guaranteed to commence on the scheduled date. This course will not be re-scheduled due to low student numbers.</Typography>
                                </Box>
                            </Popper>
                            }
                            <Typography variant="subtitle1">{formatDate(event.startDate)}&mdash;{formatDate(event.endDate)}</Typography>
                        </CardContent>
                    </Box>
                    <CardContent>
                        <Grid container alignItems='flex-start'>
                            <Grid item xs={6} sm={3}>
                                <Typography style={{ textTransform: 'uppercase' }} component="p" variant="overline">Location</Typography>
                                <EventPopper variant="body1" color="primary" event={event} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography style={{ textTransform: 'uppercase' }} component="p" variant="overline">Language</Typography>
                                <Typography variant="body1">{event.language}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography style={{ textTransform: 'uppercase' }} component="p" variant="overline">Time</Typography>
                                <Typography variant="body1">{event.startTime}-{event.endTime} {event.timezone}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={3} style={{ alignSelf: "center" }}>
                                <Button fullWidth variant='contained' color='primary' onClick={() => handleSelectClick(event)}>Select</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
        </React.Fragment>
    )
}

export default BundleEventListView
