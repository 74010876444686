import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {

    },
    tableHeaders: {
        backgroundColor: theme.palette.primary.main,
        '& th p': {
            color: theme.palette.common.white,
        }
    },
    tableBody: {
        '& tr:nth-child(odd)': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    tablePaginationActions: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    sectionHeader: {
        fontSize: '125%',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[500]
    },
    headerActionButton: {
        marginLeft: 8,
        color: theme.palette.common.white
    },
    actionButton: {
        marginLeft: 8
    },
    popover: {
    },
    paper: {
        padding: '1rem',
        '& tr:nth-child(odd)': {
            backgroundColor: theme.palette.grey[100]
        }
    }
}))
