import { fade, makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    avatar: {
        backgroundColor: theme.palette.common.white
    },
    cardContent: {
        marginTop: 20,
        
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '16px'
        },

    },
    questionPop: {
        backgroundColor: fade(theme.palette.primary.main, 0.75),
        borderRadius: '4px',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    thankYouPop: {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
    },
    thankYou: {
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    radioGroup: {
        '& .MuiIconButton-root': {
            padding: '4px',

            '& .MuiIconButton-label input': {
                margin: '3px 3px 0px 5px',
                position: 'inherit'
            }
        },

        '& .MuiFormLabel-root': {
            marginBottom: '8px',
        }
    }
}))