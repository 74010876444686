import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Box, Button, Container, Grid, Typography } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { CourseListParams } from 'api/coursesApi'

import CompactCourseListView from 'components/courses/CompactCourseListView'
import FilterView from 'components/filters/FilterView'
import SubCategoryView from 'components/filters/SubCategoryView'
import LoadingWidget from 'components/widgets/LoadingWidget'
import Category from 'models/Category'
import Filter from 'models/Filter'
import { fetchCourses } from 'slices/courseListSlice'
import CourseSummary from 'models/CourseSummary'
import BrandSearchGrid from './BrandSearchGrid'
import { CourseListState} from 'slices/courseListSlice'

interface CategoryIdParams {
    categoryId?: number
}

interface CategoryUrlSegmentParams {
    urlSegment: string
}

interface SearchResultsProps {
    hideSearchInput?: boolean
    showAll?: boolean
    onCourseList?: (courseList: CourseListState) => void
}

const SearchResults: React.FC<SearchResultsProps> = (props) => {
    const { showAll, onCourseList, hideSearchInput } = props
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const params = useParams()
    const location = useLocation()
    const [loadingCourses, setLoadingCourses] = useState(false)
    const [loadingMoreCourses, setLoadingMoreCourses] = useState(false)
    const [categoryName, setCategoryName] = useState<string | undefined>(undefined)

    const courseList = useSelector(
        (state: RootState) => state.courseList
    )

    if (onCourseList) {
        onCourseList(courseList)
    }

    useEffect(() => {
        console.log("PARAMS", params)
        console.log("LOCATION", location)
        console.log("HISTORY", history)
    }, [params, location, history])

    const categoryId = (params as CategoryIdParams)?.categoryId
    const urlSegment = (params as CategoryUrlSegmentParams)?.urlSegment
    const haveParams = Object.keys(params).length !== 0

    // This 'effect' loads the contents for the page based on the routing payload
    // If there's a preserved state the we use that
    // Otherwise if there are no params the we look for search terms
    // Finally if there are params then we use those
    //
    // In the handler methods, we navigate via the router for any actions made by the user (except infinite scrolling)
    // which will update the page via the effect below
    useEffect(() => {
        const loadAsync = async () => {
            setLoadingCourses(true)
            if (location.state) {
                const [courseListParams, categoryName, breadcrumbs] = location.state
                setCategoryName(categoryName)
                console.log("Fetching courses for saved state")
                await dispatch(fetchCourses(courseListParams, breadcrumbs))

            } else if (!haveParams) {
                const search = new URL(window.location.href).searchParams
                console.log("Search params: ",search)
                const searchTerms = search.get('terms')
                if (searchTerms) {
                    console.log("Fetching courses for search terms:", searchTerms)
                    await dispatch(fetchCourses({ terms: searchTerms }, []))
                }else if(!!showAll) {
                    console.log("Fetching all courses:")
                    await dispatch(fetchCourses({},[]))
                }

            } else {
                const search = new URL(window.location.href).searchParams
                const searchTerms = search.get('terms') ?? undefined

                if (categoryId) {
                    console.log("Fetching courses for category ID:", categoryId, searchTerms)
                    await dispatch(fetchCourses({ categoryID: categoryId, terms: searchTerms }, []))
                }

                if (urlSegment) {
                    console.log("Fetching courses for URL segment:", urlSegment, searchTerms)
                    await dispatch(fetchCourses({ urlSegment: urlSegment, terms: searchTerms }, []))
                }
            }
            setLoadingCourses(false)
        }

        loadAsync()
    }, [dispatch, categoryId, urlSegment, haveParams, location.state, showAll])

    if (courseList == null) {
        return null
    }

    const handleCategorySearch = (category: Category) => {
        const courseListParams = initParams()
        courseListParams.categoryID = category.id

        const breadcrumbs = [...courseList.breadcrumbs]
        breadcrumbs.push({
            name: category.name,
            url: category.url,
            params: courseListParams
        })

        history.push(category.url, [courseListParams, category.name, breadcrumbs])
    }

    const handleFormatSearch = (filter: Filter) => {
        const courseListParams = initParams()
        courseListParams.formats = toggleFilterString(filter, courseListParams.formats)
        history.push(location.pathname, [courseListParams, categoryName, courseList.breadcrumbs])
    }

    const handleLocationSearch = (filter: Filter) => {
        const courseListParams = initParams()
        courseListParams.locationIDs = toggleFilter(filter, courseListParams.locationIDs)
        history.push(location.pathname, [courseListParams, categoryName, courseList.breadcrumbs])
    }

    const handleStatusSearch = (filter: Filter) => {
        const courseListParams = initParams()
        courseListParams.gtr = !courseListParams.gtr  // toggle
        history.push(location.pathname, [courseListParams, categoryName, courseList.breadcrumbs])
    }

    const handleTrainingCreditSearch = (filter: Filter) => {
        const courseListParams = initParams()
        courseListParams.trainingCredit = !courseListParams.trainingCredit  // toggle
        history.push(location.pathname, [courseListParams, categoryName, courseList.breadcrumbs])
    }

    const handleCourseClick = (course: CourseSummary) => {
        history.push(course.url)
    }

    const handleLoadMoreCourses = async () => {
        const courseListParams = initParams()
        courseListParams.page = courseList.page + 1
        console.log("Loading page:", courseListParams.page)
        // Doesn't affect history, so we just fetch directly
        setLoadingMoreCourses(true)
        await dispatch(fetchCourses(courseListParams, courseList.breadcrumbs))
        setLoadingMoreCourses(false)
    }

    const toggleFilter = (filter: Filter, filterList?: number[]): number[] | undefined => {
        // Logic based on "current" value of filter
        if (filter.selected) {
            if (filterList) {
                filterList = filterList.filter(filterId => filterId !== filter.id)
            }
        } else {
            if (filterList) {
                filterList.push(filter.id as number)
            } else {
                filterList = [filter.id as number]
            }
        }

        return filterList
    }

    const toggleFilterString = (filter: Filter, filterList?: string[]): string[] | undefined => {
        // Logic based on "current" value of filter
        if (filter.selected) {
            if (filterList) {
                filterList = filterList.filter(filterValue => filterValue !== filter.value)
            }
        } else {
            if (filterList) {
                filterList.push(filter.value as string)
            } else {
                filterList = [filter.value as string]
            }
        }

        return filterList
    }

    const handleShowAllBrandsClick = () => {
        history.push('/', { showAllBrands: true})
    }

    const handleTextSearch = (searchTerms: string) => {
        const courseListParams = initParams()
        courseListParams.terms = searchTerms
        history.push(`${location.pathname}?terms=${searchTerms}`, [courseListParams, categoryName, courseList.breadcrumbs])
    }

    const initParams = (): CourseListParams => {
        return {
            terms: courseList.terms,
            categoryID: courseList.categoryId,
            formats: courseList.filters.formats.filter(filter => filter.selected).map(filter => filter.value as string),
            locationIDs: courseList.filters.locations.filter(filter => filter.selected).map(filter => filter.id as number),
            gtr: courseList.filters.status != null && courseList.filters.status.selected,
            trainingCredit: courseList.filters.trainingCredit != null && courseList.filters.trainingCredit.selected
        }
    }

    const renderSearchResult = () => {
        return (
            <>
                {courseList.count > 0 ? (
                    <Grid container justify="center" spacing={10}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6" color="primary" align="center">Filter Results</Typography>
                            <SubCategoryView onCategoryClick={handleCategorySearch} categories={courseList.categories} />
                            <FilterView key={`${courseList.categoryId}.Format`} name="Format" filters={courseList.filters.formats} onFilterClick={handleFormatSearch} />
                            <FilterView key={`${courseList.categoryId}.Location`} name="Location" filters={courseList.filters.locations} onFilterClick={handleLocationSearch} />
                            {courseList.filters.status != null &&
                                <FilterView key={`${courseList.categoryId}.Status`} name="Status" filters={[courseList.filters.status]} onFilterClick={handleStatusSearch} />
                            }
                            {courseList.filters.trainingCredit != null &&
                                <FilterView key={`${courseList.categoryId}.TrainingCredit`} name="Training Credit" filters={[courseList.filters.trainingCredit]} onFilterClick={handleTrainingCreditSearch} />
                            }
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <CompactCourseListView courses={courseList.courses} courseCount={courseList.count} onCourseClick={handleCourseClick} />
                            {loadingMoreCourses ? (
                                <LoadingWidget />
                            ) : (
                                courseList.courses.length < courseList.count &&
                                    <Box display="flex" justifyContent="center" style={{ marginTop: 40 }} >
                                        <Button variant="contained" color="primary" onClick={handleLoadMoreCourses}>Load More</Button>
                                    </Box>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                        <Box display="flex" justifyContent="center" style={{ marginTop: 60 }}>
                            <Typography variant="h5" color="primary">No Results Found</Typography>
                        </Box>
                    )}
            </>
        )
    }

    return (
        <>
            {!!!hideSearchInput && <Container maxWidth="lg" fixed={true}>
                <BrandSearchGrid showAllBrands={true} onShowAllBrandsClick={handleShowAllBrandsClick} onSearch={handleTextSearch} />
            </Container>}
            <Box component="main" style={{margin: '2rem'}}>
                {loadingCourses ? (
                    <LoadingWidget />
                ) : (
                        renderSearchResult()
                    )}
            </Box>
        </>
    )
}

export default SearchResults
