import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => createStyles({
    borderBox: {
        borderRadius: 16,
        border: '1px solid',
        borderColor: theme.palette.grey[400],
        padding: 16,
        cursor: 'pointer'
    },
    card: {
        height: '100%'
    },
    cardMedia: {
        backgroundSize: 'contain',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        minWidth: 160,
        minHeight: 90,
        boxShadow: 'none'
    },
    description: {
        marginTop: '0.5rem'
    },
    detailsButton: {
        marginTop: 18
    }
}))

