import { createMuiTheme } from '@material-ui/core/styles';
import baseTheme from "./baseTheme"
import { customTheme } from "./baseTheme"

const overrideTheme = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: "rgba(255, 255, 255, 0.54)"
            }
        },
        MuiAppBar: {
            root: {
                boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.07),0px 1px 5px 0px rgba(0,0,0,0.06)'
            },
            colorPrimary: {
                backgroundColor: baseTheme.palette.grey['50'],
                color: baseTheme.palette.text.secondary
            },
        },
        MuiBreadcrumbs: {
            root: {
                "& p": {
                    color: customTheme.toolbarText
                }
            }
        },
        MuiCardMedia: {
            media: {
                transform: 'translate3d(0,0,0) scale(.755)',
                backfaceVisibility: 'hidden',
                imageRendering: '-webkit-optimize-contrast',
                transition: 'transform 320ms ease',
                transitionProperty: 'transform, opacity, -webkit-transform',

                "&:hover" : {
                    transform: 'translate3d(0,0,0) scale(.855)',
                },

                "&:active" : {
                    transform: 'translate3d(0,0,0) scale(.855)'
                }
            }
        },
        MuiLink: {
            underlineHover: {
                cursor: 'pointer'
            }
        },
        MuiButton: {
            root: {
                borderRadius: 'initial'
            },
            containedPrimary: {
                background: `linear-gradient(45deg, ${customTheme.buttonColorStart} 30%, ${customTheme.buttonColorEnd} 90%)`,
            },
            text: {
                fontWeight: 700
            }
        },
        MuiOutlinedInput: {
            root: {                
                borderRadius: 'initial',
                borderStyle: 'none',
                borderWidth: 0,
                backgroundColor: '#ffffff80',
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    "borderColor": baseTheme.palette.primary.main
                }
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: 'initial'
            }
        },
        MuiTypography: {
            h2: {
                marginTop: 48,
                marginBottom: 36,
            },

            h3: {
                marginTop: 48,
                marginBottom: 36,
            },
            h5: {
                marginTop: 36,
                marginBottom: 18,
            },
            body1: {
                color: baseTheme.palette.text.primary
            },
        },
    },
})

export default overrideTheme