import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { CourseEventListResponse, CourseEventListParams, getCourseEvents } from 'api/courseApi'
import EventList from 'models/CourseEventList'
import { setError } from './errorSlice'

interface EventListState extends EventList { }

const initialState: EventListState = {
    events: [],
    privateEvents: [],
    filters: {
        types: [],
        locations: []
    },
    page: 1,
    pageSize: 15,
    count: 0
}

const courseEventListSlice = createSlice({
    name: 'courseEventList',
    initialState,
    reducers: {
        setCourseEvents(state: EventListState, action: PayloadAction<CourseEventListResponse>) {
            return action.payload
        },
        addCourseEvents(state: EventListState, action: PayloadAction<CourseEventListResponse>) {
            return {
                events: state.events.concat(action.payload.events),
                privateEvents: action.payload.privateEvents,
                filters: action.payload.filters,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                count: action.payload.count
            }
        }
    }
})

export const { setCourseEvents, addCourseEvents } = courseEventListSlice.actions

export default courseEventListSlice.reducer

export const fetchCourseEvents = (courseId: number, params: CourseEventListParams): AppThunk<Promise<void>> => async dispatch => {
    try {
        const events = await getCourseEvents(courseId, params)

        if (params.page && params.page > 1) {
            dispatch(addCourseEvents(events))
        } else {
            dispatch(setCourseEvents(events))
        }
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
