import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { setError } from 'slices/errorSlice'
import { CreateContactRequest, UpdateContactRequest, ContactResponse, getContacts, patchContact, postContact, deleteContact, patchContactExtraInfo } from 'api/contactsApi'
import Contact from 'models/Contact'

type ContactState = Contact[]

const initialState: ContactState = []

const contactSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setContacts(state, action: PayloadAction<ContactResponse[]>) {
            return action.payload
        },
        addContact(state: ContactState, action: PayloadAction<ContactResponse>) {
            state.push(action.payload)
            return state
        },
        replaceContact(state: ContactState, action: PayloadAction<ContactResponse>) {
            return state.map(contact => contact.id === action.payload.id ? action.payload : contact)
        },
        filterContact(state: ContactState, action: PayloadAction<number>) {
            return state.filter(contact => contact.id !== action.payload)
        }
    }
})

export const { setContacts, addContact, replaceContact, filterContact } = contactSlice.actions

export default contactSlice.reducer

export const fetchContacts = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const contacts = await getContacts()
        dispatch(setContacts(contacts))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const createContact = (contact: CreateContactRequest): AppThunk<Promise<Contact | null>> => async dispatch => {
    try {
        const newContact = await postContact(contact)
        dispatch(addContact(newContact))
        return newContact
    } catch (err) {
        dispatch(setError(err.toString()))
        return null
    }
}

export const updateContact = (contact: UpdateContactRequest): AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const newContact = await patchContact(contact)
        dispatch(replaceContact(newContact))
        return true
    } catch (err) {
        dispatch(setError(err.toString()))
        return false
    }
}

export const removeContact = (contact: Contact): AppThunk<Promise<void>> => async dispatch => {
    try {
        await deleteContact(contact.id)
        dispatch(filterContact(contact.id))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const updateContactExtraInfo = (contactId: number, extraInfo: any): AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const newContact = await patchContactExtraInfo(contactId, extraInfo)
        dispatch(replaceContact(newContact))
        return true
    } catch (err) {
        dispatch(setError(err.toString()))
        return false
    }
}
