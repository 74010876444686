import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Card, CardContent, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddonsWidget from 'components/cart/AddonsWidget'
import { useThunkDispatch } from 'app/store';
import { RootState } from 'app/rootReducer';
import StudentListView from 'components/cart/StudentListView';
import ContentDialog from 'components/widgets/ContentDialog';
import EventPopper from 'components/widgets/EventPopper';
import Contact from 'models/Contact';
import OrderItem from 'models/OrderItem';
import { fetchTerms } from 'slices/lookupsSlice';
import { formatDate } from 'utils/date';
import PriceRenderer from 'components/widgets/PriceRenderer'
import PromoCodeField from './PromoCodeField'
import { setCartValid } from 'slices/cartSubmitSlice'

interface CartItemCardProps {
    item: OrderItem
    onEnrollStudents: (event: React.KeyboardEvent | React.MouseEvent) => void
    onRemoveStudent: (item: OrderItem, contactId: number) => void
    onEditStudent: (item: OrderItem, contact: Contact) => void
    onUpdateStudentExtraInfo: (item: OrderItem, contactId: number, extraInfo: any) => void
    onRemoveItem: (item: OrderItem) => void
}

const CartItemCard: React.FC<CartItemCardProps> = (props) => {
    const { course, event, students, price, waitList } = props.item
    const dispatch = useThunkDispatch()

    const [showAddons, setShowAddons] = useState(false)

    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const handleEnrollStudents = (event: React.KeyboardEvent | React.MouseEvent) => {
        props.onEnrollStudents(event)
    }

    const handleRemoveStudent = (item: OrderItem, contactId: number) => {
        props.onRemoveStudent(item, contactId)
    }

    const handleEditStudent = (item: OrderItem, contact: Contact) => {
        props.onEditStudent(item, contact)
    }

    const handleUpdateStudentExtraInfo = (item: OrderItem, contactId: number, extraInfo: any) => {
        props.onUpdateStudentExtraInfo(item, contactId, extraInfo)
    }

    const handleRemoveItem = (item: OrderItem) => {
        props.onRemoveItem(item)
    }

    const terms = useSelector(
        (state: RootState) => state.lookups.terms
    )

    const [termsOpen, setTermsOpen] = useState(false)
    const handleTermsClick = (categoryId: number) => {
        dispatch(fetchTerms(categoryId))
        setTermsOpen(true)
    }

    const handleTermsDialogClicked = () => {
        setTermsOpen(false)
    }

    const handlePromoCodeStatus = (status: boolean) => {
        dispatch(setCartValid(status))
    }

    const renderDialog = () => {
        return (<ContentDialog open={termsOpen} title="" message={terms.content} onButtonClicked={handleTermsDialogClicked} />)
    }

    if (!course) {
        return null
    }

    return (
        <React.Fragment>
            <Card style={{ marginTop: 30 }}>
                <CardContent style={{ position: "relative" }}>
                    <Typography style={{ textTransform: 'uppercase' }} variant="overline">{course.category.name}</Typography>
                    <Box component="span" display="flex" alignItems="center" justifyContent="flex-start">
                        <Typography variant="body1" color='primary'>{course.name}</Typography>
                        {!!waitList &&
                            <Typography variant="caption" style={{ color: 'orange', textTransform: 'uppercase', fontWeight: 'bold' }}>&nbsp;Waitlist</Typography>
                        }
                    </Box>
                    <Box component="span" display="flex">
                        <Typography style={{ fontWeight: 700 }} variant="body1" color='secondary'>{course.code}</Typography>
                        {event != null && (
                            <Typography variant="body1">&nbsp;&mdash;&nbsp;{formatDate(event.startDate)} - {formatDate(event.endDate)}</Typography>
                        )}
                    </Box>
                    <Grid container style={{ marginTop: 30 }} justify="flex-start">
                        {course.standAlone ? (
                            <React.Fragment>
                                {!settings.reseller.hideAllPricing && <Grid item xs={8}>
                                    <Box>
                                        <Typography component="p" variant="overline">MSRP</Typography>
                                        <PriceRenderer isFree={course.isFree} price={price} />
                                    </Box>
                                </Grid>}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <Box>
                                        <Typography component="p" variant="overline">{"Location".toUpperCase()}</Typography>
                                        <EventPopper variant="body1" color="primary" event={event!} />
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box>
                                        <Typography component="p" variant="overline">{"Language".toUpperCase()}</Typography>
                                        <Typography variant="body1">{event?.language}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box>
                                        <Typography component="p" variant="overline">{"Time".toUpperCase()}</Typography>
                                        <Typography variant="body1">{`${event?.startTime}-${event?.endTime}`}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box>
                                        <Typography component="p" variant="overline">{"Days(s)".toUpperCase()}</Typography>
                                        <Typography variant="body1">{event?.days}</Typography>
                                    </Box>
                                </Grid>
                                {(settings.reseller.hideAllPricing || (!!event && event.type === "DED" && ((!!price.originalAmount && price.amount === 0 && price.originalAmount === 0) || price.amount === 0))) ? (
                                    <Grid item xs={4}></Grid>
                                ) : (
                                    <Grid item xs={4}>
                                        <Box>
                                            <Typography component="p" variant="overline">MSRP</Typography>
                                            <PriceRenderer isFree={course.isFree} price={price} per="/ student" />
                                        </Box>
                                    </Grid>
                                )
                                }
                            </React.Fragment>
                        )}

                        <Grid item xs={8}>
                            <span style={{ marginTop: 30, display: 'flex', alignItems: 'center' }}>
                                <Typography>By completing this order you are agreeing to our</Typography>
                                &nbsp;
                                <Button color='primary' onClick={() => handleTermsClick(course.category.id)}>Terms & Conditions</Button>
                                {renderDialog()}
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            {settings.reseller.hasPromoCode &&
                                <PromoCodeField item={props.item} label="Promo Code" onPromoCodeStatus={handlePromoCodeStatus} />
                            }
                        </Grid>
                    </Grid>

                    {students.length > 0 ? (
                        <React.Fragment>
                            <span style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='h6'>Enrolled Students</Typography>
                                <Button variant="contained" color='primary' onClick={handleEnrollStudents}>Add Students</Button>
                            </span>
                            <Divider style={{ marginTop: 15 }} />
                            <StudentListView
                                item={props.item}
                                onEditStudent={handleEditStudent}
                                onRemoveStudent={handleRemoveStudent}
                                onUpdateStudentExtraInfo={handleUpdateStudentExtraInfo}
                            />
                        </React.Fragment>
                    ) : (
                        <Box style={{ marginTop: 30, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <Button variant="contained" color='primary' onClick={handleEnrollStudents}>Enroll Students</Button>
                        </Box>
                    )}
                    <Box style={{ position: "absolute", right: 16, top: 16 }}>
                        <IconButton aria-label="remove" onClick={() => handleRemoveItem(props.item)}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    {showAddons && <>
                        <Divider style={{ marginBottom: 15, marginTop: 27 }}></Divider>
                        <Typography component="p" variant="overline">Recommended Add Ons</Typography>
                    </>
                    }
                    <AddonsWidget itemID={course.id} hasAddons={(c) => setShowAddons(c)} />
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default CartItemCard
