import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem } from '@material-ui/core'
import { Formik, Form, FormikHelpers } from 'formik'
import * as yup from 'yup'

import { useStyles } from './ExtraInfoDialog.styles'
import { StudentRequiredField } from 'models/Student'
import FormSelect from 'components/widgets/form/FormSelect'
import FormTextField from 'components/widgets/form/FormTextField'

interface ExtraInfoDialogProps {
    open?: boolean
    extraInfoFields?: StudentRequiredField[]
    onCancelClicked?: () => void
    onSaveClicked?: (values: any) => void
}

const defaultProps: ExtraInfoDialogProps = {
    open: false,
}

const ExtraInfoDialog: React.FC<ExtraInfoDialogProps> = (props) => {
    const classes = useStyles()
    const handleCancelButton = () => {
        props.onCancelClicked?.()
    }

    const handleSubmit = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        console.log("Values", values)
        props.onSaveClicked?.(values)
    }

    const renderText = (field: StudentRequiredField) => {
        return (
            <FormTextField 
                key={field.name} 
                placeholder={field.label}
                name={field.name} 
                className={classes.text}
            />
        )
    }

    const renderDropDown = (field: StudentRequiredField) => {
        return (
            <FormSelect
                id={field.name}
                key={field.name}
                placeholder={field.label}
                name={field.name}
                value={field.value}
                className={classes.dropdown}
            >
                {Object.keys(field.options).map(key =>
                    <MenuItem key={key} value={field.options[key]}>{field.options[key]}</MenuItem> // Need to return the display text as the value
                )}
            </FormSelect>
        )
    }

    const renderReadOnly = (field: StudentRequiredField) => {
        return (
            <DialogContentText key={field.name} variant="body1" className={classes.readonly} dangerouslySetInnerHTML={{ __html: field.value }}/>
        )
    }

    const renderField = (field: StudentRequiredField) => {
        switch (field.type) {
            case "text":
                return renderText(field)

            case "dropdown":
                return renderDropDown(field)

            case "readonly":
                return renderReadOnly(field)
        }
    }

    const initialValues = props.extraInfoFields?.reduce((acc: any, field) => {
        if (field.type !== "readonly") {
            acc[field.name] = field.value
        }
        return acc
    }, {}) ?? {}

    const validationSchema = yup.object(props.extraInfoFields?.reduce((acc: any, field) => {
        if (field.type !== "readonly" && field.required) {
            acc[field.name] = yup.string().required("This is a required field!")
        }
        return acc
    }, {}) ?? {})

    return (
        <Dialog
            open={props.open!}
            onClose={handleCancelButton}
            aria-labelledby="alert-dialog-title"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    isSubmitting
                }) => {
                    return (
                        <Form>
                            <DialogTitle id="alert-dialog-title">Extra Info</DialogTitle>
                            <DialogContent className={classes.root}>
                                {props.extraInfoFields?.map(field => renderField(field))}
                            </DialogContent>
                            <DialogActions className={classes.buttonSection}>
                                <Button onClick={handleCancelButton} color="primary">Cancel</Button>
                                <Button type="submit" disabled={isSubmitting} color="primary" autoFocus>Save</Button>
                            </DialogActions>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

ExtraInfoDialog.defaultProps = defaultProps

export default ExtraInfoDialog
