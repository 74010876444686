import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { getPaymentInfo, PaymentInfoResponse } from 'api/memberApi'
import PaymentInfo from 'models/PaymentInfo'
import { setError } from './errorSlice'

type PaymentInfoState = PaymentInfo | null

const initialState: PaymentInfoState = null as PaymentInfoState

const paymentInfoSlice = createSlice({
    name: 'paymentInfo',
    initialState,
    reducers: {
        setPaymentInfo(state, action: PayloadAction<PaymentInfoResponse | null>) {
            return action.payload
        }
    }
})

export const { setPaymentInfo } = paymentInfoSlice.actions

export default paymentInfoSlice.reducer

export const fetchPaymentInfo = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const paymentInfo = await getPaymentInfo()
        dispatch(setPaymentInfo(paymentInfo))
    } catch (err) {
        if (err.response && err.response.status !== 422) {
            dispatch(setError(err.toString()))
        }
    }
}
