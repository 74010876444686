import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Avatar, Box, Card, CardContent, FormControl, FormControlLabel, FormLabel, Grid, RadioGroup, Radio, Typography, Button } from '@material-ui/core'
import FaceIcon from '@material-ui/icons/Face'

import { useStyles } from './CompleteRegistrationCard.styles'
import Student from 'models/Student'
import OrderItem from 'models/OrderItem'
import Contact from 'models/Contact'

export interface CompleteRegistrationCardProps { 
    item: OrderItem
    student: Student
    onSelectionChange: (contactId: number, extraInfo: any) => void
}

const CompleteRegistrationCard: React.FC<CompleteRegistrationCardProps> = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const { item, student } = props
    const [radioDisabled, setRadioDisabled] = useState({ radioSet: new Set()})

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const data = { [event.target.name]: value }
        setRadioDisabled({radioSet: radioDisabled.radioSet.add(event.target.name)})
        props.onSelectionChange(student.contact.id, data)
    }

    const handleUpdateShipping = (contact: Contact) => {
        history.push("/shipping", { contact })                
    }

    if (!student.afterPaymentFields) {
        return null
    }
    
    const field = student.afterPaymentFields[0]

    if (!field || field.type !== "radio") {
        return null
    }

    return (
        <Card className={classes.cardContent}>
            <CardContent >
                <Grid container>
                    <React.Fragment key={student.contact.id}>
                        <Grid item sm={4}>
                            <Box display="flex">
                                <Avatar className={classes.avatar} variant='rounded'>
                                    <FaceIcon color="primary" fontSize="large" />
                                </Avatar>
                                <Box style={{ marginLeft: '8px' }}>
                                    <Typography variant="body1">{`${student.contact.firstName} ${student.contact.lastName}`}</Typography>
                                    <Typography variant="body2">{item.course?.name}</Typography>
                                    {student.requirements?.shippingAddressRequired &&
                                        <Button style={{marginTop: '1rem'}} variant="outlined" onClick={e => handleUpdateShipping(student.contact)}>Update Shipping Info</Button>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={8} className={radioDisabled.radioSet.has(field.name) ? `${classes.questionPop} ${classes.thankYouPop}` : classes.questionPop}>
                            {radioDisabled.radioSet.has(field.name) ?
                                <React.Fragment>
                                    <FormLabel component="legend"><Typography variant="h6" color="textSecondary">{field.label}</Typography></FormLabel>
                                    <Typography className={classes.thankYou} variant="h6">Thank You</Typography>
                                </React.Fragment>
                            :
                                <FormControl className={classes.radioGroup} component="fieldset">
                                    <React.Fragment>
                                        <FormLabel component="legend"><Typography variant="h6" color="textPrimary">{field.label}</Typography></FormLabel>
                                        <RadioGroup
                                            aria-label="gender"
                                            name={field.name}
                                            value={null}
                                            onChange={handleRadioChange}
                                        >
                                            {Object.keys(field.options).map(key =>
                                                <FormControlLabel
                                                    key={key}
                                                    value={key}
                                                    control={<Radio color="primary" />}
                                                    label={field.options[key]}
                                                    disabled={radioDisabled.radioSet.has(field.name)}
                                                />
                                            )}
                                        </RadioGroup>
                                    </React.Fragment>
                                </FormControl>
                            }
                        </Grid>
                    </React.Fragment>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default CompleteRegistrationCard
