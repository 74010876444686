import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    addContactButton: {
        marginTop: 30
    },
    cancelButton: {
        marginRight: 8
    },
    checkbox: {
        marginTop: 20
    },
    closeButton: {
        marginTop: 18,
        paddingBottom: 0
    }
}))
