import React from 'react';
import { Box, Button, Grid, Typography, Avatar, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import EqualIcon from '@material-ui/icons/DragHandle'
import RightArrowIcon from '@material-ui/icons/ChevronRight'

import { useStyles } from './TotalsWidget.styles'
import { formatCurrency } from 'utils/currency'
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer'

interface TotalsWidgetProps {
    disableNext: boolean,
    buttonText: string,
    subtotal: number,
    tax: number,
    total: number
    currency: string,
    currencyName: string,
    onNextClicked: () => void
}

const TotalsWidget: React.FC<TotalsWidgetProps> = (props) => {
    const classes = useStyles()
    const theme = useTheme()

    const handleNextButton = () => {
        props.onNextClicked()
    }

    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    return (
        <Box mt={'30px'}>
            <Grid container style={{ justifyContent: 'center' }}>
                {!settings.reseller.hideAllPricing && 
                    <React.Fragment>
                        <Grid item xs={3}>
                            <Box className={classes.contentBox}>
                                <Box className={classes.totalsText}>
                                    <Typography variant='h6'>{formatCurrency(props.subtotal, props.currency)}</Typography>
                                    <Typography style={{ textTransform: 'uppercase' }} className={classes.checkoutLabelText} variant='overline'>Subtotal</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box bgcolor="grey.800" className={classes.contentBox}>
                                <Box className={classes.totalsText}>
                                    <Typography variant='h6'>{formatCurrency(props.tax, props.currency)}</Typography>
                                    <Typography style={{ textTransform: 'uppercase' }}  className={classes.checkoutLabelText} variant='overline'>Tax</Typography>
                                </Box>
                                <Box className={classes.interColumnWidget}>
                                    <Avatar variant='rounded' style={{ backgroundColor: theme.palette.primary.main }}>
                                        <AddIcon />
                                    </Avatar>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box bgcolor="grey.700" className={classes.contentBox}>
                                <Box className={classes.totalsText}>
                                    <Typography variant='h6'>{formatCurrency(props.total, props.currency)}</Typography>
                                    <Typography style={{ textTransform: 'uppercase' }}  className={classes.checkoutLabelText} variant='overline'>Total ({props.currencyName})</Typography>
                                </Box>
                                <Box className={classes.interColumnWidget}>
                                    <Avatar variant='rounded' style={{ backgroundColor: theme.palette.primary.main }}>
                                        <EqualIcon />
                                    </Avatar>
                                </Box>
                            </Box>
                        </Grid>
                    </React.Fragment>
                }
                <Grid item xs={3}>
                    <Box className={classes.contentBoxAction}>
                        <Button className={[classes.totalsText, classes.checkoutButtonLabelText].join(' ')} disabled={props.disableNext} onClick={handleNextButton}>{props.buttonText}</Button>
                        {!settings.reseller.hideAllPricing && 
                            <Box className={classes.interColumnWidget}>
                                <Avatar variant='rounded' style={{ backgroundColor: theme.palette.common.white, color: theme.palette.primary.main }}>
                                    <RightArrowIcon />
                                </Avatar>
                            </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TotalsWidget
