import React, { useEffect, useState, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Box, Button, Container, Grid, Stepper, Step, StepLabel, Typography } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'

import CourseSummary from 'models/CourseSummary'
import CourseEvent from 'models/CourseEvent'
import BundleEventListView from 'components/bundles/BundleEventListView'
import FilterView from 'components/filters/FilterView'
import LoadingWidget from 'components/widgets/LoadingWidget'
import HrTextWidget from 'components/widgets/HrTextWidget'
import Filter from 'models/Filter'
import { fetchBundleEventList, addToBundle } from 'slices/bundleSlice'
import { BundleEventListParams } from 'api/bundlesApi'
import StandaloneCourseCard from 'components/bundles/StandaloneCard'

interface BundleIdParams {
    bundleId?: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    iconContainer: { // define styles for icon container
        transform: 'scale(4)',
        zIndex: 1000
    }
}))

interface CreateBundlePageProps { }

const CreateBundlePage: React.FC<CreateBundlePageProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const { bundleId: bundleIdParam } = useParams<BundleIdParams>()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [loadingMoreEvents, setLoadingMoreEvents] = useState(false)
    const bundleEventList = useSelector(
        (state: RootState) => state.bundles.bundleEventList
    )

    useEffect(() => {
        console.log("BUNDLE ID PARAM", bundleIdParam)
        console.log("LOCATION", location)
        console.log("HISTORY", history)
    }, [bundleIdParam, location, history])

    useEffect(() => {
        const loadAsync = async () => {
            // If location.state is available use that to fetch course events 
            // (the course should already be loaded in the store)
            if (location.state) {
                setLoading(true)
                const [bundleId, eventListParams] = location.state
                console.log("Fetching events for bundle (from state)", bundleId)
                await dispatch(fetchBundleEventList(bundleId, eventListParams))
                setLoading(false)

                // Otherwise use the params from the URL
            } else if (bundleIdParam != null) {
                setLoading(true)
                const bundleId = Number(bundleIdParam)
                console.log("Fetching events for bundle", bundleId)
                await dispatch(fetchBundleEventList(bundleId, {}))
                setLoading(false)
            }
        }

        loadAsync()
    }, [dispatch, location.state, bundleIdParam])

    useEffect(() => {
        if (bundleEventList && bundleEventList.step > bundleEventList.totalSteps && bundleEventList.courses.length === 0 && bundleEventList.events.length === 0) {
            history.push(`/order/bundle/summary/${bundleEventList.id}`)    
        }
    }, [history, bundleEventList])

    if (bundleEventList == null) {
        return null
    }

    const handleLoadMoreEvents = async () => {
        const eventListParams = initParams()
        eventListParams.page = bundleEventList.page + 1
        console.log("Loading page:", eventListParams.page)

        // Doesn't affect history so we just go ahead and fetch
        setLoadingMoreEvents(true)
        await dispatch(fetchBundleEventList(bundleEventList.id, eventListParams))
        setLoadingMoreEvents(false)
    }

    const handleTypeSearch = (filter: Filter) => {
        const eventListParams = initParams()
        eventListParams.types = toggleFilter(filter, eventListParams.types)
        history.push(location.pathname, [bundleEventList.id, eventListParams])
    }

    const handleLocationSearch = (filter: Filter) => {
        const eventListParams = initParams()
        eventListParams.locations = toggleFilter(filter, eventListParams.locations)
        history.push(location.pathname, [bundleEventList.id, eventListParams])
    }

    const handleStatusSearch = (filter: Filter) => {
        const eventListParams = initParams()
        eventListParams.gtr = !eventListParams.gtr  // toggle
        history.push(location.pathname, [bundleEventList.id, eventListParams])
    }

    const handleSelectCourse = async (course: CourseSummary) => {
        setLoading(true)
        await dispatch(addToBundle({ bundleID: bundleEventList.id, courseID: course.id, step: bundleEventList.step }))
        setLoading(false)
    }

    const handleSelectEvent = async (event: CourseEvent) => {
        setLoading(true)
        await dispatch(addToBundle({ bundleID: bundleEventList.id, eventID: event.id, step: bundleEventList.step}))
        setLoading(false)
    }

    const toggleFilter = (filter: Filter, filterList?: string[]): string[] | undefined => {
        // Logic based on "current" value of filter
        if (filter.selected) {
            if (filterList) {
                filterList = filterList.filter(filterValue => filterValue !== filter.value)
            }
        } else {
            if (filterList) {
                filterList.push(filter.value as string)
            } else {
                filterList = [filter.value as string]
            }
        }

        return filterList
    }

    const initParams = (): BundleEventListParams => {
        return {
            types: bundleEventList.filters.types.filter(filter => filter.selected).map(filter => filter.value as string),
            locations: bundleEventList.filters.locations.filter(filter => filter.selected).map(filter => filter.value as string),
            gtr: bundleEventList.filters.status != null && bundleEventList.filters.status.selected,
            step: bundleEventList.step
        }
    }

    const renderStepper = () => {
        const items: ReactElement[] = []

        for (let step = 0; step < bundleEventList.totalSteps; step++) {
            items.push(
                <Step key={step}>
                    <StepLabel classes={{ iconContainer: classes.iconContainer }}>{}</StepLabel>
                </Step>
            )
        }

        return (
            <Box style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                <Stepper activeStep={bundleEventList.step - 1} alternativeLabel>
                    {items}
                </Stepper>
            </Box>
        )
    }

    const renderCourses = () => {
        if (bundleEventList.courses.length === 0) {
            return null
        }

        return (
            <React.Fragment>
                <Typography variant="h5">Select from one of our standalone options:</Typography>
                {bundleEventList.courses.map(course => 
                    <StandaloneCourseCard key={course.id} course={course} onSelectClick={handleSelectCourse} />
                )}
            </React.Fragment>
        )
    }

    const renderEventList = () => {
        if (bundleEventList.events.length === 0) {
            return null
        }

        return (
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                    <Grid container justify="center" spacing={10}>
                        {bundleEventList.count > 0 &&
                            <Grid item xs={12} sm={3}>
                                <FilterView name="Type" filters={bundleEventList.filters.types} onFilterClick={handleTypeSearch} />
                                <FilterView name="Location" filters={bundleEventList.filters.locations} onFilterClick={handleLocationSearch} />
                                {bundleEventList.filters.status != null &&
                                    <FilterView name="Status" filters={[bundleEventList.filters.status]} onFilterClick={handleStatusSearch} />
                                }
                            </Grid>
                        }
                        {!loading &&
                            <Grid item xs={12} sm={bundleEventList.count > 0 ? 9 : 12}>
                            {<BundleEventListView events={bundleEventList.events} eventCount={bundleEventList.count} onSelectClick={handleSelectEvent} />}
                                {loadingMoreEvents ? (
                                    <LoadingWidget />
                                ) : (
                                    bundleEventList.events.length < bundleEventList.count &&
                                        <Box display="flex" justifyContent="center" style={{ marginTop: 40 }} >
                                            <Button variant="contained" color="primary" onClick={handleLoadMoreEvents}>Load More</Button>
                                        </Box>
                                    )}
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <Box component="main">
                <Container maxWidth="lg" fixed={true}>
                    <Box style={{ marginTop: 20 }}>
                        {loading ? (
                            <LoadingWidget />
                        ) : (
                            <React.Fragment>
                                <Typography style={{textAlign: 'center'}} variant="h3" color="primary">{bundleEventList.name}</Typography>
                                {renderStepper()}
                                {renderCourses()}
                                {bundleEventList.courses.length > 0 && bundleEventList.events.length > 0 && 
                                    <HrTextWidget text="OR"/>
                                }
                                {renderEventList()}   
                            </React.Fragment>
                        )}
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    )
}

export default CreateBundlePage
