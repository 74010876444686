import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { BillingRequest, postPaymentData } from 'api/paymentApi'
import { setError } from './errorSlice'

interface PaymentDataState {
    complete: boolean
}

const initialState = {
    complete: false
}

const paymentDataSlice = createSlice({
    name: 'paymentData',
    initialState,
    reducers: {
        setPaymentData(state, action: PayloadAction<boolean>) {
            return { complete: action.payload }
        }
    }
})

export const { setPaymentData } = paymentDataSlice.actions

export default paymentDataSlice.reducer

export const savePayment = (billing: BillingRequest): AppThunk<Promise<boolean | undefined>> => async dispatch => {
    try {
        await postPaymentData(billing)
        dispatch(setPaymentData(true))
        return true
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
