import { FormControl, InputLabel, InputBase, styled, fade, InputBaseProps, FormHelperText } from "@material-ui/core"
import React from "react"

import { useStyles } from './ECTextField.styles'

export const ECInput = styled(InputBase)(({ theme }) => ({   
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: "#f2f4f5",
        border: "0px",
        borderBottom: "1px solid transparent",
        fontSize: 16,
        padding: "10px 12px",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(","),
        "&:focus": {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderBottom: "1px solid #000000"
        },
        "&:hover": {
            borderBottom: "1px solid #000000"
        }
    },
}))

type ECTextFieldProps = InputBaseProps & {
    label?: React.ReactNode
    helperText?: React.ReactNode
}

const ECTextField: React.FC<ECTextFieldProps> = (props) => {
    const classes = useStyles()
    const { id, label, style, fullWidth, error, helperText, ...inputProps } = props

    return (
        <FormControl style={style} fullWidth={fullWidth} variant="standard">
            {label &&
                <InputLabel className={classes.inputLabel} shrink htmlFor={id}>
                    {label}
                </InputLabel>
            }
            <ECInput
                id={id}
                {...inputProps}
            />
            {helperText &&
                <FormHelperText error={error}>{helperText}</FormHelperText>
            }           
        </FormControl>        
    )
}

export default ECTextField
