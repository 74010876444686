import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    currencyButton: {
        marginTop: '1rem'
    },
    currencyButtonList: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '75%'
    }
}))
