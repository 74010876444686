import React from 'react'
import { Box, Container } from '@material-ui/core'

import ContactForm from 'components/contacts/ContactForm'

interface ShippingContactProps { }

const ShippingContactPage: React.FC<ShippingContactProps> = (props) => {

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                <ContactForm showShipping title="Update Shipping Info"/>
            </Container>
        </Box>
    )
}

export default ShippingContactPage
