import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: 20,
        borderWidth: "2px",
        borderColor: theme.palette.grey[200],
        borderStyle: "dashed",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
    },
    button: {
        marginTop: "2em",
        marginBottom: "2em"
    }
}))