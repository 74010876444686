import RestUtils from 'utils/RestUtils'
import CourseEvent from 'models/CourseEvent'

export interface PrivateEventsListResponse {
    events: CourseEvent[]
}

export async function getPrivateEvents(): Promise<PrivateEventsListResponse> {
    const url = `/training/privates`

    const { data } = await RestUtils.get<PrivateEventsListResponse>(url)

    return data
}

export async function getPrivateEventsForCourse(courseId: number): Promise<PrivateEventsListResponse> {
    const url = `/training/privatecourses/${courseId}/events`

    const { data } = await RestUtils.get<PrivateEventsListResponse>(url)

    return data
}
