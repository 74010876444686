import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    borderCenter: {
        marginTop: 60,
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        '&:before, &:after': {
            content: '""',
            flex: 1,
            borderBottom: '2px solid',
            borderBottomColor: theme.palette.grey[500],
        },
        '&:before': {
            marginRight: '0.5em'
        },
        '&:after': {
            marginLeft: '0.5em'
        },
    },
    textBox: {
    },
    text: {
    }

}))
