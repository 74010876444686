import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { BillingRequest, postPayment } from 'api/paymentApi'
import { setError } from './errorSlice'

interface PaymentState {
    complete: boolean
}

const initialState = {
    complete: false
}

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPayment(state, action: PayloadAction<boolean>) {
            return { complete: action.payload }
        }
    }
})

export const { setPayment} = paymentSlice.actions

export default paymentSlice.reducer

export const createPayment = (billing: BillingRequest): AppThunk<Promise<void>> => async dispatch => {
    try {
        await postPayment(billing)
        dispatch(setPayment(true))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}