import React from 'react';
import { Box, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'

import { useStyles } from './AddCourseWidget.styles'

interface TotalsWidgetProps {
    OnAddButtonClicked: () => void
}

const TotalsWidget: React.FC<TotalsWidgetProps> = (props) => {
    const classes = useStyles()

    const handleAddNewCourseButton = () => {
        props.OnAddButtonClicked()
    }

    return (
        <Box className={classes.root}>
            <Button className={classes.button} color="primary" startIcon={<AddIcon />} onClick={handleAddNewCourseButton}>Add New Course</Button>
        </Box>
    )
}

export default TotalsWidget
