import React from 'react'
import { FieldAttributes, useField } from 'formik'
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField'
import ECTextField from '../ECTextField'

type TextFieldProps = MuiTextFieldProps & FieldAttributes<any>

const FormTextField: React.FC<TextFieldProps> = ({
    label,
    placeholder,
    ...props
}) => {
    const [field, meta] = useField<TextFieldProps>(props)
    const errorText = meta.error && meta.touched ? meta.error : ""
    return (
        <ECTextField
            placeholder={placeholder}
            label={label}
            {...field}
            {...props}
            helperText={errorText}
            error={!!errorText}
            fullWidth={true}
        />
    )
}

export default FormTextField
