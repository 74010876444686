import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        marginTop: 30
    },
    htmlSection: {
        marginTop: 30,
        marginBottom: 10
    },
    address: {
        marginTop: 30
    }
}))
