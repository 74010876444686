import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: 30,
        padding: "3em",
        borderWidth: "2px",
        borderColor: theme.palette.grey[200],
        borderStyle: "dashed",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    acceptFile: {
        borderColor: theme.palette.primary.main,       
    },
    rejectFile: {
        borderColor: theme.palette.error.main,       
    },
    spacedText: {
        marginTop: "1em",
        marginBottom: "1em"
    }
}))