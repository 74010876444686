import RestUtils, { noCamelCaseConfig } from 'utils/RestUtils'
import camelcaseKeys from 'camelcase-keys'
import Content from 'models/Content'
import ContentPage from 'models/ContentPage'

export interface ContentResponse extends Content {}
export interface ContentPageResponse extends ContentPage {}

export async function getContent(): Promise<ContentResponse> {
    const url = "/content"

    const { data } = await RestUtils.get<ContentResponse>(url, null, noCamelCaseConfig)

    // We only want to camel case the keys inside ContentItems
    Object.keys(data).forEach(key => data[key] = camelcaseKeys(data[key], {deep: true}))

    return data
}

export async function getContentPages(): Promise<ContentPageResponse[]> {
    const url = "/content/pages"

    const { data } = await RestUtils.get<ContentPageResponse[]>(url)

    return data
}