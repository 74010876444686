import React from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

interface MessageProps { 
    message?: string | null
    messageType?: "error" | "success" | "info" | "warning"
    onSetMessage: (message: string | null) => void
}

const MessageWidget: React.FC<MessageProps> = (props) => {
    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        props.onSetMessage(null)
    }

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!!props.message} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} color={props.messageType}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

MessageWidget.defaultProps = {
    message: null,
    messageType: "success"
}

export default MessageWidget
