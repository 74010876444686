import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Container, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { fetchTransactionDetailReport } from 'slices/reportsSlice'
import LoadingWidget from 'components/widgets/LoadingWidget'
import TransactionDetailCard from 'components/reports/TransactionDetailCard'
import TransactionDetailItemCard from 'components/reports/TransactionDetailItemCard'
import { setError } from 'slices/errorSlice'

interface TransactionIdParams {
    transactionId?: number
}

interface TransactionDetailReportPageProps {
}

const TransactionDetailReportPage: React.FC<TransactionDetailReportPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const { transactionId } = useParams() as TransactionIdParams
    const history = useHistory()
    const transactionDetail = useSelector(
        (state: RootState) => state.reports.detail
    )
    const error = useSelector(
        (state: RootState) => state.error
    )
    const [loadingDetailReport, setLoadingDetailReport] = useState(false)

    useEffect(() => {
        const loadAsync = async (transactionId: number) => {
            setLoadingDetailReport(true)
            console.log("Fetching transaction detail report for transaction ID", transactionId)
            await dispatch(fetchTransactionDetailReport(transactionId))
            setLoadingDetailReport(false)
        }

        if (transactionId != null) {
            loadAsync(transactionId)
        }
    }, [dispatch, transactionId])

    useEffect(() => {
        if (error === "Error: Permission Denied") {
            dispatch(setError(null))
            history.push("/login")
        }
    }, [dispatch, history, error])

    const handleBackButtonClick = () => {
        history.goBack()
    }

    return (
        <React.Fragment>
            <Box component="main">
                <Box position="absolute" left={8} top={94}>
                    <IconButton onClick={handleBackButtonClick}>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>
                <Container maxWidth="lg" fixed={true}>
                    <Typography align="center" component="h5" variant="h5" color="secondary" style={{ marginBottom: 48 }}>
                        Transaction Detail Report
                    </Typography>

                    {loadingDetailReport ? (
                        <LoadingWidget />
                    ) : (
                        transactionDetail.length > 0 && 
                        < React.Fragment >
                            <TransactionDetailCard transactionDetail={transactionDetail[0]} />
                            <TransactionDetailItemCard transactionDetailItems={transactionDetail[0].items} />
                        </React.Fragment>
                    )}
                </Container>
            </Box>
        </React.Fragment>
    )
}

export default TransactionDetailReportPage
