import React, { useState, KeyboardEvent} from 'react';
import { IconButton, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ECTextField from './ECTextField'
import { useStyles } from './SearchBox.styles'

interface SearchBoxProps {
    style?: object
    label?: string
    placeholder?: string
    minChars?: number
    onSearch: (searchTerms: string) => void
}

const SearchBox: React.FC<SearchBoxProps> = (props) => {
    const classes = useStyles();
    const [searchTerms, setSearchTerms] = useState("")
    const [searchError, setSearchError] = useState<string | undefined>()

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setSearchError(undefined)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerms(event.target.value)
        setSearchError(undefined)
    };

    const handleSearch = () => {
        if (searchTerms !== undefined && searchTerms.length >= props.minChars!) {
            props.onSearch(searchTerms)
        } else {
            setSearchError(`Search term must be at least ${props.minChars!} characters`)
        }
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter')  {
            handleSearch()                        
        }
    }
   
    return (
        <Box display="flex" flexDirection="row" alignItems="flex-end" flex={2}>
            <ECTextField
                id="brand-search"
                label={props.label}
                placeholder={props.placeholder}
                helperText={searchError ? searchError : ""}
                type="search"
                style={props.style}
                defaultValue={searchTerms}
                fullWidth={true}
                onFocus={handleFocus}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                error={!!searchError}
            />
            <IconButton
                className={classes.searchButton}
                aria-label="search"
                onClick={handleSearch}
            >
                <SearchIcon />
            </IconButton>
        </Box>
    )
}

SearchBox.defaultProps = {
    minChars: 3
}

export default SearchBox
