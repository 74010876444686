import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { ReportIndexResponse, TransactionReportResponse, TransactionDetailResponse, getReportIndex, getTransactionReport, getTransactionDetailReport, getSFReportData, getSFReportList, SFReportResponse, SFReportDataResponse, FilterValues, DocumentListResponse, getDocuments } from 'api/reportsApi'
import Transaction from 'models/Transaction'
import Team from 'models/Team'
import TransactionDetail from 'models/TransactionDetail'
import { setError } from './errorSlice'
import SFReport from 'models/SFReport'
import SFReportData from 'models/SFReportData'
import ReportFolder from 'models/ReportFolder'

interface ReportState {
    reports: ReportIndexResponse[]
    transactionCount: number
    transactions: Transaction[]
    teams: Team[]
    detail: TransactionDetail[]
    reportList: SFReport[]
    reportData: SFReportData
    folders: ReportFolder[]
}

const initialState: ReportState = {
    reports: [],
    transactionCount: 0,
    transactions: [],
    teams: [],
    detail: [],
    reportList: [],
    reportData: {
        id: 0,
        name: "",
        headers: [],
        data: [],
        filters: [],
        rowCount: 0,
        exportDataOptions: []
    },
    folders: []
}

const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setReportIndex(state, action: PayloadAction<ReportIndexResponse[]>) {
            state.reports = action.payload
        },
        setTransactions(state, action: PayloadAction<TransactionReportResponse>) {
            state.transactionCount = action.payload.total
            state.transactions = action.payload.transactions
            state.teams = action.payload.teams
        },
        setTransactionDetails(state, action: PayloadAction<TransactionDetailResponse[]>) {
            state.detail = action.payload
        },
        setReportList(state, action: PayloadAction<SFReportResponse[]>) {
            state.reportList = action.payload
        },
        setReportData(state, action: PayloadAction<SFReportDataResponse>) {
            state.reportData = action.payload
        },
        setReportFolders(state, action: PayloadAction<DocumentListResponse>) {
            state.folders = action.payload.folders
        }
    }
})

export const { setReportIndex, setTransactions, setTransactionDetails, setReportList, setReportData, setReportFolders } = reportSlice.actions

export default reportSlice.reducer

export const fetchReportIndex = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const content = await getReportIndex()
        dispatch(setReportIndex(content))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchTransactionReport = (page: number, team: number): AppThunk<Promise<void>> => async dispatch => {
    try {
        const content = await getTransactionReport(page,team)
        dispatch(setTransactions(content))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchTransactionDetailReport = (transactionId: number): AppThunk<Promise<void>> => async dispatch => {
    try {
        const content = await getTransactionDetailReport(transactionId)
        dispatch(setTransactionDetails(content))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchSFReportList = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const content = await getSFReportList()
        dispatch(setReportList(content))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchSFReportData = (reportId: number, page: number, limit: number, filterValues: FilterValues, orderBy?: string, order?: string, first?: boolean): AppThunk<Promise<void>> => async dispatch => {
    try {
        const content = await getSFReportData(reportId, page, limit, filterValues, orderBy, order, first)
        dispatch(setReportData(content))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchReportDocumentList = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const content = await getDocuments()
        dispatch(setReportFolders(content))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
