import React from 'react'
import { Typography } from '@material-ui/core'

import { formatCurrency } from 'utils/currency'
import Price from 'models/Price'

export interface PriceRendererProps extends React.HTMLAttributes<HTMLElement> {
    isFree: boolean
    price: Price
    per?: string
    sx?: object
}

const PriceRenderer: React.FC<PriceRendererProps> = (props) => {
    const { className, isFree, price, per, sx } = props

    if (isFree) {
        return <Typography style={sx} className={className} color="inherit">FREE</Typography>
    } else if (price.showDiscounts && price.originalAmount && price.amount !== price.originalAmount) {
        return (<>
            <Typography style={sx} className={className} color="inherit">
                    {formatCurrency(price.amount, price.currency)} {price.currencyName}&nbsp;&nbsp;
                <span style={{ textDecoration: 'line-through' }}>
                    {formatCurrency(price.originalAmount, price.currency)} {price.currencyName}
                </span> {per}
            </Typography>
        </>);
    } else if ( (!price.originalAmount && price.amount === 0)) {
        return <Typography style={sx} className={className} color="inherit">Pricing not available</Typography>
    } else {
        return <Typography style={sx} className={className} color="inherit">{formatCurrency(price.amount, price.currency)} {price.currencyName} {per}</Typography>
    }
}

export default PriceRenderer
