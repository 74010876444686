import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginTop: '2rem',
        marginBottom: '1rem',
        '@media(max-width: 599px)': {
            justifyContent: 'center'
        }
    },
    divider: {
        marginTop: 10,
        marginBottom: 30
    },
    sameAs: {
        marginTop: 30
    },
    buttonBar: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 40
    },
    useMyAddressButon: {
        marginLeft: 16
    },
}))
