import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import NotificationContact from 'models/NotificationContact'

interface CartSubmitState {
    submit: boolean
    localSubmit: boolean // Collect data locally before submitting for reals
    cartValid: boolean  // In the future this could be an number where cart is valid when zero
    notificationContacts: NotificationContact[] | null
}

const initialState: CartSubmitState = {
    submit: false,
    localSubmit: false,
    cartValid: true,
    notificationContacts: null as NotificationContact[] | null
}

const CartSubmitSlice = createSlice({
    name: 'cartSubmit',
    initialState,
    reducers: {
        setCartSubmit(state: CartSubmitState, action: PayloadAction<boolean>) {
            state.submit = action.payload
        },
        setLocalSubmit(state: CartSubmitState, action: PayloadAction<boolean>) {
            state.localSubmit = action.payload
        },
        setCartValid(state: CartSubmitState, action: PayloadAction<boolean>) {
            state.cartValid = action.payload
        },
        setCartNotificationContacts(state: CartSubmitState, action: PayloadAction<NotificationContact[]>) {
            state.notificationContacts = action.payload
        }
    }
})

export const { setCartSubmit, setLocalSubmit, setCartValid, setCartNotificationContacts } = CartSubmitSlice.actions

export default CartSubmitSlice.reducer
