import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Container, Typography } from '@material-ui/core'

import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'

interface ContentPageUrlSegmentParams {
    urlSegment: string
}

interface ContentPageProps { }

const ContentPage: React.FC<ContentPageProps> = (props) => {
    const params = useParams<ContentPageUrlSegmentParams>()
    const contentPages = useSelector(
        (state: RootState) => state.content.pages
    )

    const page = contentPages.find(page => page.urlSegment === params.urlSegment)

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column' }}>
                {contentPages.length > 0 ? (
                        page ?  
                            <Box dangerouslySetInnerHTML={{ __html: page.content }} />
                        :
                            <Typography align="center" variant="h5" color="primary">Not Found</Typography>
                    )
                :
                    <LoadingWidget />
                }
            </Container>
        </Box>
    )
}

export default ContentPage
