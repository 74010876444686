import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ErrorState = string | null

const initialState: ErrorState = null as ErrorState

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError(state: ErrorState, action: PayloadAction<ErrorState>) {
            return action.payload
        }
    }
})

export const { setError } = errorSlice.actions

export default errorSlice.reducer
