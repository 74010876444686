import React, { useCallback } from 'react'
import { Box, Button, IconButton, Typography } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import ClearIcon from '@material-ui/icons/HighlightOff'

import { useThunkDispatch } from 'app/store'
import { useStyles } from './FileUploadWidget.styles'
import { setError } from 'components/widgets/ErrorMessageWidget'

interface TotalsWidgetProps {
    file?: File,
    onFileSelected: (file?: File) => void
}

const TotalsWidget: React.FC<TotalsWidgetProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()

    const maxFileSize = 2000000
    const acceptedFileTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"]

    const onDropAccepted = useCallback(acceptedFiles => {
        console.log("Accepted", acceptedFiles)
        props.onFileSelected(acceptedFiles[0])
    }, [props])

    const onDropRejected = useCallback(rejectedFiles => {
        console.log("Rejected", rejectedFiles)
        const file = rejectedFiles[0]
        
        let error = "Unknown Error"
        if (!acceptedFileTypes.includes(file.type)) {
            error = "Invalid File Type! Supported file types inlude PDF and Microsoft Word (.docx, doc)"
        } else if (file.size > maxFileSize){
            error = "File size cannot exceed 2MB"
        }
        dispatch(setError(error))
    }, [dispatch, acceptedFileTypes])

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        accept: acceptedFileTypes,
        multiple: false,
        maxSize: maxFileSize,
        onDropAccepted,
        onDropRejected
    })

    const handleRemoveFile = () => {
        props.onFileSelected(undefined)
    }

    return (
        <React.Fragment>
            {props.file ? (
                <Box className={classes.root} style={{ flexDirection: "row" }}>
                    <Typography variant="body1" color="textSecondary">{props.file.name}</Typography>
                    <IconButton aria-label="remove" onClick={() => handleRemoveFile()}>
                        <ClearIcon />
                    </IconButton>
                </Box>
            ) : (
                    <Box {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <Box className={clsx(classes.root, isDragAccept && classes.acceptFile, isDragReject && classes.rejectFile)} >
                            <Typography variant="body1" color="textSecondary">Drag & Drop a File Here</Typography>
                            <Typography className={classes.spacedText} variant="body2">or</Typography>
                            <Button color="primary">Upload File</Button>
                        </Box>
                    </Box>

                )}
        </React.Fragment>
    )
}

export default TotalsWidget
