import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    inputLabel: {
        '@medial(max-width: 599px)': { 
            left: '50%', 
            transform: 'translate(-50%, 0) scale(0.75)' 
        }
    }
}))
