import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik, FormikHelpers, Form} from 'formik'
import { Box, Button, Divider, Grid, MenuItem, Typography } from '@material-ui/core'
import * as yup from 'yup'
import { useStyles } from './CourseRequestForm.styles'
import { RootState } from 'app/rootReducer'
import FormTextField from 'components/widgets/form/FormTextField'
import FormSelect from 'components/widgets/form/FormSelect'
import FormStatusLogger from 'components/widgets/form/FormStatusLogger'
import ErrorMessageWidget from 'components/widgets/ErrorMessageWidget'
import { sendCourseRequest } from 'api/requestsApi'
import { formatDate } from 'utils/date'
import { useThunkDispatch } from 'app/store'

interface FormValues {
    firstName: string
    lastName: string
    email: string
    type: string
    request: string
    comments: string
}

const validationSchema = yup.object({
    firstName: yup.string().required("First Name is a required field."),
    lastName: yup.string().required("Last Name is a required field."),
    email: yup.string().required("Email is a required field."),
    type: yup.string().required("Type is a required field."),
    request: yup.string().required("Title is a required field.")
})

interface CourseRequestFormProps { }

const CourseRequestForm: React.FC<CourseRequestFormProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()
    const member = useSelector(
        (state: RootState) => state.member.member
    )

    const initialValues = {
        firstName: member?.contact.firstName || "",
        lastName: member?.contact.lastName || "",
        email: member?.contact.email || "",
        type: "",
        request: "",
        comments: ""
    }

    if (location.state) {
        const { item } = location.state
        if (item.type && item.type === "OtherDate") {
            initialValues.type = 'Date'
            initialValues.request = `Private events: request for other date`
        } else if (item.type && item.type === "RegisterInterest" && item.course) {
            initialValues.type    = 'RegisterInterest';
            initialValues.request = item.course.name +' '+(item.course.code ? '('+item.course.code+')' : '');
        } else if(item.course) {
            initialValues.type = 'Date'
            initialValues.request = `${item.course.name} request for other date`
        } else if(item.event) {
            const eventDate = `${formatDate(item.event.startDate)}-${formatDate(item.event.endDate)}\n${item.event.startTime}-${item.event.endTime} ${ item.event.timezone}`
            const message = `${item.event.name}\n${eventDate}\n${item.event.format}\n${item.event.deliveryFormat} ${item.event.locationName}` 

            initialValues.type = 'Enroll'
            initialValues.request = `${item.event.name} request to enroll`
            initialValues.comments = message
        }
    }

    const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        setSubmitting(true)
        const res = await dispatch(sendCourseRequest(values))
        setSubmitting(false)
        
        let text = "We will review your course request as soon as possible."
        switch(values.type) {
            case "Topic":
                text = "We will review your topic request as soon as possible."
                break
            case "Date":
                text = "Thanks for requesting another date, we will get back to you as soon as possible"
                break
            case "Enroll":
                text = "Thanks for the enrollment request, we will get back to you as soon as possible."
                break
            case "PrivateEvent":
                text = "Thanks for the private event request, we will get back to you as soon as possible."
                break
            case "GroupTraining":
                text = "Thanks for the group training request, we will get back to you as soon as possible."
                break
        }

        if (res) {
            history.push("/thankyou", { text })
        }
    }

    const handleCancelButton = () => {
        history.goBack()
    }

    return (
        <React.Fragment>
            <Typography variant="h3" color="primary">Training Request</Typography>
            <Typography>Use this form to request dates, enrollment and an additional training topic or course.</Typography>
            <Divider className={classes.divider} />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    isSubmitting,
                    errors
                }) => {
                    return (
                        <React.Fragment>
                            <Form>
                                <Grid container spacing={4}>
                                    <Grid item sm={4}>
                                        <FormTextField label="First Name" name={'firstName'} />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <FormTextField label="Last Name" name={'lastName'} />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <FormTextField label="Email" name={'email'}  />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <FormSelect
                                            id={'type'}
                                            label="Request Type"
                                            name="type"
                                        >
                                            <MenuItem value="Course">Course</MenuItem>
                                            <MenuItem value="Topic">Topic</MenuItem>
                                            <MenuItem value="Date">Other Date</MenuItem>
                                            <MenuItem value="Enroll">Enroll</MenuItem>
                                            <MenuItem value="RegisterInterest">Register Interest</MenuItem>
                                            <MenuItem value="PrivateEvent">Private Event</MenuItem>
                                            <MenuItem value="GroupTraining">Group Training</MenuItem>
                                        </FormSelect>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <FormTextField label="Title" name={'request'} />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <FormTextField
                                            name="comments"
                                            label="Additional training requirements or comments"
                                            multiline={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Box className={classes.buttonBar}>
                                    <Button variant="text" onClick={handleCancelButton}>Cancel</Button>
                                    &nbsp;
                                    <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">Submit</Button>
                                </Box>
                            </Form>
                            <FormStatusLogger values={values} errors={errors} />
                        </React.Fragment>
                    )
                }}
            </Formik>
            <ErrorMessageWidget />
        </React.Fragment>
    )
}

export default CourseRequestForm
