import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { TrainingResourceResponse, getTrainingResources } from 'api/trainingResourcesApi'
import TrainingResource from 'models/TrainingResource'
import { setError } from './errorSlice'

export type TrainingResourceState = TrainingResource

const initialState: TrainingResourceState = {
    typeFilters: {},
    brandFilters: {},
    types: []
}

const trainingResourcesSlice = createSlice({
    name: 'trainingResources',
    initialState,
    reducers: {
        setTrainingResources(state: TrainingResourceState, action: PayloadAction<TrainingResourceResponse>) {
            return action.payload
        }
    }
})

const { setTrainingResources } = trainingResourcesSlice.actions

export default trainingResourcesSlice.reducer

export const fetchTrainingResources = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const resources = await getTrainingResources()
        dispatch(setTrainingResources(resources))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
