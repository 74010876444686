import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { useStyles } from './HrTextWidget.styles'

export interface HrTextWidgetProps {
    text: string
 }

const HrTextWidget: React.FC<HrTextWidgetProps> = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.borderCenter}>
            <Typography className={classes.text}>{props.text}</Typography>
        </Box>
    )
}

export default HrTextWidget
