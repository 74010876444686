import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography, Box, Button, Hidden } from '@material-ui/core'

import { useStyles} from './BrandSearchGrid.styles'
import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { fetchCategories } from 'slices/categoriesSlice'
import SearchBox from 'components/widgets/SearchBox'
import BrandGrid from './BrandGrid'
import LoadingWidget from 'components/widgets/LoadingWidget'

interface BrandSearchGridProps {
    showAllBrands: boolean
    onShowAllBrandsClick: () => void
    onSearch: (searchTerms: string) => void
}

const BrandSearchGrid: React.FC<BrandSearchGridProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const categories = useSelector(
        (state: RootState) => state.categories
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )
    
    const [loadingCategories, setLoadingCategories] = useState(false)
    const { showAllBrands, onShowAllBrandsClick, onSearch } = props

    // number of categories to show before displaying display more button
    const INITIAL_CATEGORIES = settings.reseller.brandTileLimit ? settings.reseller.brandTileLimit : 12;

    useEffect(() => {
        const loadAsync = async () => {
            setLoadingCategories(true)
            await dispatch(fetchCategories())
            setLoadingCategories(false)
        }
        loadAsync()
    }, [dispatch])

    useEffect(() => {
        if (categories.length === 1) {
            history.replace(categories[0].url, [{ categoryID: categories[0].id }, categories[0].name, [{
                name: categories[0].name,
                url: categories[0].url,
                params: { categoryID: categories[0].id }
            }]])
        }
    }, [categories, history])

    const initialCategories = categories.slice(0, INITIAL_CATEGORIES)
    
    if (initialCategories.length > 0) {
        return (
            <>
                <Typography align="center" component="h5" variant="h5" style={{ marginBottom: 48, color: 'inherit' }}>
                    View our courses by brand or search below:
                </Typography>

                {!showAllBrands && (
                    <BrandGrid categories={initialCategories}/>
                )}

                <Box display="flex" alignItems="center">
                    <SearchBox placeholder="Narrow down your search" onSearch={onSearch} />
                    <Hidden xsDown>
                        {!showAllBrands && (
                            <Button className={classes.allbrandsButton} variant="contained" color="primary" onClick={onShowAllBrandsClick}>View All Brands</Button>
                        )}
                    </Hidden>
                </Box>
            </>
        )
    }

    return loadingCategories ? <LoadingWidget /> : null
}

export default BrandSearchGrid
