import RestUtils from 'utils/RestUtils'
import CourseList from 'models/CourseList'

export interface CourseListResponse extends CourseList { }
export interface CourseListParams {
    categoryID?: number
    terms?: string
    formats?: string[]
    locationIDs?: number[]
    urlSegment?: string
    gtr?: boolean
    page?: number
    trainingCredit?:boolean
}

export async function getCourses(params: CourseListParams): Promise<CourseListResponse> {
    const url = "/training/courses"

    const { data } = await RestUtils.get<CourseListResponse>(url, { ...params })

    return data
}
