import React from 'react'
import { Link, Toolbar } from '@material-ui/core'

import { useStyles } from './AppFooter.styles'

export interface AppFooterProps { }

const AppFooter: React.FC<AppFooterProps> = (props) => {
    const classes = useStyles()

    const handlePrivacyPolicy = () => {
        window.open("https://www.exitcertified.com/privacy-policy", '_blank')
    }

    const handleTermsAndConditions = () => {
        window.open("https://www.exitcertified.com/terms/", '_blank')
    }

    return (
        <Toolbar className={classes.root}>
            <Link title="Privacy Policy" onClick={handlePrivacyPolicy}>Privacy Policy</Link>
            <Link title="Terms and Conditions " onClick={handleTermsAndConditions}>Terms and Conditions</Link>
        </Toolbar>
    )
}

export default AppFooter
