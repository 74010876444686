import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { setCartSubmit } from 'slices/cartSubmitSlice'

const AutoSubmitForm: React.FC = () => {
    const dispatch = useThunkDispatch()
    const { submitForm } = useFormikContext()
    const cartSubmit = useSelector(
        (state: RootState) => state.cartSubmit.submit
    )

    useEffect(() => {
        if (cartSubmit) {
            console.log("Submit form")
            submitForm()
                .catch(err => {
                    console.log("*** Payment form error", err)
                })
                .finally(() => {
                    console.log("Reset Cart submit")
                    dispatch(setCartSubmit(false))
                })
        }
    }, [dispatch, cartSubmit, submitForm])

    return null
}

export default AutoSubmitForm
