import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { OrderParams, PromoCodeParams, OrderResponse, createOrder, deleteFromOrder, getOrder, createPromoCode, deletePromoCode, getOrderFinish } from 'api/orderApi'
import Order from 'models/Order'
import OrderItem from 'models/OrderItem'
import { setError } from './errorSlice'

type OrderState = Order

const initialState: OrderState = {
    items: [],
    subtotal: 0,
    tax: 0,
    total: 0,
    currency: "",
    currencyName: ""
}

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrder(state, action: PayloadAction<OrderResponse>) {
            return action.payload
        }
    }
})

export const { setOrder } = orderSlice.actions

export default orderSlice.reducer

export const fetchOrder = (currency?: string): AppThunk<Promise<void>> => async dispatch => {
    try {
        const order = await getOrder(currency)
        dispatch(setOrder(order))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const addItemToOrder = (item: OrderItem, contactIds?: number[]): AppThunk<Promise<void>> => async dispatch => {
    try {
        let params: OrderParams = {}
        if (item.bundle != null) {
            params = { bundleID: item.bundle.id, contactIDs: contactIds }
        } else if (item.event != null) {
            params = { eventID: item.event.id, contactIDs: contactIds, waitList: !!item.waitList }
        } else {
            params = { courseID: item.course?.id, contactIDs: contactIds }
        }

        const order = await createOrder(params)
        dispatch(setOrder(order))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const addToOrder = (params: OrderParams): AppThunk<Promise<void>> => async dispatch => {
    try {
        const order = await createOrder(params)
        dispatch(setOrder(order))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const removeFromOrder = (item: OrderItem, contactIds?: number[]): AppThunk<Promise<void>> => async dispatch => {
    try {
        let params: OrderParams = {}
        if (item.bundle != null) {
            params = { bundleID: item.bundle.id, contactIDs: contactIds }
        } else if (item.event != null) {
            params = { eventID: item.event.id, contactIDs: contactIds }
        } else {
            params = { courseID: item.course?.id, contactIDs: contactIds }
        }
        
        const order = await deleteFromOrder(params)
        dispatch(setOrder(order))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const validatePromoCode = (item: OrderItem, promoCode: string): AppThunk<Promise<boolean>> => async dispatch => {
    try {
        let params: PromoCodeParams = {promoCode}
        if (item.bundle != null) {
            params = { bundleID: item.bundle.id, promoCode }
        } else if (item.event != null) {
            params = { eventID: item.event.id, promoCode }
        } else {
            params = { courseID: item.course?.id, promoCode }
        }

        const order = await createPromoCode(params)
        dispatch(setOrder(order))
        return true
    } catch (err) {
        dispatch(setError(err.toString()))
        return false
    }
}

export const removePromoCode = (item: OrderItem): AppThunk<Promise<void>> => async dispatch => {
    try {
        let params: OrderParams = {}
        if (item.bundle != null) {
            params = { bundleID: item.bundle.id }
        } else if (item.event != null) {
            params = { eventID: item.event.id }
        } else {
            params = { courseID: item.course?.id }
        }

        const order = await deletePromoCode(params)
        dispatch(setOrder(order))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchOrderFinish = (orderId?: string, orderHash?: string): AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const order = await getOrderFinish(orderId, orderHash)
        dispatch(setOrder(order))
        return true
    } catch (err) {
        dispatch(setError(err.toString()))
        return false
    }
}
