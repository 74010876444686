import React from "react"
import DateFnsUtils from '@date-io/date-fns'
import { TextFieldProps } from "@material-ui/core/TextField"
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'

import ECTextField from "./ECTextField"

export interface ECDatePickerProps extends KeyboardDatePickerProps {}

const DateTextField = ({
    InputProps,
    label,
    disabled,
    error,
    helperText,
    onChange,
    value,
    inputRef,
}: TextFieldProps) => {
    const { endAdornment } = InputProps!
    return (
        <ECTextField
            inputRef={inputRef}
            endAdornment={endAdornment}
            label={label}
            disabled={disabled}
            error={error}
            helperText={helperText}
            onChange={onChange}
            value={value}
        />
    )
}

const ECDatePicker: React.FC<ECDatePickerProps> = ({
  format,
  label,
  value,
  onChange,
  className
}) => {   
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={format}
                fullWidth={true}
                margin="normal"
                label={label}
                value={value}
                onChange={onChange}
                className={className}
                TextFieldComponent={DateTextField}
            />
        </MuiPickersUtilsProvider>
    )
}

export default ECDatePicker
