import React from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@material-ui/core';


export interface LoadingWidgetProps extends CircularProgressProps {}

const defaultProps: LoadingWidgetProps = {
    style: { marginTop: 60 },
    color: "primary"
}

const LoadingWidget: React.FC<LoadingWidgetProps> = (props) => {
    return (
        <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress {...props} />
        </Box>
    )
}

LoadingWidget.defaultProps = defaultProps

export default LoadingWidget
