import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { getAddons } from 'api/addonsApi';
import Course from 'models/Course';
import { setError } from './errorSlice';

interface AddonState {
    addons: Course[]
}

const initialState: AddonState = {
    addons: []
}

const addonsSlice = createSlice({
    name: 'addons',
    initialState,
    reducers: {
        setAddons(state, action: PayloadAction<Course[]>) {
            state.addons = action.payload
        }
    }
})

export const { setAddons } = addonsSlice.actions

export default addonsSlice.reducer

export const fetchAddons = (courseId: number): AppThunk<Promise<void>> => async dispatch => {
    try {
        const addons = await getAddons(courseId)
        var addon_courses: Course[] = [];
        addons.courses.forEach( (course) => {
            course.addonParent = courseId;
            addon_courses.push(course);
        }); 
        dispatch(setAddons(addon_courses))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}