import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Box, Button, Container, Typography } from '@material-ui/core'

import { RootState } from 'app/rootReducer'
import config from 'config/Config'
import { useThunkDispatch } from 'app/store'
import { setError } from 'slices/errorSlice'
import { fetchOrderFinish } from 'slices/orderSlice'
import { updateContactExtraInfo } from 'slices/contactListSlice'
import CompleteRegistrationCard from 'components/orderfinish/CompleteRegistrationCard'
import LoadingWidget from 'components/widgets/LoadingWidget'

interface OrderFinishParams {
    orderId?: string
    orderHash?: string
}

interface OrderFinishProps { }

const OrderFinishPage: React.FC<OrderFinishProps> = (props) => {
    const dispatch = useThunkDispatch()
    const location = useLocation()
    const { orderId, orderHash } = useParams<OrderFinishParams>()
    const order = useSelector(
        (state: RootState) => state.order
    )
    const [loadingOrder, setLoadingOrder] = useState(false)

    const error = useSelector(
        (state: RootState) => state.error
    )
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    useEffect(() => {
        const loadAsync = async () => {
            setLoadingOrder(true)
            await dispatch(fetchOrderFinish(orderId, orderHash))
            setLoadingOrder(false)
        }

        loadAsync()
    }, [dispatch, orderId, orderHash])

    useEffect(() => {
        if (error) {
            // Need to capture the error so when can still display it after has been reset
            setErrorMessage(error)
            dispatch(setError(null))
        }
    }, [dispatch, error])

    const handleSelectionChange = (contactId: number, data: any) => {
        dispatch(updateContactExtraInfo(contactId, data))
    }

    const handleButtonClick = () => {
        window.location.href = (config.BaseName ? config.BaseName : "/")
    }

    const afterPaymentFieldsCount = order.items.reduce((acc1, item) => item.students.reduce((acc1, student) => student.afterPaymentFields?.length ?? 0, 0), 0) 
    const haveAfterPaymentFields = order.id && afterPaymentFieldsCount > 0
    const shareVideoText = "Students who share their video in class report higher satisfaction with their class. Your satisfaction is important to us, so we’ll provide you complimentary headphones and web camera that are yours to keep. Just click Yes below, and provide us the shipping address where we can send your kit."

    return (
        <Box component="main" style={{ marginBottom: '2rem' }}>
            <Container maxWidth='md' fixed={true}>
                {errorMessage ? 
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '150px' }}>
                        <Typography variant="h3" color="error">Order Not Found</Typography>
                    </Box>
                :
                    loadingOrder?
                        <LoadingWidget />
                    :
                        <React.Fragment>
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: haveAfterPaymentFields ? 'flex-start' : 'center', marginTop: '30px' }}>
                                <Typography variant="h3" color="primary">{haveAfterPaymentFields ? "Your upcoming event" : location.state?.title ?? "Thank You!"}</Typography>
                                <Typography variant="body1">{haveAfterPaymentFields ? shareVideoText : location.state?.text}</Typography>
                            </Box>

                            {haveAfterPaymentFields &&
                                <Box style={{ display: 'flex', flexDirection: 'column'}}>
                                    <Typography variant="h6" style={{marginTop: '2rem'}}>Interact to get the most out of your class with video and audio</Typography>
                                    {order.items.map(item => (
                                        item.students.map(student =>
                                            <CompleteRegistrationCard key={student.contact.id} item={item} student={student} onSelectionChange={handleSelectionChange} />
                                        )
                                    ))}
                                </Box>
                            }   
                        </React.Fragment>
                }
                <Box display="flex" flexDirection="column" alignItems={haveAfterPaymentFields ? "flex-end": "center"}>
                    <Button style={{ marginTop: 50 }} color="primary" variant="contained" onClick={handleButtonClick}>Search For More Training</Button>
                </Box>
            </Container>
        </Box>
    )
}

export default OrderFinishPage
