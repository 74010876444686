import React, { useEffect, useState, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Button, Container, Typography, MenuItem, InputLabel, FormHelperText, FormControl  } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import BackArrowIcon from '@material-ui/icons/KeyboardBackspace'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { fetchTransactionReport } from 'slices/reportsSlice'
import { downloadTransactionReportData } from 'api/reportsApi'
import LoadingWidget from 'components/widgets/LoadingWidget'
import TransactionReport from 'components/reports/TransactionReport'
import { setError } from 'slices/errorSlice'

interface TransactionReportPageProps { }

const TransactionReportPage: React.FC<TransactionReportPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const transactionCount = useSelector(
        (state: RootState) => state.reports.transactionCount
    )
    const transactions = useSelector(
        (state: RootState) => state.reports.transactions
    )
    const teams = useSelector(
        (state: RootState) => state.reports.teams
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )
    const error = useSelector(
        (state: RootState) => state.error
    )

    const [page, setPage] = useState(0)
    const [loadingReport, setLoadingReport] = useState(false)
    const [team, setTeam] = useState(0)

    useEffect(() => {
        const loadAsync = async (page: number, team: number) => {
            setLoadingReport(true)
            await dispatch(fetchTransactionReport(page, team))
            setLoadingReport(false)
        }

        console.log("Loading page:", page)
        loadAsync(page,team)
    }, [dispatch, page, team])

    useEffect(() => {
        if (error === "Error: Permission Denied") {
            dispatch(setError(null))
            history.push("/login")
        }
    }, [dispatch, history, error])

    const handleTransactionClick = (transactionId: number) => {
        history.push(`/reports/transactions/${transactionId}`)
    }

    const handlePageChanged = (page: number) => {
        setPage(page)
    }

    const handleTeamChange = (event: ChangeEvent<any>) => {
        setTeam(event.target.value);
    }

    const handleReportsButton = () => {
        history.goBack()
    }

    const handleDownloadClick = async () => {
        const response = await downloadTransactionReportData(team)
        const fileName = response.headers['content-disposition'].split('filename="')[1].split('"')[0]
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
        a.remove()
    }

    const teamLabel = (plural: boolean = false) => {
        if (settings.reseller.portalType === 'GSA') {
            return plural ? 'Agencies' : 'Agency'
        }

        return plural ? 'Teams' : 'Team'
    }

    const renderTransactionReport = () => {
        return (
            <React.Fragment>
                { teams.length > 1 && 
                <FormControl style={{minWidth:"210px"}}>
                    <InputLabel id="Teams.label">Filter By {teamLabel()}</InputLabel>
                    <Select
                        labelId="Teams.label"
                        id={teamLabel(true)}
                        onChange={handleTeamChange}
                        value={team}
                    >
                        <MenuItem key={0} value={0}>
                            All {teamLabel(true)}
                        </MenuItem>
                        {teams.map(team => (
                            <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText></FormHelperText>
                </FormControl>
                }
                <TransactionReport  skipTeam={(teams.length===0)} 
                                    transactions={transactions} 
                                    page={page} 
                                    transactionCount={transactionCount} 
                                    onTransactionClick={handleTransactionClick} 
                                    onPageChanged={handlePageChanged}
                                    customTeamLabel={teamLabel()}/>
                {transactions.length > 0 && 
                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button variant="contained" color="primary" onClick={() => handleDownloadClick()}>Download Transactions</Button>
                    </Box>
                }
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Box component="main" style={{ marginBottom: '2rem' }}>
                <Container maxWidth="xl" fixed={true}>
                    <Box style={{ marginTop: '1rem' }} >
                        <Button color="primary" startIcon={<BackArrowIcon />} onClick={handleReportsButton}>Return to Reports</Button>
                    </Box>
                    <Typography align="center" variant="h3" color="primary" style={{ marginBottom: '3rem' }}>
                        Transaction Report
                    </Typography>

                    {loadingReport ? (
                        <LoadingWidget />
                    ) : (
                        renderTransactionReport()
                    )}
                </Container>
            </Box>
        </React.Fragment>
    )
}

export default TransactionReportPage
