import React from 'react'
import { MenuItem, Typography } from '@material-ui/core'

import { useStyles } from './TrainingResourceFilters.styles'
import ECSelect from 'components/widgets/ECSelect'

export interface TrainingResourceFilters {
    [key: string]: string
}
export type TraininingResourceCallback = (value: string) => void
export type TrainingResourceFilterValue = string | null

interface TrainingResourceFiltersProps {
    typeFilters: TrainingResourceFilters
    brandFilters: TrainingResourceFilters
    typeValue?: TrainingResourceFilterValue
    brandValue?: TrainingResourceFilterValue
    onTypeSelect: TraininingResourceCallback
    onBrandSelect: TraininingResourceCallback
}

const TrainingResourceFilters: React.FC<TrainingResourceFiltersProps> = (props) => {
    const classes = useStyles()
    const { typeFilters, brandFilters, typeValue, brandValue } = props

    const renderDropDown = (id: string, name: string, callback: TraininingResourceCallback, filters: TrainingResourceFilters, value?: TrainingResourceFilterValue) => {
        return (
            <ECSelect 
                id={id}
                label={name}
                className={classes.select}
                value={value || ''}
                onChange={e => callback(e.target.value as string)}
            >
                <MenuItem key={0} value="">
                    <Typography color="textSecondary">-- No Selection --</Typography>
                </MenuItem>
                {Object.keys(filters).map(key =>
                    <MenuItem key={key} value={key}>{filters[key]}</MenuItem>
                )}
            </ECSelect>
        )
    }

    return (
        <React.Fragment>
            {renderDropDown("typeFilter", "  Resource Type", props.onTypeSelect, typeFilters, typeValue)}
            {renderDropDown("brandFilter", "Topic", props.onBrandSelect, brandFilters, brandValue)}
        </React.Fragment>
    )
}

export default TrainingResourceFilters
