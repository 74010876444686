import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Container, Grid, Typography, Button, useTheme } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { fetchBundleSummary } from 'slices/bundleSlice'
import { formatCurrency } from 'utils/currency'
import { addToOrder } from 'slices/orderSlice'
import { formatDate } from 'utils/date'

interface BundleSummaryPageUrlSegmentParams {
    bundleId: string
}

interface BundleSummaryPageProps { }

const BundleSummaryPage: React.FC<BundleSummaryPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const theme = useTheme()
    const history = useHistory()
    const {bundleId} = useParams<BundleSummaryPageUrlSegmentParams>()
    const bundleSummary = useSelector(
        (state: RootState) => state.bundles.summary
    )

    useEffect(() => {
        dispatch(fetchBundleSummary(Number(bundleId)))
    }, [dispatch, bundleId])

    const handleAddToCart = async () => {
        await dispatch(addToOrder({ bundleID: bundleSummary?.id }))
        history.push("/order")
    }

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column' }}>
                {bundleSummary ? (
                    <React.Fragment>
                        <Typography style={{ textTransform: 'uppercase', textAlign: 'center' }} variant="h3">Summary</Typography>
                        <Typography style={{ marginTop: 30, textAlign: 'center' }} variant="h6" color="primary">{`${bundleSummary.name} (${bundleSummary.code})`}</Typography>
                        <Grid container style={{ marginTop: 30}}>
                            <Grid item sm={6}>
                                <Typography variant="overline">Course/Event</Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant="overline">Date</Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant="overline">Time</Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant="overline">Location</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}><hr/></Grid>
                            {bundleSummary.courses.map(course => 
                                <React.Fragment key={course.id}>
                                    <Grid item sm={6}>
                                        <Typography>{course.name}</Typography>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography>{}</Typography>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography>{}</Typography>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography>{}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}><hr style={{ borderTop: '1px solid', borderTopColor: theme.palette.grey[200]}} /></Grid>
                                </React.Fragment>
                            )}
                            {bundleSummary.events.map(event =>
                                <React.Fragment key={event.id}>
                                    <Grid item sm={6}>
                                        <Typography>{event.name}</Typography>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography>{formatDate(event.startDate)}</Typography>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography>{event.startTime}</Typography>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Typography>{event.locationName}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}><hr style={{ borderTop: '1px solid', borderTopColor: theme.palette.grey[200] }} /></Grid>
                                </React.Fragment>
                            )}
                            <Grid item style={{marginTop: 30}} xs={12} sm={12}></Grid>
                            <Grid item sm={7}></Grid>
                            <Grid item sm={3}>
                                <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>Individual Item Total:</Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography style={{ textAlign: 'right' }}>{formatCurrency(bundleSummary.subTotal, bundleSummary.currency)}</Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography></Typography>
                            </Grid>
                            <Grid item sm={7}></Grid>
                            <Grid item sm={3}>
                                <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>Bundle Discount:</Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography style={{textAlign: 'right'}}>{formatCurrency(bundleSummary.discount, bundleSummary.currency)}</Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography>&nbsp;({bundleSummary.discountPercent}%)</Typography>
                            </Grid>
                            <Grid item sm={7}></Grid>
                            <Grid item sm={3}>
                                <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>Bundled Price:</Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography style={{ textAlign: 'right' }}>{formatCurrency(bundleSummary.total, bundleSummary.currency)}</Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography>&nbsp;({bundleSummary.currency})</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 30 }} xs={12} sm={12}></Grid>
                            <Grid item sm={2}>
                                <Button variant="contained" color="primary" onClick={handleAddToCart}>Add to Cart</Button>
                            </Grid>
                        </Grid>             
                    </React.Fragment>
                )
                    :
                    <LoadingWidget />
                }
            </Container>
        </Box>
    )
}

export default BundleSummaryPage
