import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { CourseResponse, getCourse } from 'api/courseApi'
import Course from 'models/Course'
import { setError } from './errorSlice'

export type CourseState = Course | null

const initialState: CourseState = null as CourseState

const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        setCourse(state: CourseState, action: PayloadAction<CourseResponse>) {
            return action.payload
        }
    }
})

const { setCourse } = courseSlice.actions

export default courseSlice.reducer

export const fetchCourse = (courseId: number): AppThunk<Promise<Course | null>> => async dispatch => {
    try {
        const course = await getCourse(courseId)
        dispatch(setCourse(course))
        return course
    } catch (err) {
        dispatch(setError(err.toString()))
        return null
    }
}
