import RestUtils from 'utils/RestUtils'
import Course from 'models/Course'

export interface PrivateCoursesListResponse {
    courses: Course[]
}

export async function getPrivateCourses(): Promise<PrivateCoursesListResponse> {
    const url = `/training/privatecourses`

    const { data } = await RestUtils.get<PrivateCoursesListResponse>(url)

    return data
}
