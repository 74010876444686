import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import PlusIcon from '@material-ui/icons/AddCircle'

import { useStyles } from './CourseDescriptionView.styles'
import Course from 'models/Course'

interface CourseListViewProps {
    course: Course
}

const CourseDescriptionView: React.FC<CourseListViewProps> = (props) => {
    const { course } = props
    const classes = useStyles()
    const [showFullDescription, setShowFullDescription] = useState(false)

    const handleShowFullDescription = () => {
        setShowFullDescription(!showFullDescription)
    }

    return (
        <Box>
            <Box className={[classes.faderContainer, showFullDescription ? 'inactive' : null].join(' ')}>
                <Box style={{ position: 'relative', zIndex: 1, height: showFullDescription ? '100%' : 100 }} dangerouslySetInnerHTML={{ __html: course.content }} />
                {!showFullDescription && (
                    <Box className={classes.fader}>&nbsp;</Box>
                )}
            </Box>
            <Button startIcon={<PlusIcon color='primary'/>} onClick={() => handleShowFullDescription()}>{showFullDescription ? 'Show Less' : 'Show More'}</Button>
        </Box>
    )
}

export default CourseDescriptionView
