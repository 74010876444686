import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const imageHeight = '96px'

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {

    },
    header: { 
        position: 'relative'
    },
    media: {
        height: imageHeight,
    },
    darkMask: {
        background: theme.palette.common.black,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: imageHeight,
        opacity: 0.6
    },
    typeLabel: {
        position: 'absolute',
        top: '4rem',
        left: '1rem',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    content: {
        height: '6rem'
    }
}))

