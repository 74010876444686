import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Container, Typography } from '@material-ui/core'
import BackArrowIcon from '@material-ui/icons/KeyboardBackspace'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { fetchSFReportData } from 'slices/reportsSlice'
import SFReportTable, { SortOrder } from 'components/reports/SFReportTable'
import SFReportFilters from 'components/reports/SFReportFilters'
import { FilterValues, downloadSFReportData } from 'api/reportsApi'

interface SFReportPageParams {
    reportId: string
}

interface SFReportPageProps { }

const SFReportPage: React.FC<SFReportPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const { reportId } = useParams<SFReportPageParams>()
    const firstRef = useRef(true)
    const [accordionExpanded, setAccordionExpanded] = useState(true)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(100)
    const [filterValues, setFilterValues] = useState<FilterValues>({})
    const [tempFilterValues, setTempFilterValues] = useState<FilterValues>({})
    const [orderBy, setOrderBy] = useState<string>("")
    const [order, setOrder] = useState<SortOrder>("asc")
    const reportData = useSelector(
        (state: RootState) => state.reports.reportData
    )

    useEffect(() => {
        const loadAsync = async () => {
            setLoading(true)
            await dispatch(fetchSFReportData(Number(reportId), page, limit, filterValues, orderBy, order, firstRef.current))
            firstRef.current = false
            setLoading(false)
        }
        loadAsync()
    }, [dispatch, reportId, page, limit, filterValues, orderBy, order, firstRef])
    
    useEffect(() => {
        const newValues: FilterValues = {}
        reportData.filters.forEach(filter => {
            if (filter.default) {
                if (filter.type === "Date") {
                    newValues[filter.id] = new Date(`${filter.default}T12:00`)
                } else {
                    newValues[filter.id] = filter.default
                }
            }
        })
        // console.log("NV", newValues)
        setTempFilterValues(newValues)

        // HACK HACK HACK - we can't really detect changes in the deafult filters, only the number of filters.
        // But the default filters shouldn't change over the life of the report anyway
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData.filters.length])

    const handlePageChange = (page: number) => {
        setFilterValues(tempFilterValues)
        setPage(page)
    }

    const handleLimitChange = (limit: number) => {
        setFilterValues(tempFilterValues)
        setLimit(limit)
    }

    const handleFilterChange = (filterValues: FilterValues) => {
        setTempFilterValues(filterValues)
    }

    const handleFilterReport = () => {
        setFilterValues(tempFilterValues)
    }

    const handleSortChange = (orderBy: string, order: SortOrder) => {
        setFilterValues(tempFilterValues)
        setOrderBy(orderBy)
        setOrder(order)
    }

    const handleReportsButton = () => {
        history.goBack()
    }

    const handleAccordionClick = () => {
        setAccordionExpanded(!accordionExpanded)
    }

    const handleDownloadClick = async (option?: string) => {
        const response = await downloadSFReportData(Number(reportId), page, limit, tempFilterValues, orderBy, order, firstRef.current, option)
        const fileName = response.headers['content-disposition'].split('filename="')[1].split('"')[0]
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
        a.remove()
    }

    return (
        <Box component="main">
            <Container maxWidth='xl' fixed={true} style={{ marginBottom: '2rem' }}>
                <Box style={{ marginTop: '0.25rem', marginBottom: '0.25rem'}} display="flex" justifyContent="space-between">
                    <Button color="primary" startIcon={<BackArrowIcon />} onClick={handleReportsButton}>Return to Reports</Button>
                    <Typography style={{ textAlign: 'center', fontSize: '2rem' }} color="primary" >{reportData.name}</Typography>
                </Box>
                {loading ? (
                    <LoadingWidget />
                ) : (
                    <React.Fragment>
                        {reportData.filters.length > 0 &&
                            <Accordion expanded={accordionExpanded} onChange={handleAccordionClick}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography variant="h6" color="primary">Filters</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <SFReportFilters
                                        filters={reportData.filters}
                                        filterValues={tempFilterValues}
                                        onUpdateFilters={handleFilterChange}
                                        onFilterReport={handleFilterReport}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        }
                        <SFReportTable 
                            reportData={reportData} 
                            page={page} 
                            limit={limit}
                            orderBy={orderBy}
                            order={order}
                            onPageChange={handlePageChange} 
                            onLimitChange={handleLimitChange}
                            onSortChange={handleSortChange}
                        />
                        {reportData.rowCount > 0 && 
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Button variant="contained" color="primary" onClick={() => handleDownloadClick()}>Download Enrollments</Button>
                                {reportData.exportDataOptions.map((option, index) => 
                                        <Button variant="contained" style={{marginLeft:'5px'}} key={index} color="primary" onClick={() => handleDownloadClick(option)}>
                                            { option === 'AttendanceData' ? ("Download Zoom Attendance"):(`Download ${option}`) }
                                        </Button>
                                    )
                                }
                            </Box>
                        }
                    </React.Fragment>
                )}
            </Container>
        </Box>
    )
}

export default SFReportPage
