import RestUtils from 'utils/RestUtils'
import Course from 'models/Course'
import EventList from 'models/CourseEventList'

export interface CourseResponse extends Course { }

export interface CourseEventListResponse extends EventList {

}
export interface CourseEventListParams {
    page?: number,
    gtr?: boolean,
    types?: string[],
    locations?: string[]
}

export async function getCourse(courseId: number): Promise<CourseResponse> {
    const url = `/training/course/${courseId}`

    const { data } = await RestUtils.get<CourseResponse>(url)

    return data
}

export async function getCourseEvents(courseId: number, params: CourseEventListParams): Promise<CourseEventListResponse> {
    const url = `/training/course/${courseId}/events`

    const { data } = await RestUtils.get<CourseEventListResponse>(url, { ...params })

    return data
}
