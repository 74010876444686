import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Container, Typography } from '@material-ui/core'

import { RootState } from 'app/rootReducer'
import AppHeader from 'components/widgets/AppHeader'
import ContactForm from 'components/contacts/ContactForm'

interface CreateContactProps { }

const CreateContactPage: React.FC<CreateContactProps> = (props) => {
    const custom = useSelector(
        (state: RootState) => state.content.custom
    )

    const customContent = custom != null ? custom["ContactPage"] : null

    return (
        <>
            <AppHeader style={(customContent && customContent.backgroundImage)?{backgroundImage:'url('+customContent.backgroundImage+')'}:{}}>
                <Typography style={{ textAlign: 'center' }} variant="h3">Create Student</Typography>
            </AppHeader>
            <Box component="main" style={{ marginBottom: '2rem' }}>
                <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column' }}>
                    <ContactForm showContact showShipping/>
                </Container>
            </Box>
        </>
    )
}

export default CreateContactPage
