import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { BundleResponse, BundleEventListResponse, BundleEventListParams, BundleOrderParams, BundleSummaryResponse, getBundles, getBundleEventList, postBundle, getBundleSummary } from 'api/bundlesApi'
import Bundle from 'models/Bundle'
import BundleEventList from 'models/BundleEvenList'
import { setError } from './errorSlice'
import BundleSummary from 'models/BundleSummary'

interface BundleState {
    bundles: Bundle[]
    bundleEventList?: BundleEventList
    summary?: BundleSummary
}

const initialState: BundleState = {
    bundles:[],
    bundleEventList: undefined,
    summary: undefined
}

const bundlesSlice = createSlice({
    name: 'bundles',
    initialState,
    reducers: {
        setBundles(state, action: PayloadAction<BundleResponse[]>) {
            state.bundles = action.payload
        },
        setBundleEventList(state, action: PayloadAction<BundleEventListResponse>) {
            state.bundleEventList = action.payload
        },
        setBundleSummary(state, action: PayloadAction<BundleSummaryResponse>) {
            state.summary = action.payload
        },
    }
})

export const { setBundles, setBundleEventList, setBundleSummary } = bundlesSlice.actions

export default bundlesSlice.reducer

export const fetchBundles = (courseId?: string): AppThunk<Promise<void>> => async dispatch => {
    try {
        const bundles = await getBundles(courseId)
        dispatch(setBundles(bundles))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchBundleEventList = (bundleId: number, params: BundleEventListParams): AppThunk<Promise<void>> => async dispatch => {
    try {
        const bundleEventList = await getBundleEventList(bundleId, params)
        dispatch(setBundleEventList(bundleEventList))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const addToBundle = (params: BundleOrderParams): AppThunk<Promise<void>> => async dispatch => {
    try {
        const bundleEventList = await postBundle(params)
        dispatch(setBundleEventList(bundleEventList))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchBundleSummary = (bundleId: number): AppThunk<Promise<void>> => async dispatch => {
    try {
        const bundleSummary = await getBundleSummary(bundleId)
        dispatch(setBundleSummary(bundleSummary))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}