import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Container, Typography, Button } from '@material-ui/core';

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { performLogin, performLogout } from 'slices/memberSlice'
import ErrorMessageWidget from 'components/widgets/ErrorMessageWidget';
import ECTextField from 'components/widgets/ECTextField'

interface CartProps { }

const LoginPage: React.FC<CartProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    
    // console.log("LOCATION.STATE", location.state)

    useEffect(() => {
        if (member != null && location.state != null && location.state.action === 'logout') {
            console.log("Logging out")
            dispatch(performLogout())
        }
    }, [dispatch, member, location.state])
    
    useEffect(() => {
        if (member != null) {
            if (location.state == null) {
                console.log(`Login successful, redirecting to /`)
                history.push('/')
            } else if (location.state.action === 'login') {
                console.log(`Login successful, redirecting to ${location.state.next.pathname + location.state.next.search}`)
                history.replace(location.state.next.pathname + location.state.next.search)
            }
        }
    }, [history, member, location.pathname, location.state])

    useEffect(() => {
        if (member == null && location.state != null && location.state.action === 'logout') {
            console.log(`Logout successful, redirecting to ${location.state.next.pathname + location.state.next.search}`)
            history.replace(location.state.next.pathname + location.state.next.search)
        }
    }, [history, member, location, location.pathname, location.state])

    // Don't render anything on logout
    if (location.state != null && location.state.action === 'logout') {
        return null
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    };

    const handleLogin = () => {
        dispatch(performLogin(email, password)) 
    }

    return (
        <React.Fragment>
            <Box component="main">
                <Container maxWidth='sm' fixed={true} style={{marginTop: 240, marginBottom: 120, display: 'flex', flexDirection: 'column'}}>
                    <Typography variant='h6'>Please log in</Typography>
                    <ECTextField 
                        id="username"
                        label="Email"
                        style={{ marginTop: 30}}
                        onChange={handleEmailChange}
                    />
                    <ECTextField 
                        label="Password"
                        type='password'
                        style={{ marginTop: 20 }}
                        onChange={handlePasswordChange}
                    />
                    <Button style={{marginTop: 20, alignSelf: 'flex-end'}} color='primary' variant='contained' onClick={handleLogin}>Log in</Button>
                </Container>
            </Box>
            <ErrorMessageWidget />
        </React.Fragment>
    )
}

export default LoginPage
