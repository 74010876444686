import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {customTheme} from "styles/baseTheme"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    appBar: {
        zIndex: theme.zIndex.modal + 1,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    mobileMenu: {
        zIndex: theme.zIndex.modal + 3,
        backgroundColor: customTheme.toolbarBackground,
        padding: 8,
        '& button': {
            '@media (max-width:959px)': {
                color: customTheme.toolbarText,
            },
        },
        '& .MuiIconButton-label': {
            fontSize: '1rem',
            fontWeight: 'bold',
        },
    },
    toolbar1: {
        display: 'flex',
        justifyContent: 'flex-end',
        background: customTheme.toolbarBackground,
        minHeight: 'inherit',
        paddingTop: 6,
        paddingBottom: 4,
        '& button' : {
            paddingTop: 6,
            paddingBottom: 6,
            color: customTheme.toolbarText,
        },
        '& .MuiSelect-root': {
            padding: '0px 8px 0px 0px',
        },
        '& .MuiIconButton-label': {
            fontSize: '1rem',
            fontWeight: 'bold',
        },
        '& .MuiSelect-icon': {
            color: customTheme.toolbarText,
        },
        '& .single_option .MuiSelect-icon': {
            display: 'none'
        },
    },
    toolbarButton: {
        '@media (min-width:600px)': {
            '& .MuiButton-label': {
                borderTop: '3px solid transparent',
                borderBottom: '3px solid transparent',
                '&:hover': {
                    borderBottom: `3px solid ${customTheme.toolbarHoverColor}`,
                },
            }        
        },
        '@media (max-width:599px)': {
            padding: 8,
            color: customTheme.toolbarText,
            fontSize: '1rem',
            fontWeight: 'bold',
            width: '100%',
            '& .MuiButton-label': {
                justifyContent: 'flex-start'
            },
            '&:hover': {
                backgroundColor: customTheme.toolbarHoverColor,
            },
        }
    },
    countryMenu: {
        '& .MuiMenu-paper': {
            backgroundColor: customTheme.toolbarBackground
        },
        '& .MuiIconButton-label': {
            color: customTheme.toolbarText,
            fontSize: '1rem'
        },
        '& .MuiListItem-button:hover, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: customTheme.toolbarHoverColor
        },
        '& .MuiListItem-root.Mui-selected': {
            backgroundColor: 'inherit'
        }
    },
    xSpacer: {
        flexGrow: 1,
        maxWidth: '24px'
    },
    menuText: {
        textTransform: 'uppercase', 
        cursor: 'pointer',
        '@media (min-width:600px)': {
            borderTop: '3px solid transparent',
            borderBottom: '3px solid transparent',
            '&:hover': {
                borderBottom: `3px solid ${customTheme.toolbarHoverColor}`,
            },
        },
        '@media (max-width:599px)': {
            padding: 8,
            color: customTheme.toolbarText,
            fontSize: '1rem',
            fontWeight: 'bold',
            width: '100%',
            '& .MuiButton-label': {
                justifyContent: 'flex-start',
            },
            '&:hover': {
                backgroundColor: customTheme.toolbarHoverColor,
            },
        },
    },
    menu: {
        backgroundColor: customTheme.toolbarBackground,
        color: theme.palette.primary.contrastText,
        '@media (max-width: 599px)': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '& .MuiListItem-button:hover, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: customTheme.toolbarHoverColor
        }
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
    uiBrand__root: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        width: 'auto',
        height: 48
    },
    uiBrand_start: {
        justifyContent: 'flex-start'
    },
    uiBrand__media: {
        flex: '0 0 auto',
        width: 'auto',
        height: '100%'
    }
}))