import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import OrderItem from 'models/OrderItem'

type SelectedOrderItemState = OrderItem | undefined
type SelectedContactsState = number[] | undefined

interface SelectionsState  {
    selectedItem: SelectedOrderItemState
    selectedContacts: SelectedContactsState
}

const initialState: SelectionsState = {
    selectedItem: undefined as SelectedOrderItemState, 
    selectedContacts: undefined as SelectedContactsState
}

const contactsSelectedSlice = createSlice({
    name: 'selections',
    initialState,
    reducers: {
        setSelectedOrderItem(state, action: PayloadAction<SelectedOrderItemState>) {
            state.selectedItem = action.payload
        },
        setSelectedContacts(state, action: PayloadAction<SelectedContactsState>) {
            state.selectedContacts = action.payload
        }
    }
})

export const { setSelectedOrderItem, setSelectedContacts } = contactsSelectedSlice.actions

export default contactsSelectedSlice.reducer
