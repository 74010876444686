import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create'
import CloseIcon from '@material-ui/icons/Close'

import { useThunkDispatch } from 'app/store'
import { useStyles } from './ContactDrawer.styles'
import { RootState } from 'app/rootReducer';
import { fetchContacts } from 'slices/contactListSlice';
import Contact from 'models/Contact'
import { setSelectedContacts } from 'slices/selectionsSlice'

interface ContractDrawerProps {
    open: boolean
    onClose: (event: React.KeyboardEvent | React.MouseEvent) => void
    onAddContacts: (contactIds: number[]) => void
}

const ContactDrawer: React.FC<ContractDrawerProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const contacts = useSelector(
        (state: RootState) => state.contacts
    )
    const {selectedContacts} = useSelector(
        (state: RootState) => state.selections
    )

    useEffect(() => {
        dispatch(fetchContacts())
    }, [dispatch])

    const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
        props.onClose(event)
    } 

    const handleContactClick = (contact: Contact) => {
        if (selectedContacts) {
            const index = selectedContacts.indexOf(contact.id)
            if (index >= 0) {
                dispatch(setSelectedContacts([...selectedContacts.slice(0, index), ...selectedContacts.slice(index + 1)]))
            } else {
                dispatch(setSelectedContacts([...selectedContacts, contact.id]))
            }
        } else {
            dispatch(setSelectedContacts([contact.id]))
        }
    }

    const handleEditContact = (event: React.KeyboardEvent | React.MouseEvent, contact: Contact) => {
        event.stopPropagation()
        history.push("/contact", { contact })
    }

    const handleAddSelected = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (selectedContacts) {
            props.onAddContacts(selectedContacts)
        }
        setSelectedContacts(undefined)
        props.onClose(event)
    }
    
    const handleCreateContact = () => {
        history.push("/contact")
    }

    return (
        <Drawer className={classes.drawer} anchor="right" open={props.open} onClose={handleClose}>
            <Box className={classes.content}>
                <Box className={classes.topBar}>
                    <Typography variant="subtitle1">Students</Typography>
                    <IconButton style={{padding: 0}} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <List dense component="div" role="list">
                    {contacts.map(contact => (
                        <ListItem
                            key={contact.id}
                            role="listitem"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleContactClick(contact)}
                            disableGutters
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedContacts?.includes(contact.id) ?? false}
                                    tabIndex={-1}
                                    disableRipple
                                    color='primary'
                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${contact.id}` }}
                                />
                            </ListItemIcon>
                            <ListItemText id={contact.email} primary={`${contact.lastName}, ${contact.firstName}`} />
                            <ListItemIcon>
                                <IconButton aria-label="edit" onClick={(event) => handleEditContact(event, contact)}>
                                    <CreateIcon />
                                </IconButton>
                            </ListItemIcon>
                        </ListItem>
                    ))}
                </List>
                <Box className={classes.buttonBar}>
                    <Button className={classes.leftButton} onClick={handleCreateContact}>Create student</Button>
                    {contacts.length > 0 && (
                        <Button className={classes.rightButton} variant="contained" color="primary" onClick={handleAddSelected}>Add selected</Button>
                    )}
                </Box>
            </Box>
        </Drawer>
    )
}

export default ContactDrawer
