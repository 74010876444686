import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Container } from '@material-ui/core';

import { RootState } from 'app/rootReducer'
import { useThunkDispatch } from 'app/store'
import { fetchCourseEvents } from 'slices/courseEventListSlice'
import FilterView from 'components/filters/FilterView'
import CourseEventListView from 'components/featuresheet/CourseEventListView'
import { CourseEventListParams } from 'api/courseApi'
import Filter from 'models/Filter'
import CourseDetailsView from 'components/featuresheet/CourseDetailsView'
import { fetchCourse } from 'slices/courseSlice'
import Course from 'models/Course'
import CourseEvent from 'models/CourseEvent'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { hasPrice } from 'utils/courseUtils'
import AppHeader from 'components/widgets/AppHeader'
import CourseDescriptionView from 'components/featuresheet/CourseDescriptionView'

interface CourseParams {
    coursePath?: string
    coursePrefix?: string
    courseId?: string
}

interface FeatureSheetPageProps { }

const FeatureSheetPage: React.FC<FeatureSheetPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [loadingMoreEvents, setLoadingMoreEvents] = useState(false)
    const { courseId: courseIdParam } = useParams<CourseParams>()

    const course = useSelector(
        (state: RootState) => state.course
    )
    const courseEventList = useSelector(
        (state: RootState) => state.courseEventList
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const custom = useSelector(
        (state: RootState) => state.content.custom
    )

    const customContent = custom != null ? custom["CoursePage"] : null;

    // console.log("courseIdParam", courseIdParam, "LOCATION.STATE", location.state)

    useEffect(() => {
        const loadAsync = async () => {
            // If location.state is available use that to fetch course events 
            // (the course should already be loaded in the store)
            if (location.state) {
                setLoading(true)
                const [courseId, eventListParams] = location.state
                console.log("Fetching events for course (state)", courseId)
                await dispatch(fetchCourseEvents(courseId, eventListParams))
                setLoading(false)

            // Otherwise use the params from the URL
            } else if (courseIdParam != null) {
                setLoading(true)
                const courseId = Number(courseIdParam)
                console.log("Fetching course", courseId)
                const course = await dispatch(fetchCourse(courseId))
                if (course != null && !course.standAlone) {
                    console.log("Fetching events for course", course)
                    await dispatch(fetchCourseEvents(courseId, {}))
                }
                setLoading(false)
            }
        }

        loadAsync()
    }, [dispatch, location.state, courseIdParam])

    if (course == null) {
        return null
    }

    const handleLoadMoreEvents = async () => {
        if (course != null) {
            const eventListParams = initParams()
            eventListParams.page = courseEventList.page + 1
            console.log("Loading page:", eventListParams.page)

            // Doesn't affect history so we just go ahead and fetch
            setLoadingMoreEvents(true)
            await dispatch(fetchCourseEvents(course.id, eventListParams))
            setLoadingMoreEvents(false)
        }
    }

    const handleTypeSearch = (filter: Filter) => {
        if (course != null) {
            const eventListParams = initParams()
            eventListParams.types = toggleFilter(filter, eventListParams.types)
            history.push(location.pathname, [course.id, eventListParams])
        }
    }

    const handleLocationSearch = (filter: Filter) => {
        if (course != null) {
            const eventListParams = initParams()
            eventListParams.locations = toggleFilter(filter, eventListParams.locations)
            history.push(location.pathname, [course.id, eventListParams])
        }
    }

    const handleStatusSearch = (filter: Filter) => {
        if (course != null) {
            const eventListParams = initParams()
            eventListParams.gtr = !eventListParams.gtr  // toggle
            history.push(location.pathname, [course.id, eventListParams])
        }
    }

    const handleBuyCourse = (course: Course) => {
        if (settings.reseller.contactToEnrollEmail) {
            history.push("/requests/course", { item: { course } })
        } else {
            history.push("/order", { item: { course } })
        }
    }

    const handleEnrollEvent = (event: CourseEvent, waitList?: boolean) => {
        if (settings.reseller.contactToEnrollEmail) {
            history.push("/requests/course", { item: { event } })
        } else {
            history.push("/order", { item: { course, event, waitList } })
        }
    }

    const handleOtherDateRequest = () => {
        history.push("/requests/course", { item: { course } })
    }

    const toggleFilter = (filter: Filter, filterList?: string[]): string[] | undefined => {
        // Logic based on "current" value of filter
        if (filter.selected) {
            if (filterList) {
                filterList = filterList.filter(filterValue => filterValue !== filter.value)
            }
        } else {
            if (filterList) {
                filterList.push(filter.value as string)
            } else {
                filterList = [filter.value as string]
            }
        }

        return filterList
    }

    const initParams = (): CourseEventListParams => {
        return {
            types: courseEventList.filters.types.filter(filter => filter.selected).map(filter => filter.value as string),
            locations: courseEventList.filters.locations.filter(filter => filter.selected).map(filter => filter.value as string),
            gtr: courseEventList.filters.status != null && courseEventList.filters.status.selected
        }
    }

    const renderEventList = () => {
        return (
            <Grid style={{ marginTop: '1rem' }} container spacing={4}>
                <Grid item xs={12} sm={12}>
                    <Grid container justify="center" spacing={10}>
                        {hasPrice(course) && courseEventList.count > 0 &&
                            <Grid item xs={12} sm={3}>
                                <FilterView name="Formats" filters={courseEventList.filters.types} onFilterClick={handleTypeSearch} />
                                <FilterView name="Location" filters={courseEventList.filters.locations} onFilterClick={handleLocationSearch} />
                                {courseEventList.filters.status != null &&
                                    <FilterView name="Status" filters={[courseEventList.filters.status]} onFilterClick={handleStatusSearch} />
                                }

                            </Grid>
                        }
                        {!loading && 
                            <Grid item xs={12} sm={courseEventList.count > 0 ? 9 : 12}>
                            {courseEventList.privateEvents.length > 0 && <CourseEventListView privateEvents={true} events={courseEventList.privateEvents} eventCount={courseEventList.privateEvents.length} contactToEnrollEmail={settings.reseller.contactToEnrollEmail} onEnrollClick={handleEnrollEvent} requestOtherDateEmail={settings.reseller.requestOtherDateEmail} onRequestDateClick={handleOtherDateRequest} />}
                            {hasPrice(course) && <CourseEventListView events={courseEventList.events} eventCount={courseEventList.count} contactToEnrollEmail={settings.reseller.contactToEnrollEmail} onEnrollClick={handleEnrollEvent} requestOtherDateEmail={settings.reseller.requestOtherDateEmail} onRequestDateClick={handleOtherDateRequest}/>}
                            {loadingMoreEvents ? (
                                <LoadingWidget />
                            ) : (
                                courseEventList.events.length < courseEventList.count &&
                                    <Box display="flex" justifyContent="center" style={{ marginTop: 40 }} >
                                        <Button variant="contained" color="primary" onClick={handleLoadMoreEvents}>Load More</Button>
                                    </Box>    
                            )}
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <AppHeader style={(customContent && customContent.backgroundImage)?{backgroundImage:'url('+customContent.backgroundImage+')'}:{}}>
                <CourseDetailsView course={course} contactToEnrollEmail={settings.reseller.contactToEnrollEmail} onBuyCourse={handleBuyCourse} />
            </AppHeader>
            <Box component="main" style={{marginTop: '1rem', marginBottom: '2rem'}}>
                <Container maxWidth="lg">
                    {course.customContent &&
                        <Box style={{ marginTop: '2rem'}} dangerouslySetInnerHTML={{ __html: course.customContent }} />
                    }
                    <CourseDescriptionView course={course} />
                    {loading ? <LoadingWidget /> : !course.standAlone && renderEventList() }
                </Container>
            </Box>
        </>
    )
}

export default FeatureSheetPage
