import Country from 'models/Country'
import CountryState from 'models/CountryState'
import PaymentTypes from 'models/PaymentTypes'
import Settings from 'models/Settings'
import Terms from 'models/Terms'
import RestUtils, { noCamelCaseConfig, excludeCamelCaseConfig } from 'utils/RestUtils'

export interface CountryResponse extends Country { }
export interface CountryStateResponse extends CountryState { }
export interface PaymentTypesResponse extends PaymentTypes {}
export interface TermsResponse extends Terms {}
export interface SettingsResponse extends Settings {}

export async function getCountries(): Promise<CountryResponse> {
    const url = "/lookups/countries"

    const { data } = await RestUtils.get<CountryResponse>(url, null, noCamelCaseConfig)

    return data
}

export async function getStates(country: string): Promise<CountryStateResponse> {
    const url = `/lookups/states/${country}`

    const { data } = await RestUtils.get<CountryStateResponse>(url, null, noCamelCaseConfig)

    return data
}

export async function getPaymentOptions(): Promise<PaymentTypesResponse> {
    const url = "/lookups/payments"

    const { data } = await RestUtils.get<PaymentTypesResponse>(url, null, noCamelCaseConfig)

    return data
}

export async function getTerms(categoryId: number): Promise<TermsResponse> {
    const url = `/lookups/terms/${categoryId}`

    const { data } = await RestUtils.get<TermsResponse>(url, null)

    return data
}

export async function getSettings(): Promise<SettingsResponse> {
    const url = "/lookups/settings"

    const { data } = await RestUtils.get<SettingsResponse>(url, null, excludeCamelCaseConfig(["Cart.AvailableCurrencies"]))
    console.log(data);
    return data
}
