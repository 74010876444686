import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { RootState } from 'app/rootReducer'

// This module updates the notificationContacts values if the contacts change after the containing form has been instantiated (avoid the use of enableReinitialize)
const NotificationContactsInjector: React.FC = () => {
    const { setFieldValue } = useFormikContext()
    const notificationContacts = useSelector(
        (state: RootState) => state.cartSubmit.notificationContacts
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    useEffect(() => {
        if (settings.reseller.enableNotificationContacts && notificationContacts != null) {
            setFieldValue("notificationContacts", notificationContacts)
        }
    }, [setFieldValue, notificationContacts, settings])

    return null
}

export default NotificationContactsInjector
