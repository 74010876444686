export default interface CourseEvent {
    id: number
    name: string
    locationName: string
    address: string
    city: string
    state: string
    country: string
    postalCode: string
    phone: string
    tollFree: string
    language?: string
    format: string
    startDate: string
    endDate: string
    startTime: string
    endTime: string
    timezone: string
    days: number
    status: CourseEventStatus
    gtr: boolean
    type: CourseEventType
    isFull: boolean
    deliveryFormat: DeliveryFormat | ""
}

export enum CourseEventStatus {
    Confirmed = "Confirmed",
    Tentative = "Tentative"
}

export enum CourseEventType {
    Pub = "PUB",
    Ded = "DED"
}

export enum DeliveryFormat {
    iMVP = "Live Virtual",
    MVP = "Remote Classroom"
}
