import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Formik, FormikHelpers, Form, Field } from 'formik'
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core'
import * as yup from 'yup'

import { useStyles } from './ContactForm.styles'
import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { stateFieldForCountry, stateLabelForCountry } from 'utils/addressUtils'
import ContactDetails from './ContactDetails'
import ContactAddress from './ContactAdress'
import { createContact, updateContact } from 'slices/contactListSlice'
import { Addresses } from 'models/Contact'
import { CreateContactRequest, UpdateContactRequest } from 'api/contactsApi'
import FormValues, { FormDetails, ShippingDetails, FormAddress, AddressType } from './ContactFormTypes'
import ErrorMessageWidget from 'components/widgets/ErrorMessageWidget'
import { setSelectedContacts } from 'slices/selectionsSlice'
import { setMember } from 'slices/memberSlice'
import FormStatusLogger from 'components/widgets/form/FormStatusLogger'

interface ContactFormProps { 
    showContact?: boolean
    showBilling?: boolean
    showShipping?: boolean
    title?: string
}

const ContactForm: React.FC<ContactFormProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const location = useLocation()
    const history = useHistory()
    const { showContact, showBilling, showShipping } = props

    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const { selectedContacts } = useSelector(
        (state: RootState) => state.selections
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    let validateContactFields = {
        firstName: yup.string().required("First Name is a required field."),
        lastName: yup.string().required("Last Name is a required field."),
        email: yup.string().required("Email is a required field."),
        company: yup.string().required("Company is a required field."),
        position: (settings.contact.optionalContactPosition ? yup.mixed().notRequired() : yup.string().required("Position is a required field.")),
        phone: (settings.contact.optionalContactPhone ? yup.mixed().notRequired() : yup.string().required("Phone is a required field.")),

        street: yup.string().required("Street is a required field."),
        city: yup.string().required("City is a required field."),
        country: yup.string().required("Country is a required field."),
        zip: yup.string().required("Postal / Zip Code is a required field.")
    }

    let validateBillingFields = {
        firstName: yup.string().required("First Name is a required field."),
        lastName: yup.string().required("Last Name is a required field."),
        email: yup.string().required("Email is a required field."),
        company: yup.string().required("Company is a required field."),
        phone: yup.string().required("Phone is a required field."),

        street: yup.string().required("Street is a required field."),
        city: yup.string().required("City is a required field."),
        country: yup.string().required("Country is a required field."),
        zip: yup.string().required("Postal / Zip Code is a required field.")
    }

    let validateShippingFields = {
        addressType: yup.string().required("Address Type is a required field."),
        firstName: yup.string().required("First Name is a required field."),
        lastName: yup.string().required("Last Name is a required field."),

        street: yup.string().required("Street is a required field."),
        city: yup.string().required("City is a required field."),
        country: yup.string().required("Country is a required field."),
        zip: yup.string().required("Postal / Zip Code is a required field.")
    }

    let validationSchema = yup.lazy((formValues: FormValues) => {
        // console.log("VALIDATE FORMVALUES", formValues)
        return yup.object({
            contact: yup.lazy((values: FormDetails & FormAddress) => {
                // console.log("VALIDATE CONTACT", value)
                if (showContact) {
                    const validateStateField = values.country && values.country !== "other"
                    return yup.object(Object.assign({}, validateContactFields, validateStateField ? { [stateFieldForCountry(values.country)]: yup.string().required(`${stateLabelForCountry(values.country)} is a required field.`) } : {}))
                } else {
                    return yup.mixed().notRequired()
                }
            }),
            billing: yup.lazy((values: ShippingDetails & FormAddress) => {
                // console.log("VALIDATE BILLING", value)
                if (showBilling) {
                    const validateStateField = values.country && values.country !== "other"
                    return yup.object(Object.assign({}, validateBillingFields, validateStateField ? { [stateFieldForCountry(values.country)]: yup.string().required(`${stateLabelForCountry(values.country)} is a required field.`) } : {}))
                } else {
                    return yup.mixed().notRequired()
                }
            }),
            shipping: yup.lazy((values: ShippingDetails & FormAddress & AddressType) => {
                // console.log("VALIDATE SHIPPING", value)
                const validateStateField = values.country && values.country !== "other"
                const validateCompany = values.addressType && values.addressType === "business"
                if (values && !formValues.sameAsContactAddress) {
                    return yup.object(Object.assign({}, validateShippingFields, 
                        validateStateField ? { [stateFieldForCountry(values.country)]: yup.string().required(`${stateLabelForCountry(values.country)} is a required field.`) } : {},
                        validateCompany ? { company: yup.string().required("Company is a required field.") } : {}
                    ))
                } else {
                    return yup.mixed().notRequired()
                }
            }),
            sameAsContactAddress: yup.mixed().notRequired()
        })
    })

    let initialValues: FormValues = {
        contact: {
            firstName: "",
            lastName: "",
            email: "",
            company: "",
            position: "",
            phone: "",
            street: "",
            city: "",
            state: "",
            province: "",
            other: "",
            country: "",
            zip: ""
        },
        billing: {
            firstName: "",
            lastName: "",
            email: "",
            company: "",
            phone: "",
            street: "",
            city: "",
            state: "",
            province: "",
            other: "",
            country: "",
            zip: ""
        },
        shipping: {
            firstName: "",
            lastName: "",
            email: "",
            company: "",
            phone: "",
            street: "",
            city: "",
            state: "",
            province: "",
            other: "",
            country: "",
            zip: "",
            addressType: ""
        },
        sameAsContactAddress: true
    }

    const contact = location.state?.contact ? location.state.contact : showBilling && member?.contact ? member.contact : null
    const isExistingContact = location.state?.contact || (showBilling && member?.contact.id)
    const onlyShipping = showShipping && !showContact && !showBilling

    if (contact) {
        const contactFields = {
            firstName: contact.firstName ?? "",
            lastName: contact.lastName ?? "",
            email: contact.email ?? "",
            company: contact.company ?? "",
            position: contact.position ?? "",
            phone: contact.phone ?? ""
        }
        initialValues.contact = Object.assign(initialValues.contact, contact.addresses.contact, contactFields)
        initialValues.billing = Object.assign(initialValues.billing, contact.addresses.billing)
        initialValues.sameAsContactAddress = onlyShipping ? false : true

        if (!onlyShipping && contact.addresses.shipping) { // onlyShipping true DO NOT inintialize shipping info - for privacy reasons
            initialValues.shipping = Object.assign(initialValues.shipping, contact.addresses.shipping, { addressType: contact.addresses.shipping.company ? "business" : "home" })
            initialValues.sameAsContactAddress = false
        }
        console.log("LOCATION STATE", location.state)
        console.log("INITIAL VALUES", initialValues)
    }

    const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        setSubmitting(true)

        const haveBillingAddress = values.billing ? Object.keys(values.billing).some(key => values.billing && values.billing[key as keyof (ShippingDetails & FormAddress)]) : false
        const addresses: Addresses = {
            contact: values.contact,
            billing: haveBillingAddress ? values.billing : undefined,
            shipping: values.sameAsContactAddress ? undefined : values.shipping
        }
        
        if (isExistingContact) {
            if (values.contact) {
                const contact: UpdateContactRequest = {
                    id: location.state?.contact.id ?? member?.contact.id,
                    email: values.contact.email,
                    firstName: values.contact.firstName,
                    lastName: values.contact.lastName,
                    company: values.contact.company,
                    position: values.contact.position ?? "", 
                    phone: values.contact.phone ?? "",
                    isMe: location.state?.contact.isMe ?? member?.contact.isMe,
                    addresses: addresses,
                    shippingSameAsContact: values.sameAsContactAddress
                }

                console.log("Updating student:", contact)
                const result = await dispatch(updateContact(contact))

                if (result) {
                    if (showBilling) {
                        // Need to refetch member
                        dispatch(setMember(null))
                    } else {
                        history.goBack()
                    }
                }
            }
        } else {
            if (values.contact) {
                const contact: CreateContactRequest = {
                    email: values.contact.email,
                    firstName: values.contact.firstName,
                    lastName: values.contact.lastName,
                    company: values.contact.company,
                    position: values.contact.position ?? "",
                    phone: values.contact.phone ?? "",
                    isMe: false,
                    addresses: addresses,
                    shippingSameAsContact: values.sameAsContactAddress
                }

                console.log("Adding student:", contact)
                const newContact = await dispatch(createContact(contact))

                if (newContact) {
                    if (selectedContacts) {
                        dispatch(setSelectedContacts([...selectedContacts, newContact.id]))
                    } else {
                        dispatch(setSelectedContacts([newContact.id]))
                    }

                    if (showBilling) {
                        // Need to refetch member
                        dispatch(setMember(null))
                    } else {
                        history.goBack()
                    }
                }
            }
        }
    }

    const handleCancelButton = () => {
        if (!showBilling) {
            history.goBack()
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    isSubmitting,
                    errors
                }) => {
                    return (
                        <React.Fragment>
                            <Form>
                                {showContact &&
                                    <React.Fragment>
                                        <ContactDetails
                                            name="Student Details"
                                            prefix="contact"
                                            showPosition={true}
                                            showPhone={true}
                                            showEmail={true}
                                            isMe={location.state?.contact?.isMe ?? false}
                                        />
                                        <ContactAddress
                                            prefix="contact"
                                            country={values.contact?.country}
                                            stateProvince={values.contact?.province ?? values.contact?.state ?? values.contact?.other}
                                            isMe={!!location.state?.contact?.isMe}
                                        />
                                    </React.Fragment>
                                }
                                {showBilling &&
                                    <React.Fragment>
                                        <ContactDetails
                                            name="Billing Details"
                                            prefix="billing"
                                            showPosition={false}
                                            showPhone={true}
                                            showEmail={true}
                                            isMe={false}
                                        />
                                        <ContactAddress
                                            prefix="billing"
                                            country={values.billing?.country}
                                            stateProvince={values.billing?.province ?? values.billing?.state ?? values.billing?.other}
                                            isMe={location.state?.contact?.isMe ?? member?.contact.isMe}
                                        />
                                    </React.Fragment>
                                }
                                {showShipping && (onlyShipping || settings.contact.shippingAddressEnabled) &&
                                    <React.Fragment>
                                        {!values.sameAsContactAddress &&
                                            <React.Fragment>
                                                <ContactDetails
                                                    name="Shipping Student Details"
                                                    prefix="shipping"
                                                    addressType={values.shipping?.addressType ?? ""}
                                                    showPosition={false}
                                                    showPhone={false}
                                                    showEmail={false}
                                                    isMe={false}
                                                />
                                                <ContactAddress
                                                    prefix="shipping"
                                                    country={values.shipping?.country}
                                                    stateProvince={values.shipping?.province ?? values.shipping?.state ?? values.shipping?.other}
                                                    isMe={!!location.state?.contact?.isMe}
                                                />
                                            </React.Fragment>
                                        }
                                        {!onlyShipping &&
                                            <FormControlLabel className={classes.sameAs}
                                                control={
                                                    <Field
                                                        name="sameAsContactAddress"
                                                        type="checkbox"
                                                        checked={values.sameAsContactAddress}
                                                        as={Checkbox}
                                                    />
                                                }
                                                label="Shipping same as student?"
                                            />
                                        }
                                    </React.Fragment>
                                }
                                <Box className={classes.buttonBar}>
                                    <Button type="reset" variant="text" onClick={handleCancelButton}>Cancel</Button>
                                    &nbsp;
                                <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">{isExistingContact ? "Update Student" : "Create Student"}</Button>
                                </Box>
                            </Form>
                            <FormStatusLogger values={values} errors={errors} />
                        </React.Fragment>
                    )
                }}
            </Formik>
            <ErrorMessageWidget />
        </React.Fragment>
    )
}

ContactForm.defaultProps = {
    showContact: false,
    showBilling: false,
    showShipping: false
}

export default ContactForm
