import RestUtils from 'utils/RestUtils'
import Bundle from 'models/Bundle'
import BundleEventList from 'models/BundleEvenList'
import BundleSummary from 'models/BundleSummary'

export interface BundleResponse extends Bundle { }
export interface BundleEventListResponse extends BundleEventList { }
export interface BundleSummaryResponse extends BundleSummary { }

export interface BundleEventListParams {
    step?: number
    page?: number
    gtr?: boolean
    types?: string[]
    locations?: string[]
}

export interface BundleOrderParams {
    bundleID?: number
    eventID?: number
    courseID?: number
    step?: number
}

export async function getBundles(courseId?: string): Promise<BundleResponse[]> {
    const endPoint = "training/bundles"

    const { data } = await RestUtils.get<BundleResponse[]>(endPoint, { courseID: courseId})
    return data
}

export async function getBundleEventList(bundleId: number, params: BundleEventListParams): Promise<BundleEventListResponse> {
    const endPoint = `/training/bundles/${bundleId}`

    const { data } = await RestUtils.get<BundleEventListResponse>(endPoint, {...params})
    return data
}

export async function postBundle(params: BundleOrderParams): Promise<BundleEventListResponse> {
    const url = "/orders/bundle"

    const { data } = await RestUtils.post<BundleEventListResponse>(url, params)
    return data
}

export async function getBundleSummary(bundleId: number): Promise<BundleSummaryResponse> {
    const endPoint = `/orders/bundle/${bundleId}`

    const { data } = await RestUtils.get<BundleSummaryResponse>(endPoint)
    return data
}
