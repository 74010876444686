import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Container, Grid, Typography } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { fetchTrainingResources } from 'slices/trainingResourcesSlice'
import TrainingResourcesCard from 'components/trainingresources/TrainingResourceCard'
import SearchBox from 'components/widgets/SearchBox'
import TrainingResourceFilters from 'components/trainingresources/TrainingResourceFilters'
import { ResourceType, Resource } from 'models/TrainingResource'

interface TrainingResourcesParams {
    values: string
}
interface TrainingResourcesPageProps { }

const TrainingResourcesPage: React.FC<TrainingResourcesPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const params = useParams<TrainingResourcesParams>()
    const [loading, setLoading] = useState(false)
    const [typeValue, setTypeValue] = useState<string | undefined>(undefined)
    const [brandValue, setBrandValue] = useState<string | undefined>(undefined)
    const [searchTerms, setSearchTerms] = useState<string | undefined>(undefined)
    const trainingResources = useSelector(
        (state: RootState) => state.trainingResources
    )

    // console.log("PARAMS", params)

    useEffect(() => {
        const loadAsync = async () => {
            setLoading(true)
            await dispatch(fetchTrainingResources())
            setLoading(false)
        }
        loadAsync()
    }, [dispatch])

    useEffect(() => {
        setTypeValue(undefined)
        setBrandValue(undefined)
        if (params.values) { 
            params.values.split('/').forEach(param => {
                if (trainingResources.typeFilters[param]) {
                    console.log("Type Param", param)
                    setTypeValue(param)
                }

                if (trainingResources.brandFilters[param]) {
                    console.log("Brand Param", param)
                    setBrandValue(param)
                }
            })
        }
    }, [trainingResources, params])

    const handleResourceClick = (url: string) => {
        window.location.href = url
    }

    const handleSearch = (searchTerms: string) => {
        setSearchTerms(searchTerms.length > 0 ? searchTerms : undefined)
    }

    const handleTypeSelect = (value: string) => {
        let url = "/it-training-resources"

        if (value) {
            url += `/${value}`
        }

        if (brandValue) {
            url += `/${brandValue}`
        }
        history.push(url)
    }

    const handleBrandSelect = (value: string) => {
        let url = "/it-training-resources"

        if (typeValue) {
            url += `/${typeValue}`
        }

        if (value) {
            url += `/${value}`
        }

        history.push(url)
    }

    const showType = (resourceType: ResourceType) => {
        if (searchTerms) {
            const c1 = !typeValue && !brandValue
            const c2 = !typeValue && resourceType.resources.filter(resource => showBrand(resource) && searchResource(resource)).length > 0
            const c3 = typeValue && resourceType.resources.filter(resource => resource.typeTags.includes(typeValue) && showBrand(resource) && searchResource(resource)).length > 0 
            const c4 = resourceType.resources.filter(resource => searchResource(resource)).length > 0

            // console.log("ST", c1, c2, c3, c4)
            return (c1 || c2 || c3) && c4
        } else {
            const c1 = !typeValue && !brandValue
            const c2 = !typeValue && resourceType.resources.filter(resource => showBrand(resource)).length > 0
            const c3 = typeValue && resourceType.resources.filter(resource => resource.typeTags.includes(typeValue) && showBrand(resource)).length > 0 

            return c1 || c2 || c3
        }
    }

    const showBrand = (resource: Resource) => {
        return !brandValue || resource.brandTags.includes(brandValue)
    }

    const searchResource = (resource: Resource) => {
        if (searchTerms) {
            const re = new RegExp(searchTerms, "i")

            if (resource.title.match(re)) {
                return true
            }

            if (resource.description.match(re)) {
                return true
            }
        }

        return false
    }

    const showTrainingResource = (resource: Resource) => {
        if (searchTerms) {
            const c1 = !typeValue && !brandValue
            const c2 = typeValue && resource.typeTags.includes(typeValue)
            const c3 = brandValue && resource.brandTags.includes(brandValue)
            const c4 = searchResource(resource) 

            if (typeValue) {
                return (c2 || c3) && c4
            }

            if (brandValue) {
                return c3 && c4
            }
            return c1 && c4
        } else {
            const c1 = !typeValue || resource.typeTags.includes(typeValue)
            const c2 = !brandValue || resource.brandTags.includes(brandValue)

            return c1 && c2
        }
    }

    // console.log("TB", typeValue, brandValue)
    console.log("Search Terms", searchTerms)

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                <Typography style={{ textAlign: 'center' }} color="primary" variant="h3">IT Training and Development Resources</Typography>
                <Typography>
                    ExitCertified is an industry leader in IT training; our free training and development resources enable you and your team to get the vital skills you need to advance to the next level in your chosen field. We’ve been training IT professionals like you, since 2001 and today, we deliver over 9,500 authorized training courses for more than 25 brands. Our most popular free IT training resources include articles, webinars, free courses, whitepapers, case studies, videos, learning paths, skills assessments, and datasheets – to name a few. We cover a wide range of topics including AWS, VMware, IBM, Nutanix, Docker, ForgeRock, Google Cloud, Microsoft, SAP, Veeam, Kubernetes, Databricks, and more. Keep your skills up to date and get the training you need by selecting a helpful resource below. 
                </Typography>
                {loading ? (
                    <LoadingWidget />
                ) : (
                    <React.Fragment>
                        <Box style={{marginTop: '2rem'}} display="flex">
                            <SearchBox placeholder="Search Training Resources" minChars={0} style={{ marginTop: 0, marginBottom: 0 }} onSearch={handleSearch} />
                            <TrainingResourceFilters 
                                typeFilters={trainingResources.typeFilters}
                                brandFilters={trainingResources.brandFilters}
                                typeValue={typeValue}
                                brandValue={brandValue}
                                onTypeSelect={handleTypeSelect}
                                onBrandSelect={handleBrandSelect}
                            />
                        </Box>
                        {trainingResources.types.map(resourceType =>
                            (showType(resourceType)) && (
                                <React.Fragment key={resourceType.name} >
                                    <Typography style={{ marginTop: '2rem', marginBottom: '1rem' }} variant="h4" color="primary">{resourceType.name}</Typography>
                                    <Grid container spacing={4}>
                                        {resourceType.resources.map(resource =>
                                            (showTrainingResource(resource)) && (
                                                <Grid item key={resource.title} xs={12} sm={4}>
                                                    <TrainingResourcesCard resource={resource} onClick={() => handleResourceClick(resource.url)} />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </React.Fragment>
                            )
                        )}
                    </React.Fragment>
                )}
            </Container>
        </Box>
    )
}

export default TrainingResourcesPage
