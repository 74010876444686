import React from 'react'
import { Box, Container } from '@material-ui/core'
import CourseRequestForm from 'components/requests/CourseRequestForm'

interface CourseRequestProps { }

const CourseRequestPage: React.FC<CourseRequestProps> = (props) => {
    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                <CourseRequestForm />
            </Container>
        </Box>
    )
}

export default CourseRequestPage
