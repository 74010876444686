import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Card, CardContent, Divider, Grid, IconButton, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import StudentListView from 'components/cart/StudentListView'
import ContentDialog from 'components/widgets/ContentDialog'
import Contact from 'models/Contact'
import OrderItem from 'models/OrderItem'
import { fetchTerms } from 'slices/lookupsSlice'
import { formatCurrency } from 'utils/currency'
import PriceRenderer from 'components/widgets/PriceRenderer'

interface CartBundleCardProps {
    item: OrderItem
    onEnrollStudents: (event: React.KeyboardEvent | React.MouseEvent) => void
    onRemoveStudent: (item: OrderItem, contactId: number) => void
    onEditStudent: (item: OrderItem, contact: Contact) => void
    onUpdateStudentExtraInfo: (item: OrderItem, contactId: number, extraInfo: any) => void
    onRemoveItem: (item: OrderItem) => void
}

const CartBundleCard: React.FC<CartBundleCardProps> = (props) => {
    const { bundle, students, price } = props.item
    const dispatch = useThunkDispatch()

    const handleEnrollStudents = (event: React.KeyboardEvent | React.MouseEvent) => {
        props.onEnrollStudents(event)
    }

    const handleRemoveStudent = (item: OrderItem, contactId: number) => {
        props.onRemoveStudent(item, contactId)
    }

    const handleEditStudent = (item: OrderItem, contact: Contact) => {
        props.onEditStudent(item, contact)
    }

    const handleUpdateStudentExtraInfo = (item: OrderItem, contactId: number, extraInfo: any) => {
        props.onUpdateStudentExtraInfo(item, contactId, extraInfo)
    }

    const handleRemoveItem = (item: OrderItem) => {
        props.onRemoveItem(item)
    }

    const terms = useSelector(
        (state: RootState) => state.lookups.terms
    )

    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const [termsOpen, setTermsOpen] = useState(false)
    const handleTermsClick = (categoryId: number) => {
        dispatch(fetchTerms(categoryId))
        setTermsOpen(true)
    }

    const handleTermsDialogClicked = () => {
        setTermsOpen(false)
    }

    const renderDialog = () => {
        return (<ContentDialog open={termsOpen} title="" message={terms.content} onButtonClicked={handleTermsDialogClicked} />)
    }

    if (!bundle) {
        return null
    }

    return (
        <Card style={{ marginTop: 30 }}>
            <CardContent style={{ position: "relative" }}>
                <Typography style={{ textTransform: 'uppercase' }} variant="h6">{bundle.name}</Typography>
                <Typography style={{marginTop: 30, marginBottom: 20, textTransform: 'capitalize'}} variant="body1" color='primary'>Bundle Items</Typography>

                <ul style={{ listStyleType: 'square', paddingLeft: 24 }}>
                    {bundle.courses.map(course =>
                        <li key={course.id}><Typography>{course.name}</Typography></li>
                    )}

                    {bundle.events.map(event =>
                        <li key={event.id}><Typography>{event.name}</Typography></li>
                    )}
                </ul>


                <Grid container style={{ marginTop: 30 }} alignContent="flex-start">
                    {!settings.reseller.hideAllPricing &&
                        <React.Fragment>
                            <Grid item sm={2}>
                                <Typography style={{fontWeight: 'bold'}}>MSRP:</Typography>
                                { bundle.currency === 'CR' &&
                                    <Typography style={{ fontWeight: 'bold' }}>Discount Price:</Typography>
                                }
                            </Grid>
                            <Grid item sm={4}>
                                <PriceRenderer isFree={false} price={price} per="/ student" />
                                { bundle.currency === 'CR' &&
                                    <Typography>{formatCurrency(bundle.total, bundle.currency)} {price.currencyName} / student</Typography>
                                }
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item sm={6}>
                        <Box display="flex" flexDirection="row" alignItems="baseline" padding={0}>
                            <Typography>By completing this order you are agreeing to our</Typography>
                            <Button style={{paddingTop: 0}} color='primary' onClick={() => handleTermsClick(bundle.id)}>Terms & Conditions</Button>
                            {renderDialog()}
                        </Box>
                    </Grid>
                </Grid>

                {students.length > 0 ? (
                    <React.Fragment>
                        <span style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant='h6'>Enrolled Students</Typography>
                            <Button variant="contained" color='primary' onClick={handleEnrollStudents}>Add Students</Button>
                        </span>
                        <Divider style={{ marginTop: 15 }} />
                        <StudentListView
                            item={props.item}
                            onEditStudent={handleEditStudent}
                            onRemoveStudent={handleRemoveStudent}
                            onUpdateStudentExtraInfo={handleUpdateStudentExtraInfo}
                        />
                    </React.Fragment>
                ) : (
                        <Box style={{ marginTop: 30, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <Button variant="contained" color='primary' onClick={handleEnrollStudents}>Enroll Students</Button>
                        </Box>
                    )}
                <Box style={{ position: "absolute", right: 16, top: 16 }}>
                    <IconButton aria-label="remove" onClick={() => handleRemoveItem(props.item)}>
                        <ClearIcon />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    )
}

export default CartBundleCard
