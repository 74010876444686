import React from 'react'
import { Button, Grid, MenuItem, Typography } from '@material-ui/core'
import { isValid } from 'date-fns'

import { useStyles } from './SFReportFilters.styles'
import { SFReportFilter } from 'models/SFReportData'
import { FilterValues, FilterValueType, FilterDateFormat } from 'api/reportsApi'
import ECTextField from 'components/widgets/ECTextField'
import ECSelect from 'components/widgets/ECSelect'
import ECDatePicker from 'components/widgets/ECDatePicker'

interface SFReportFiltersProps { 
    filters: SFReportFilter[]
    filterValues: FilterValues
    onUpdateFilters: (filters: FilterValues) => void
    onFilterReport: () => void
}

const SFReportFilters: React.FC<SFReportFiltersProps> = (props) => {
    const classes = useStyles()
    const { filters, filterValues } = props

    if (filters.length === 0) {
        return null
    }

    const updateValue = (filterId: number, value: FilterValueType) => {
        const newValues = {...filterValues}
        if (value) {
            newValues[filterId] = value
        } else {
            delete newValues[filterId]
        }
        props.onUpdateFilters(newValues)
    }
    
    const handleTextChange = (filterId: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateValue(filterId, event.target.value)
    }

    const handleDateChange = (filterId: number, date: Date | null) => {
        if (date == null || isValid(date)) {
            updateValue(filterId, date)
        }
    }

    const handleSelectChange = (filterId: number, event: React.ChangeEvent<{name?: string | undefined, value: unknown}>) => {
        updateValue(filterId, event.target.value as FilterValueType)
    }

    const handleClearFilters = () => {
        props.onUpdateFilters({})
        props.onFilterReport()
    }

    const handleUpdateButton = () => {
        props.onFilterReport()
    }

    const renderText = (filter: SFReportFilter) => {
        return (
            <ECTextField
                key={filter.name}
                // placeholder={filter.name}
                label={filter.name}
                name={filter.name}
                value={filterValues[filter.id] || ''}
                fullWidth={true}
                onChange={e => handleTextChange(filter.id, e)}
            // className={classes.text}
            />
        )
    }

    const renderDropDown = (filter: SFReportFilter) => {
        return (
            <ECSelect
                id={filter.name}
                key={filter.name}
                name={filter.name}
                value={filterValues[filter.id] || ''}
                fullWidth={true}
                onChange={e => handleSelectChange(filter.id, e)}
            >
                <MenuItem key={0} value="">
                    <Typography color="textSecondary">-- No Selection --</Typography>
                </MenuItem>
                {filter.values?.map(item =>
                    <MenuItem key={item.label} value={item.label}>{item.value}</MenuItem>
                )}
            </ECSelect>
        )
    }

    const renderDatePicker = (filter: SFReportFilter) => {
        return (
            <ECDatePicker
                format={FilterDateFormat}
                fullWidth={true}
                margin="normal"
                label={filter.name}
                value={filterValues[filter.id] || null}
                onChange={date => handleDateChange(filter.id, date)}
                className={classes.datePicker}
            />
        )
    }

    const renderFilter = (filter: SFReportFilter) => {
        switch (filter.type) {
            case "TextField": {
                return renderText(filter)
            }
            case "DropDown": {
                return renderDropDown(filter)
            }
            case "Date": {
                return renderDatePicker(filter)
            }
        }
    }

    return (
        <Grid container spacing={2}  alignItems="center">
            <Grid item xs={12} sm={10} className={classes.verticalSeparator}>
                <Grid container spacing={2} alignItems="baseline">
                    {filters.map(filter =>
                        <Grid item key={filter.name} xs={12} sm={6} md={3} xl={2} >
                            {renderFilter(filter)}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Button variant="contained" color="primary" fullWidth={true} onClick={handleUpdateButton}>Update</Button>
                <Button style={{ marginTop: '1rem' }} variant="outlined" color="primary" fullWidth={true} onClick={handleClearFilters}>Clear</Button>
            </Grid>
        </Grid>
    )
}

export default SFReportFilters
