import React from "react"
import { TreeView, TreeItem } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DocumentIcon from '@material-ui/icons/Description'

import ReportDocument from "models/ReportDocument"

interface DocumentListViewProps {
    documents: ReportDocument[]
    onDocumentClick: (documentId: string) => void
 }

const DocumentListView: React.FC<DocumentListViewProps> = (props) => {
    const { documents, onDocumentClick } = props

    const handleDocumentClick = (documentId: string) => {
        onDocumentClick(documentId)
    }

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
        >
            {documents.map((document, index) => 
                <TreeItem style={{marginLeft: 6}} key={index} nodeId={document.name} label={document.name} icon={<DocumentIcon color="primary"/>} onClick={e => handleDocumentClick(document.id)}/>
            )}
        </TreeView>
    )
}

export default DocumentListView
