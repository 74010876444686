import React from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form, FormikHelpers } from 'formik'
import { Box, Typography } from '@material-ui/core'
import * as yup from 'yup'

import { useStyles } from './CreditCardPaymentForm.styles'
import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import AutoSubmitForm from 'components/widgets/form/AutoSubmitForm'
import FormStatusLogger from 'components/widgets/form/FormStatusLogger'
import BillingAddressForm, { BillingValues } from './BillingAddressForm'
import { CreditCardBilling } from 'models/Billing'
import Address from 'models/Address'
import { stateFieldForCountry, stateLabelForCountry } from 'utils/addressUtils'
import YourContactInfoForm, { YourContactInfoValues } from './YourContactInfoForm'
import NotificationContact from 'models/NotificationContact'
import NotificationContactsInjector from 'components/members/NotificationContactsInjector'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { savePayment } from 'slices/paymentDataSlice'
import config from 'config/Config'
import { setError } from 'slices/errorSlice'

interface FormValues {
    me: YourContactInfoValues
    billing: BillingValues
    notificationContacts: NotificationContact[] | null
    saveBillingAddress: true
}

interface CreditCardPaymentFormProps {
}

const CreditCardPaymentForm: React.FC<CreditCardPaymentFormProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const stripe = useStripe()
    const elements = useElements()

    const member = useSelector(
        (state: RootState) => state.member.member
    )  
    const notificationContacts = useSelector(
        (state: RootState) => state.cartSubmit.notificationContacts
    )
    const contacts = useSelector(
        (state: RootState) => state.contacts
    ) 

    let validationSchema = yup.object({
        billing: yup.lazy((values: BillingValues) => {
            const validateStateField = values.country && values.country !== "other"

            return yup.object(Object.assign({}, {
                firstName: yup.string().required("First Name is a required field."),
                lastName: yup.string().required("Last Name is a required field."),
                company: yup.string().required("Company is a required field."),
                email: yup.string().required("Email is a required field."),
                phone: yup.string().required("Phone is a required field."),

                street: yup.string().required("Street is a required field."),
                city: yup.string().required("City is a required field."),
                country: yup.string().required("Country is a required field."),
                zip: yup.string().required("Postal / Zip Code is a required field.")
            }, validateStateField ? { [stateFieldForCountry(values.country)]: yup.string().required(`${stateLabelForCountry(values.country)} is a required field.`) } : {}))
        })
    })

    let billingContact: Address = {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",

        street: "",
        city: "",
        state: "",
        province: "",
        other: "",
        country: "",
        zip: ""
    }

    let me = {
        firstName: "",
        lastName: "",
        email: ""
    }

    if (member != null) {      
        if (member.contact.addresses.billing != null) {
            const contact = member.contact.addresses.billing
            billingContact = {
                firstName: contact.firstName,
                lastName: contact.lastName,
                company: contact.company,
                email: contact.email,
                phone: contact.phone,

                street: contact.street,
                city: contact.company,
                state: contact.state,
                province: contact.province,
                other: contact.other,
                country: contact.country,
                zip: contact.zip
            }            
        }

    } else if (contacts != null) {
        const filteredContacts = contacts.filter(contact => contact.isMe)

        if (filteredContacts.length > 0) {
            me = {
                firstName: filteredContacts[0].firstName,
                lastName: filteredContacts[0].lastName,
                email: filteredContacts[0].email
            }
        }

        const validateMe = yup.object({
            me: yup.object({
                firstName: yup.string().required("First Name is a required field."),
                lastName: yup.string().required("Last Name is a required field."),
                email: yup.string().required("Email is a required field.")
            })
        })

        validationSchema = validationSchema.concat(validateMe)
    }

    const initialValues: FormValues = {
        me: me,
        billing: billingContact,
        notificationContacts: notificationContacts,
        saveBillingAddress: true
    }

    const handleCartSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        if (stripe && elements) {
            setSubmitting(true)

            console.log("Saving cart data")
            const billing: CreditCardBilling = member == null ? 
                Object.assign({}, values.billing, { me: values.me, notificationContacts: values.notificationContacts, paymentType: "CREDITCARD", saveBillingAddress: values.saveBillingAddress }) :
                Object.assign({}, values.billing, { notificationContacts: values.notificationContacts, paymentType: "CREDITCARD", saveBillingAddress: values.saveBillingAddress })

            const res = await dispatch(savePayment(billing))

            if (res) {
                console.log("confirming payment")

                const returnUrl = config.Env === 'dev' ? `${config.API_BASE}` : `${window.location.origin}${config.API_BASE}`
                
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: returnUrl + "/payments/fulfill"
                    }
                });

                if (error.type === "card_error" || error.type === "validation_error") {
                    dispatch(setError(error.message ?? ''))
                } else {
                    dispatch(setError("An unexpected error occurred."))
                }       
            }
        }
    }

    const handleUseMyContactInfoButton = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        if (member != null && member.contact.addresses.contact != null) {
            const contact = member.contact.addresses.contact
            setFieldValue("billing.firstName", contact.firstName)
            setFieldValue("billing.lastName", contact.lastName)
            setFieldValue("billing.company", contact.company)
            setFieldValue("billing.email", contact.email)
            setFieldValue("billing.phone", contact.phone)

            setFieldValue("billing.street", contact.street)
            setFieldValue("billing.city", contact.city)
            setFieldValue("billing.state", contact.state)
            setFieldValue("billing.province", contact.province)
            setFieldValue("billing.country", contact.country)
            setFieldValue("billing.zip", contact.zip)
        }
    }

    // console.log("STRIPE", stripe, elements)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCartSubmit}
        >
            {({
                values,
                errors,
                setFieldValue
            }) => {
                return (
                    <React.Fragment>
                        <Form>
                            <YourContactInfoForm />
                            <Box className={classes.section}>
                                <Typography variant="h6" style={{marginBottom:'20px'}}>Card Details</Typography>
                                <PaymentElement id="payment-element"/>
                            </Box>

                            <BillingAddressForm
                                saveBillingAddress={values.saveBillingAddress}
                                country={values.billing.country}
                                stateProvince={values.billing.province ?? values.billing.state ?? values.billing.other}
                                onUseMyContactInfoButton={member == null ? undefined : () => handleUseMyContactInfoButton(setFieldValue)}
                            />
                            <NotificationContactsInjector />
                        </Form>
                        <AutoSubmitForm />
                        <FormStatusLogger values={values} errors={errors} />
                    </React.Fragment>
                )
            }}
        </Formik>
    )
}

export default CreditCardPaymentForm
