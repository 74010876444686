export const stateFieldForCountry = (country?: string): string => {
    let stateField = "other"
    if (country) {
        if (["US", "AU", "BR", "CN", "IE", "IN", "IT", "MX"].includes(country)) {
            stateField = "state"
        } else if (["CA"].includes(country)) {
            stateField = "province"
        }
    }
    return stateField
}

export const stateLabelForCountry = (country?: string): string => {
    let stateLabel = "State"
    if (country) {
        if (["US", "AU", "BR", "CN", "IE", "IN", "IT", "MX"].includes(country)) {
            stateLabel = "State"
        } else if (["CA"].includes(country)) {
            stateLabel = "Province"
        }
    }
    return stateLabel
}