import RestUtils, { excludeCamelCaseConfig } from 'utils/RestUtils'
import Transaction from 'models/Transaction'
import Team from 'models/Team'
import TransactionDetail from 'models/TransactionDetail'
import SFReport from 'models/SFReport'
import SFReportData from 'models/SFReportData'
import {format} from 'date-fns'
import ReportFolder from 'models/ReportFolder'

export interface ReportIndexResponse {
    name: string
    endPoint: string
}

export  interface TransactionReportResponse { 
    total: number
    transactions: Transaction[]
    teams: Team[]
}

export interface TransactionDetailResponse extends TransactionDetail { }

export async function getReportIndex(): Promise<ReportIndexResponse[]> {
    const url = "/reports"

    const { data } = await RestUtils.get<ReportIndexResponse[]>(url)

    return data
}

export async function getTransactionReport(page: number, team: number): Promise<TransactionReportResponse> {
    const url = `/reports/transactions?page=${page}&teamId=${team}`

    const { data } = await RestUtils.get<TransactionReportResponse>(url)

    return data
}

export async function downloadTransactionReportData(team: number) {
    const url = `/reports/transactions?teamId=${team}&download=1`

    return await RestUtils.get<Blob>(url, null, { responseType: 'blob', transformResponse: undefined })
}

export async function getTransactionDetailReport(transactionId: number): Promise<TransactionDetailResponse[]> {
    const url = `/reports/transactions/${transactionId}`

    const { data } = await RestUtils.get<TransactionDetailResponse[]>(url)
    
    return data
}

export interface SFReportResponse extends SFReport {}
export interface SFReportDataResponse extends SFReportData {}

export async function getSFReportList(): Promise<SFReportResponse[]> {
    const url = `/reports/list`

    const { data } = await RestUtils.get<SFReportResponse[]>(url)

    return data
}

export interface DocumentListResponse {
    folders: ReportFolder[]
}

export async function getDocuments() {
    const url = "reports/documents"

    const { data } = await RestUtils.get<DocumentListResponse>(url)

    return data
}

export async function downloadDocument(documentId: string) {
    const url = `reports/documents/${documentId}`

    return await RestUtils.get<Blob>(encodeURI(url), null, { responseType: 'blob', transformResponse: undefined })
}

export const FilterDateFormat = "yyyy-MM-dd"
export type FilterValueType = string | Date | null
export interface FilterValues {
    [key: number]: FilterValueType
}

export async function getSFReportData(reportId: number, page: number, limit: number, filterValues: FilterValues, orderBy?: string, order?: string, first?: boolean): Promise<SFReportDataResponse> {
    const url =  getReportUrl(reportId, page, limit, filterValues, orderBy, order, first)
    const { data } = await RestUtils.get<SFReportDataResponse>(encodeURI(url), null, excludeCamelCaseConfig([ "Headers.LookupData" ]))

    return data
}

export async function downloadSFReportData(reportId: number, page: number, limit: number, filterValues: FilterValues, orderBy?: string, order?: string, first?: boolean, dataOption?: string) {
    const url = getReportUrl(reportId, page, limit, filterValues, orderBy, order, first) + "&download=1" + (dataOption ? "&dataoption=" + dataOption : "")

    return await RestUtils.get<Blob>(encodeURI(url), null, { responseType: 'blob', transformResponse: undefined })
}

function getReportUrl(reportId: number, page: number, limit: number, filterValues: FilterValues, orderBy?: string, order?: string, first?: boolean) {
    let url = `/reports/fetch/${reportId}?page=${page}&limit=${limit}`

    if (Object.keys(filterValues).length > 0) {
        url += "&" + Object.keys(filterValues).map(filterId => {
            const value = filterValues[Number(filterId)]

            if (value instanceof Date) {
                return `Filter[${filterId}]=${format(value, FilterDateFormat)}`
            }

            return `Filter[${filterId}]=${filterValues[Number(filterId)]}`
        }).join("&")
    }

    if (orderBy) {
        url += `&Sort=${orderBy},${order}`
    }

    if (first) {
        url += "&first=1"
    }

    return url
}
