import React from 'react'
import { Button, Card, CardContent, Typography, Grid } from '@material-ui/core'
import PlusIcon from '@material-ui/icons/AddCircle'
import { useStyles } from './CompactCourseListView.styles'
import CourseSummary from 'models/CourseSummary'

interface CourseListViewProps {
    courses: CourseSummary[]
    courseCount: number
    courseAction?: string
    onCourseClick: (course: CourseSummary) => void
}

const CompactCourseListView: React.FC<CourseListViewProps> = (props) => {
    const classes = useStyles() 

    const handleCourseClick = (course: CourseSummary) => {
        props.onCourseClick(course)
    }
    
    return (
        <>
            <Typography variant="h5">{props.courseCount} Results found</Typography>
            <Grid container spacing={4}>
                {props.courses.map(course => (
                    <Grid item key={course.id} xs={12} sm={4}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="body1" style={{ fontWeight: 700, cursor: 'pointer' }} onClick={() => handleCourseClick(course)}>{course.name}</Typography>
                                <Typography variant="body2" style={{ fontWeight: 700 }}>{course.code}</Typography>
                                <Typography className={classes.description} component="p" variant="body2">{course.description}</Typography>
                                <Button className={classes.detailsButton} variant="text" color='primary' startIcon={<PlusIcon />} onClick={() => handleCourseClick(course)}>{ props.courseAction ? props.courseAction : "View course"}</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default CompactCourseListView
