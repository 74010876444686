import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { customTheme } from "styles/baseTheme"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    contentBox: {
        color: customTheme.checkoutContentBoxText,
        textAlign:"center",
        height: "100%",
        backgroundColor: customTheme.checkoutBarBackground
    },
    contentBoxAction: {
        color: customTheme.checkoutContentBoxText,
        textAlign:"center",
        height: "100%",
        backgroundColor: customTheme.checkoutBarAccent
    },
    totalsText: {
        color: customTheme.checkoutContentBoxText,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", 
        alignItems: "center"
    },    
    checkoutLabelText: {
        color: customTheme.checkoutLabelText
    },
    checkoutButtonLabelText: {
        color: customTheme.checkoutButtonLabelText
    },
    interColumnWidget: {
        position: "relative",
        width: 40,
        right: 20,
        bottom: 70
    }
}))