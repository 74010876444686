import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CountryResponse, CountryStateResponse, getCountries, getPaymentOptions as getPaymentTypes, getSettings, getStates, getTerms, PaymentTypesResponse, SettingsResponse, TermsResponse } from 'api/lookupsApi'
import { AppThunk } from 'app/store'
import Country from 'models/Country'
import CountryState from 'models/CountryState'
import PaymentTypes from 'models/PaymentTypes'
import Settings from 'models/Settings'
import Terms from 'models/Terms'
import { setError } from './errorSlice'


interface StatePayload {
    country: string,
    states: CountryStateResponse
}

interface LookupState {
    countries: Country,
    statesForCountry: {
        [key: string] : CountryState
    },
    paymentTypes: PaymentTypes,
    terms: Terms,
    settings: Settings
}

const initialState: LookupState = {
    countries: {},
    statesForCountry: {},
    paymentTypes: {},
    terms: {content: ""},
    settings: { 
        cart: { 
            hideAddNewCourseButton:false,
            availableCurrencies: {}
        }, 
        reseller: { 
            enableNotificationContacts: false, 
            showCoursesListOnTrainingPage: false,
            showTrainingRequestForm: false, 
            hasPrivateEvents: false, 
            hasBundles: false, 
            portalType: undefined, 
            hasPromoCode: false, 
            contactToEnrollEmail: undefined,
            requestOtherDateEmail: undefined,
            hasResources: false,
            portalHomePage: undefined,
            country: '',
            brandTileLimit: 12,
            showAccountBillingForm: false,
            hideAllPricing: false,
            allowWaitlist: false,
            registerCourseInterest: false,
            allowPrivateEventsWaitlist: false,
            hideGtrEventLabels: false
        },
        contact: { 
            optionalContactPosition: false, 
            optionalContactPhone: false, 
            shippingAddressEnabled: false,
            enableCopyAddress: false
        } 
    }
}

const lookupsSlice = createSlice({
    name: 'lookups',
    initialState,
    reducers: {
        setCountries(state: LookupState, action: PayloadAction<CountryResponse>) {
            state.countries = action.payload
        },
        setStates(state: LookupState, action: PayloadAction<StatePayload>) {
            const { country, states } = action.payload
            state.statesForCountry[country] = states
        },
        setPaymentOptions(state: LookupState, action: PayloadAction<PaymentTypesResponse>) {
            state.paymentTypes = action.payload
        },
        setTerms(state: LookupState, action: PayloadAction<TermsResponse>) {
            state.terms = action.payload
        },
        setSettings(state: LookupState, action: PayloadAction<SettingsResponse>) {
            state.settings = action.payload
        }
    }
})

export const { setCountries, setStates, setPaymentOptions, setTerms, setSettings } = lookupsSlice.actions

export default lookupsSlice.reducer

export const fetchCountries = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const countries = await getCountries()
        dispatch(setCountries(countries))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchStatesForCountry = (country: string): AppThunk<Promise<void>> => async dispatch => {
    try {
        const states = await getStates(country)
        dispatch(setStates({country, states}))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchPaymentTypes = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const paymentTypes = await getPaymentTypes()
        dispatch(setPaymentOptions(paymentTypes))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchTerms = (categoryId : number): AppThunk<Promise<void>> => async dispatch => {
    try {
        const terms = await getTerms(categoryId)
        dispatch(setTerms(terms))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchSettings = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const settings = await getSettings()
        dispatch(setSettings(settings))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
