import RestUtils from 'utils/RestUtils'
import { CreditCardBilling, POBilling, VendorCreditBilling, ContactMeBilling } from 'models/Billing'

export type BillingRequest = CreditCardBilling | POBilling | VendorCreditBilling | ContactMeBilling

export async function postPayment(params: BillingRequest): Promise<void> {
    const url = "/payments"

    await RestUtils.post<void>(url, params)
}

export async function postPaymentData(params: BillingRequest): Promise<boolean> {
    const url = "/payments/savePaymentData"

    await RestUtils.post<void>(url, params)

    return true
}

export interface POFileResponse {
    id: number
}

export async function postPoFile(file: File): Promise<POFileResponse> {
    const url = "/payments/upload"
    
    let formData = new FormData()
    formData.set('POBlobFile', file)

    const { data } = await RestUtils.post<POFileResponse>(url, formData)

    return data
}
