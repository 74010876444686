import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { customTheme } from 'styles/baseTheme'

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width:'100%',
        color: customTheme.footerTextColor,
        backgroundColor: customTheme.footerColor,
        borderTop: `4px solid ${customTheme.footerBorderColor}`,
        '& a': {
            marginRight: 32,
            color: customTheme.footerTextColor,
        }
    }
}))
