import React from 'react'
import { Card, CardContent, Divider, Typography } from '@material-ui/core'

import { useStyles } from './AdditionalNotificationContactsCard.styles'
import NotificationContactsEditor from 'components/members/NotificationContactsEditor'

interface AdditionalNotificationContactsCardProps {
}

const AdditionalNotificationContactsCard: React.FC<AdditionalNotificationContactsCardProps> = (props) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Card style={{ marginTop: 30 }}>
                <CardContent style={{ position: "relative" }}>
                    <Typography className={classes.title} variant="h5" color="primary">Additional Notification Contacts</Typography>
                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                    <NotificationContactsEditor additional={true} />
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default AdditionalNotificationContactsCard
