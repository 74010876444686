import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Backdrop, Box, CircularProgress, Container, Typography } from '@material-ui/core'

import { RootState } from 'app/rootReducer'
import { useThunkDispatch } from 'app/store'
import AddCourseWidget from 'components/cart/AddCourseWidget'
import CartItemCard from 'components/cart/CartItemCard'
import PaymentForm from 'components/cart/payment/PaymentForm'
import TotalsWidget from 'components/cart/TotalsWidget'
import ContactDrawer from 'components/contacts/ContactDrawer'
import AdditionalNotificationContactsCard from 'components/cart/AdditionalNotificationContactsCard'
import ErrorMessageWidget, { setError } from 'components/widgets/ErrorMessageWidget'
import LoadingWidget from 'components/widgets/LoadingWidget'
import Contact from 'models/Contact'
import Course from 'models/Course'
import OrderItem from 'models/OrderItem'
import { setLocalSubmit, setCartSubmit } from 'slices/cartSubmitSlice'
import { updateContactExtraInfo } from 'slices/contactListSlice'
import { addItemToOrder, fetchOrder, removeFromOrder } from 'slices/orderSlice'
import { setPayment } from 'slices/paymentSlice'
import { setSelectedContacts, setSelectedOrderItem } from 'slices/selectionsSlice'
import CartBundleCard from 'components/cart/CartBundleCard'
import CurrenceySelectorDialog from 'components/widgets/CurrencySelectorDialog'

interface CartProps { }

const CartPage: React.FC<CartProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()
    const paymentRef = useRef<HTMLInputElement>(null)
    
    const order = useSelector(
        (state: RootState) => state.order
    )
    const payment = useSelector(
        (state: RootState) => state.payment
    )
    const cartSubmit = useSelector(
        (state: RootState) => state.cartSubmit.submit
    )
    const localSubmit = useSelector(
        (state: RootState) => state.cartSubmit.localSubmit
    )
    const cartValid = useSelector(
        (state: RootState) => state.cartSubmit.cartValid
    )
    const notificationContacts = useSelector(
        (state: RootState) => state.cartSubmit.notificationContacts
    )
    const { selectedItem, selectedContacts } = useSelector(
        (state: RootState) => state.selections
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const [cartCurrency, setCartCurrency] = useState("USD")
    const [showPaymentForm, setShowPaymentForm] = useState(false)
    const [cartIsLoading, setCartIsLoading] = useState(false)
    const [paymentType, setPaymentType] = useState("")
    const [updatingStudents, setUpdatingStudents] = useState(false)
    const [needCurrencyCheck, setNeedCurrencyCheck] = useState(false)

    // Fetch the course info
    useEffect(() => {
        const itemHasMultipleCurrencies = (course: Course): boolean => {
            const currencyCount = course.formats.length ? course.formats[0].prices.length : 1
            return currencyCount > 1
        }
        
        const loadAsync = async () => {
            setCartIsLoading(true)
            if (location.state) {
                const { item } = location.state
                if (order.items.length === 0 && item.course && itemHasMultipleCurrencies(item.course)) {
                    setNeedCurrencyCheck(true)
                }
                console.log(`Adding item to cart`)
                await dispatch(addItemToOrder(item))
            } else {
                await dispatch(fetchOrder())
            }
            setCartIsLoading(false)
        }

        loadAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location.state])

    useEffect(() => {
        setShowPaymentForm(false)
    }, [order])

    useEffect(() => {
        if (showPaymentForm) {
            scrollToPaymentSection()
        }
    }, [showPaymentForm])

    useEffect(() => {
        if (payment.complete) {
            dispatch(setPayment(false))
            history.push(`/order/finish`, { text: "We will contact you with the training details." })
        }
    }, [history, dispatch, payment])

    useEffect(() => {
        if (!localSubmit && notificationContacts != null) {
            goToNextStep()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localSubmit, notificationContacts])

    if (order == null) {
        return null
    }

    const toggleContactDrawer = (open: boolean, item?: OrderItem) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return
        }

        dispatch(setSelectedContacts(open ? [] : undefined))
        dispatch(setSelectedOrderItem(item))
    }

    const handleCurrencyChange = (currency: string) => {
        setCartCurrency(currency)
        setNeedCurrencyCheck(false)
        dispatch(fetchOrder(currency))
    }

    const handleRemoveItem = (item: OrderItem) => {
        console.log(`Removing item from order`)
        dispatch(removeFromOrder(item))
    }

    const handleAddContacts = async (contactIds: number[]) => {
        if (selectedItem) {
            console.log(`Adding ${contactIds} to event ${selectedItem}`)
            setUpdatingStudents(true)
            await dispatch(addItemToOrder(selectedItem, contactIds))
            setUpdatingStudents(false)
        } else {
            console.log("No item is selected in the card. Unable to add students.")
        }
    }

    const handleRemoveStudent = async (item: OrderItem, contactId: number) => {
        console.log(`Removing student $contactId`)
        setUpdatingStudents(true)
        await dispatch(removeFromOrder(item, [contactId]))
        setUpdatingStudents(false)
    }

    const handleEditStudent = (item: OrderItem, contact: Contact) => {
        history.push("/contact", { contact })
    }

    const handleUpdateStudentExtraInfo = async (item: OrderItem, contactId: number, extraInfo: any) => {
        if (await dispatch(updateContactExtraInfo(contactId, extraInfo))) {
            dispatch(fetchOrder())
        }
    }

    const handleAddCourseButtonClicked = () => {
        history.push("/")
    }

    const handlePaymentTypeChange = (paymentType: string) => {
        setPaymentType(paymentType)
        scrollToPaymentSection()
    }

    const handleNextClicked = () => {
        if (isCartValid()) {
            // For now the actions for the next button are: Payment form hidden => payment form shown and submit
            if (showPaymentForm) {
                if (settings.reseller.enableNotificationContacts) {
                    dispatch(setLocalSubmit(true))
                } else {
                    goToNextStep()
                }
            } else {
                // The cart value may be discounted to zero. In which case we only allow payment type of OTHER
                if (order.total === 0) {
                    setPaymentType("OTHER")
                }
                setShowPaymentForm(true)
            }
        }
    }

    const goToNextStep = () => {
        if (paymentType === "") {
            dispatch(setError("You must select a payment type"))
        } else {
            dispatch(setCartSubmit(true))
        }
    }

    const isCartValid = (): boolean => {
        const missingStudents = order.items.reduce((count, item) => (item.students.length === 0) ? ++count : count, 0)

        if (missingStudents > 0) {
            console.log("Missing student count:", missingStudents)
            dispatch(setError("You must have at least one student per course. Please remove courses with zero students from your shopping cart."))
            return false
        }

        const missingInfoCount = order.items.reduce((accumulator, item) => {
            return item.students.reduce((acc, student) => {
                const requirements = student.requirements
                const needExtraInfo = requirements?.fields.reduce((count, field) => (field.required && field.value === "") ? ++count : count, 0) ?? 0
                return acc + needExtraInfo + (requirements?.addressRequired ? 1 : 0)
            }, accumulator)
        }, 0)

        if (missingInfoCount > 0) {
            console.log("Missing info count:", missingInfoCount)
            dispatch(setError("Please provide the missing student information above, before proceeding."))
            return false
        }
        
        return true
    }

    const scrollToPaymentSection = () => {
        if (paymentRef.current) {
            const timer = setTimeout(() => {
                if (paymentRef.current) {
                    window.scrollTo({
                        left: 0,
                        top: paymentRef.current.offsetTop,
                        behavior: "smooth"
                    })
                }
            }, 10) // timeout set to a really small value as we appear to need some time for the DOM to settle before scrolling.
            return () => clearTimeout(timer)
        }
    }

    const cartHasItems = order.items.length > 0
    const buttonText = showPaymentForm ? (order.total === 0 ? "Enroll" : "Process Payment") : "Next"
    const showCurrencyDialog = Object.keys(settings.cart.availableCurrencies).length > 1 && needCurrencyCheck && order.items.length === 1

    const renderCart = () => {
        return (
            <React.Fragment>
                {cartHasItems ? (
                    <Typography variant="body1">Please review your selected courses below; add any students (including yourself) to view updated pricing before completing your check-out.</Typography>
                ) : (
                    <Typography align="center" variant="body1">Your Shopping Cart is empty.</Typography>
                )}
                {order.items.map((item, index) => (
                    item.course ?
                        <div key={index}>
                            <CartItemCard
                                item={item}
                                onEnrollStudents={toggleContactDrawer(true, item)}
                                onEditStudent={handleEditStudent}
                                onUpdateStudentExtraInfo={handleUpdateStudentExtraInfo}
                                onRemoveStudent={handleRemoveStudent}
                                onRemoveItem={handleRemoveItem}
                            />
                        </div>
                    :
                        <CartBundleCard
                            key={index}
                            item={item}
                            onEnrollStudents={toggleContactDrawer(true, item)}
                            onEditStudent={handleEditStudent}
                            onUpdateStudentExtraInfo={handleUpdateStudentExtraInfo}
                            onRemoveStudent={handleRemoveStudent}
                            onRemoveItem={handleRemoveItem}
                        />
                    ))
                }
                {!settings.cart.hideAddNewCourseButton &&
                    <AddCourseWidget OnAddButtonClicked={handleAddCourseButtonClicked} />
                }
                {showPaymentForm && settings.reseller.enableNotificationContacts &&
                    <AdditionalNotificationContactsCard />
                }
                {showPaymentForm &&
                    <PaymentForm ref={paymentRef} paymentType={paymentType} cartTotal={order.total} onPaymentTypeChange={handlePaymentTypeChange} />
                }
                {cartHasItems &&
                    <TotalsWidget buttonText={buttonText} subtotal={order.subtotal} tax={order.tax} total={order.total} currency={order.currency} currencyName={order.currencyName} disableNext={cartSubmit || !cartValid} onNextClicked={handleNextClicked} />
                }
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Box component="main" style={{ marginBottom: '2rem' }}>
                <Container maxWidth="lg" fixed={true}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h3" color='primary'>Your Shopping Cart</Typography>
                        {/* {Object.keys(settings.cart.availableCurrencies).length > 1 &&
                            <CurrencyFilterDropDown currency={cartCurrency} currencyFilter={settings.cart.availableCurrencies} onCurrencyChange={handleCurrencyChange} />
                        } */}
                    </Box>
                    { cartIsLoading ? (
                        <LoadingWidget />
                    ) : (
                        renderCart()
                    )}
                </Container>
            </Box>
            <CurrenceySelectorDialog open={showCurrencyDialog} currency={cartCurrency} currencyFilter={settings.cart.availableCurrencies} onCurrencyChange={handleCurrencyChange} />
            <ContactDrawer open={!!selectedContacts} onClose={toggleContactDrawer(false)} onAddContacts={handleAddContacts} />
            <ErrorMessageWidget />
            <Backdrop open={updatingStudents || cartSubmit} style={{zIndex: 10000}}>
                <CircularProgress color="primary"/>
            </Backdrop>
        </React.Fragment>
    )
}

export default CartPage
