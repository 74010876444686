import RestUtils from 'utils/RestUtils'
import Contact from 'models/Contact'

export interface CreateContactRequest extends Omit<Contact, "id"> { }
export interface UpdateContactRequest extends Contact { }
export interface ContactResponse extends Contact { }

export async function getContacts(): Promise<ContactResponse[]> {
    const url = "/contacts"

    const { data } = await RestUtils.get<ContactResponse[]>(url)

    return data
}

export async function postContact(contact: CreateContactRequest): Promise<ContactResponse> {
    const url = "/contacts"

    const { data } = await RestUtils.post<ContactResponse>(url, contact)

    return data
}

export async function patchContact(contact: UpdateContactRequest): Promise<ContactResponse> {
    const url = "/contacts"

    const { data } = await RestUtils.patch<ContactResponse>(url, contact)

    return data
}

export async function deleteContact(contactId: number): Promise<ContactResponse[]> {
    const url = `/contacts/${contactId}`
    
    const { data } = await RestUtils.delete<ContactResponse[]>(url)

    return data
}

export async function patchContactExtraInfo(contactId: number, extraInfo: any): Promise<ContactResponse> {
    const url = `/contacts/${contactId}/extrainfo`

    const { data } = await RestUtils.patch<ContactResponse>(url, extraInfo)

    return data
}
