import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Box, Container, Typography } from "@material-ui/core"

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from "components/widgets/LoadingWidget"
import DocumentTreeView from "components/documents/DocumentTreeView"
import { fetchReportDocumentList } from "slices/reportsSlice"
import { downloadDocument } from "api/reportsApi"

interface DocumentsPageProps { }

const DocumentsPage: React.FC<DocumentsPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const folders = useSelector(
        (state: RootState) => state.reports.folders
    )
    const [loading, setLoading] = useState(false)

    const hasDocuments = member?.canHazDocuments ?? false

    useEffect(() => {
        const loadAsync = async () => {
            setLoading(true)
            await dispatch(fetchReportDocumentList())
            setLoading(false)
        }
        if (hasDocuments) {
            loadAsync()
        }
    }, [dispatch, hasDocuments])

    const handleDocumentClick = async (documentId: string) => {
        const response = await downloadDocument(documentId)
        const fileName = response.headers['content-disposition'].split('filename="')[1].split('"')[0]
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
        a.remove()
    }

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                <Typography style={{ textAlign: 'center' }} color="primary" variant="h3">Documents</Typography>
                {loading ? (
                    <LoadingWidget />
                ) : (
                    <Box>
                        <DocumentTreeView folders={folders} onDocumentClick={handleDocumentClick}/>
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default DocumentsPage
