import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { customTheme } from 'styles/baseTheme'

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        color: customTheme.headerTextColor,
        backgroundColor: customTheme.headerColor,
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover',
        padding: '2rem'
    }
}))
