import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { PrivateEventsListResponse, getPrivateEvents, getPrivateEventsForCourse } from 'api/privateEventsApi'
import { setError } from './errorSlice'

export type PrivateEventsState = PrivateEventsListResponse | null

const initialState: PrivateEventsState = {
    events: []
}

const privateEventsSlice = createSlice({
    name: 'privateEvents',
    initialState,
    reducers: {
        setPrivateEvents(state: PrivateEventsState, action: PayloadAction<PrivateEventsListResponse>) {
            return action.payload
        }
    }
})

const { setPrivateEvents } = privateEventsSlice.actions

export default privateEventsSlice.reducer

export const fetchPrivateEvents = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const events = await getPrivateEvents()
        dispatch(setPrivateEvents(events))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchPrivateEventsForCourse = (courseId: number): AppThunk<Promise<void>> => async dispatch => {
    try {
        const events = await getPrivateEventsForCourse(courseId)
        dispatch(setPrivateEvents(events))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
