import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux';

import { RootState } from 'app/rootReducer'
import { useStyles } from './CourseDetails.styles'
import Course from 'models/Course'
import PriceRenderer from 'components/widgets/PriceRenderer'
import { hasPrice } from 'utils/courseUtils'
import CheckmarkIcon from '@material-ui/icons/Done'

interface CourseDetailsViewProps {
    course: Course,
    contactToEnrollEmail?: string
    onBuyCourse: (course: Course) => void
}

const CourseDetailsView: React.FC<CourseDetailsViewProps> = (props) => {
    const classes = useStyles()
    const history = useHistory()

    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const handleBuyButton = (course: Course) => {
        props.onBuyCourse(course)
    }

    const handleBundleButton = (course: Course) => {
        history.push(`/it-training/bundles/${course.id}`)
    }

    const handleCustomizeBundle = (bundleId: number) => {
        history.push(`/order/bundle/${bundleId}`)
    }

    const buttonText = (course: Course) => {

        const hasZeroPrice = course.formats.some((format) => {
            let zeroPrices:boolean[] = []
            format.prices.map((price,index) => (
                zeroPrices.push( price.amount === 0 )
            ))
    
            return zeroPrices.includes(true)
        })

        if (props.contactToEnrollEmail) {
            return (course.isFree || hasZeroPrice) ? "Contact To Redeem" : "Contact To Buy"
        } else {
            return (course.isFree || hasZeroPrice) ? "Redeem" : "Buy Now" 
        }
    }

    return (
        <React.Fragment>
            <Box className={classes.root} display="flex" flexDirection="column" alignItems="center">
                <img
                    className={classes.courseImage}
                    src={props.course.category.logo}
                    alt={props.course.category.name}
                    title={props.course.category.name}
                />
                <Typography style={{textAlign: 'center'}} variant="h4">{props.course.name}</Typography>
                <Box className={classes.row} display="flex" justifyContent="center">
                    <Typography className={classes.label}>Code:</Typography>
                    <Typography className={classes.value}>{props.course.code}</Typography>
                    <Typography className={classes.separator}>|</Typography>
                    {props.course.duration > 0 && 
                        <>
                            <Typography className={classes.label}>Duration:</Typography>
                            <Typography className={classes.value}>{`${props.course.duration} ${props.course.durationUnit}`}</Typography>
                            <Typography className={classes.separator}>|</Typography>
                        </>
                    }
                </Box>
                <Box className={classes.row} display="flex" justifyContent="center">
                    {props.course.formats.map((format, index) => (
                        <React.Fragment key={index}>
                            <Typography className={classes.label}>{`${format.label} (${format.name}):`}</Typography>
                            {settings.reseller.hideAllPricing 
                                ? (<Typography className={classes.label}>Price Not Available</Typography>)
                                : (<>
                                    {format.prices.map((price, index) => (
                                        <PriceRenderer className={classes.value} key={index} isFree={props.course.isFree} price={price} />
                                    ))}
                                   </>)
                            }
                                
                            {index < props.course.formats.length - 1 &&
                                <Typography className={classes.separator}>|</Typography>
                            }
                        </React.Fragment>
                    ))}
                </Box>
                {props.course.voucherEligibleTitle &&
                    <Box className={classes.row} display="flex" justifyContent="center">
                            <Typography className={classes.label}>Voucher:</Typography>
                            <Typography className={classes.value}><CheckmarkIcon style={{ verticalAlign: 'bottom', margin: '0 5px 0 0' }} />{`${props.course.voucherEligibleTitle}`}</Typography>
                    </Box>
                }
            </Box>

            {((props.course.standAlone && hasPrice(props.course)) || props.course.isPartOfBundle || props.course.bundleId > 0) &&
                <Box style={{ marginTop: 30 }} display="flex" justifyContent="center">
                    {props.course.standAlone && hasPrice(props.course) &&
                        <Button style={{ marginRight: props.course.isPartOfBundle ? 12 : 0 }} variant="contained" color="primary" onClick={() => handleBuyButton(props.course)}>
                            {buttonText(props.course)}
                        </Button>
                    }
                    {props.course.isPartOfBundle &&
                        <Button variant="contained" color="primary" onClick={() => handleBundleButton(props.course)}>Create Bundle</Button>
                    }
                    {props.course.bundleId > 0 &&
                        <Button variant="contained" color="primary" onClick={() => handleCustomizeBundle(props.course.bundleId)}>Customize Bundle</Button>
                    }
                </Box>
            }
        </React.Fragment>
    )
}

export default CourseDetailsView
