import React from 'react'
import { useSelector } from 'react-redux'
import { Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer';
import { setError } from 'slices/errorSlice'

export { setError } // Make it available from a single import rather than needing to import the errorSlice as well.

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface ErrorMesageProps { }

const ErrorMessageWidget: React.FC<ErrorMesageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const error = useSelector(
        (state: RootState) => state.error
    )

    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setError(null))
    }

    return (
        <Snackbar open={!!error} autoHideDuration={7000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} color="error">
                {error}
            </Alert>
        </Snackbar>
    )
}

export default ErrorMessageWidget
