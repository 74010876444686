import React, { useState } from 'react'
import { Box, Fade, Paper, Popper as MuiPopper, Typography, TypographyProps } from '@material-ui/core'

import { useStyles } from './Popper.styles'

export interface PopperProps extends TypographyProps {
    text: string
}

const Popper: React.FC<PopperProps> = (props) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null)

    const { text, ...otherProps } = props

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
   
    const open = Boolean(anchorEl)

    return (
        <React.Fragment>
            <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                {...otherProps}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {text}
            </Typography>
            <MuiPopper 
                className={classes.popper}
                open={open} 
                anchorEl={anchorEl} 
                transition
                placement="top"
                disablePortal={false}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                    },
                    arrow: {
                        enabled: true,
                        element: arrowRef,
                    },
                }}
            >
                {({ TransitionProps }) => 
                    <Fade {...TransitionProps} timeout={350}>
                        <Box>
                            <span className={classes.arrow} ref={setArrowRef}></span>
                            <Paper className={classes.paper} elevation={16}>
                                {props.children}
                            </Paper>
                        </Box>
                    </Fade>
                }
            </MuiPopper>
        </React.Fragment>
    )
}

export default Popper
