import React, { ChangeEvent, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { useStyles } from "./PaymentForm.styles"
import { RootState } from 'app/rootReducer'
import StripeFormWrapper from './StripeFormWrapper'
import POPaymentForm from './POPaymentForm'
import ContactMePaymentForm from './ContactMePaymentForm'
import VendorPaymentForm from './VendorPaymentForm'
import { fetchPaymentTypes }  from 'slices/lookupsSlice'

interface PaymentFormProps {
    paymentType: string
    cartTotal: number
    onPaymentTypeChange: (paymentType: string) => void
}

const PaymentForm = React.forwardRef<HTMLDivElement, PaymentFormProps>((props , ref) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const paymentTypes = useSelector(
        (state: RootState) => state.lookups.paymentTypes
    )
    const { cartTotal, paymentType } = props
    
    const paymentOptions = useMemo(() => paymentTypes[paymentType], [paymentTypes, paymentType])

    const id = "paymentType"

    useEffect(() => {
        dispatch(fetchPaymentTypes())
    }, [dispatch])

    useEffect(() => {
        const keys = Object.keys(paymentTypes)
        if (keys.length === 1) {
            props.onPaymentTypeChange(keys[0])
        }
    }, [props, paymentTypes])

    const handleChangePaymentType = (event: ChangeEvent<{ name?: string; value: unknown;}>) => {
        props.onPaymentTypeChange(event.target.value as string)
    }

    if (Object.keys(paymentTypes).length === 0) {
        return null
    }

    return (
        <React.Fragment>
            <Card className={classes.root} ref={ref}>
                <CardContent style={{ position: "relative" }}>
                    <Typography className={classes.title} variant="h5" color="primary">Checkout</Typography>
                    { cartTotal > 0 &&
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id={`${id}.label`}>Payment Type</InputLabel>
                                    <Select
                                        labelId={`${id}.label`}
                                        id={id}
                                        name={id}
                                        onChange={handleChangePaymentType}
                                        value={paymentType}
                                        fullWidth={true}
                                    >
                                        {Object.keys(paymentTypes).map(key =>
                                            <MenuItem key={key} value={key}>
                                                {paymentTypes[key].label}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {paymentType === "CREDITCARD" && 
                        <StripeFormWrapper />
                    }
                    {paymentType === "PO" &&
                        <POPaymentForm paymentOptions={paymentOptions} />
                    }
                    {paymentType === "CREDIT" &&
                        <VendorPaymentForm paymentType={paymentType} paymentOptions={paymentOptions} />
                    }
                    {paymentType === "FLEX" &&
                        <VendorPaymentForm paymentType={paymentType} paymentOptions={paymentOptions} />
                    }
                    {paymentType === "OTHER" &&
                        <ContactMePaymentForm />
                    }
                </CardContent>
            </Card>
        </React.Fragment>
    )
})

export default PaymentForm
