import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form, FormikHelpers } from 'formik'
import { Box } from '@material-ui/core'
import * as yup from 'yup'

import { useStyles } from './POPaymentForm.styles'
import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import AutoSubmitForm from 'components/widgets/form/AutoSubmitForm'
import FormStatusLogger from 'components/widgets/form/FormStatusLogger'
import FormTextField from 'components/widgets/form/FormTextField'
import FileUploadWidget from 'components/widgets/FileUploadWidget'
import { createPayment } from 'slices/paymentSlice'
import { POBilling } from 'models/Billing'
import Address from 'models/Address'
import BillingAddressForm, { BillingValues } from './BillingAddressForm'
import { postPoFile } from 'api/paymentApi'
import { PaymentOptions } from 'models/PaymentTypes'
import { stateFieldForCountry, stateLabelForCountry } from 'utils/addressUtils'
import YourContactInfoForm, { YourContactInfoValues } from './YourContactInfoForm'
import NotificationContact from 'models/NotificationContact'
import NotificationContactsInjector from 'components/members/NotificationContactsInjector'

interface POValues {
    paymentExtraInfo: string
}

interface FormValues {
    me: YourContactInfoValues
    po: POValues
    billing: BillingValues
    notificationContacts: NotificationContact[] | null
    saveBillingAddress: boolean
}

type FileType = File | undefined

interface POPaymentFormProps {
    paymentOptions: PaymentOptions
}

const POPaymentForm: React.FC<POPaymentFormProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const notificationContacts = useSelector(
        (state: RootState) => state.cartSubmit.notificationContacts
    )
    const contacts = useSelector(
        (state: RootState) => state.contacts
    )
    const [poFile, setPoFile] = useState(undefined as FileType)

    let validationSchema = yup.object({
        po: yup.object({
            paymentExtraInfo: yup.string().required("This field is required.")
        }),
        billing: yup.lazy((values: BillingValues) => {
            const validateStateField = values.country && values.country !== "other"

            return yup.object(Object.assign({}, {
                firstName: yup.string().required("First Name is a required field."),
                lastName: yup.string().required("Last Name is a required field."),
                company: yup.string().required("Company is a required field."),
                email: yup.string().required("Email is a required field."),
                phone: yup.string().required("Phone is a required field."),

                street: yup.string().required("Street is a required field."),
                city: yup.string().required("City is a required field."),
                country: yup.string().required("Country is a required field."),
                zip: yup.string().required("Postal / Zip Code is a required field.")
            }, validateStateField ? { [stateFieldForCountry(values.country)]: yup.string().required(`${stateLabelForCountry(values.country)} is a required field.`) } : {}))
        })
    })

    let billingContact: Address = {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",

        street: "",
        city: "",
        state: "",
        province: "",
        other: "",
        country: "",
        zip: ""
    }

    let me = {
        firstName: "",
        lastName: "",
        email: ""
    }

    if (member != null) {
        if (member.contact.addresses.billing != null) {
            const contact = member.contact.addresses.billing

            billingContact = {
                firstName: contact.firstName,
                lastName: contact.lastName,
                company: contact.company,
                email: contact.email,
                phone: contact.phone,

                street: contact.street,
                city: contact.company,
                state: contact.state,
                province: contact.province,
                other: contact.other,
                country: contact.country,
                zip: contact.zip
            }
        }
    } else if (contacts != null) {
        const filteredContacts = contacts.filter(contact => contact.isMe)

        if (filteredContacts.length > 0) {
            me = {
                firstName: filteredContacts[0].firstName,
                lastName: filteredContacts[0].lastName,
                email: filteredContacts[0].email
            }
        }

        const validateMe = yup.object({
            me: yup.object({
                firstName: yup.string().required("First Name is a required field."),
                lastName: yup.string().required("Last Name is a required field."),
                email: yup.string().required("Email is a required field.")
            })
        })

        validationSchema = validationSchema.concat(validateMe)
    }

    const initialValues: FormValues = {
        me: me,
        po: {
            paymentExtraInfo: props.paymentOptions.paymentExtraInfoValue ?? ""
        },
        billing: billingContact,
        notificationContacts: notificationContacts,
        saveBillingAddress: true
    }
    
    const handleFileselected = (file?: File) => {
        console.log("Select file:", file ? file.name : "Removing file")
        setPoFile(file)
    }
    
    const handleUseMyContactInfoButton = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        if (member != null && member.contact.addresses.contact != null) {
            const contact = member.contact.addresses.contact
            setFieldValue("billing.firstName", contact.firstName)
            setFieldValue("billing.lastName", contact.lastName)
            setFieldValue("billing.company", contact.company)
            setFieldValue("billing.email", contact.email)
            setFieldValue("billing.phone", contact.phone)

            setFieldValue("billing.street", contact.street)
            setFieldValue("billing.city", contact.city)
            setFieldValue("billing.state", contact.state)
            setFieldValue("billing.province", contact.province)
            setFieldValue("billing.country", contact.country)
            setFieldValue("billing.zip", contact.zip)
        }
    }

    const handleCartSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        setSubmitting(true)

        let poFileId = {}
        if (poFile) {
            const poFileResult = await postPoFile(poFile)
            poFileId = { POBlobFile: poFileResult.id }
        }

        const billing: POBilling = member == null ?
            Object.assign({}, values.po, values.billing, { me: values.me, notificationContacts: values.notificationContacts, paymentType: "PO", saveBillingAddress: values.saveBillingAddress }, poFileId) :
            Object.assign({}, values.po, values.billing, { notificationContacts: values.notificationContacts, paymentType: "PO", saveBillingAddress: values.saveBillingAddress }, poFileId)

        await dispatch(createPayment(billing))
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCartSubmit}
        >
            {({
                values,
                errors,
                setFieldValue
            }) => {
                return (
                    <React.Fragment>
                        <Form>
                            <YourContactInfoForm />
                            <Box className={classes.root}>
                                <FormTextField
                                    name="po.paymentExtraInfo"
                                    placeholder={props.paymentOptions.paymentExtraInfo}
                                />
                                <FileUploadWidget file={poFile} onFileSelected={handleFileselected} />

                                <BillingAddressForm
                                    saveBillingAddress={values.saveBillingAddress}
                                    country={values.billing.country}
                                    stateProvince={values.billing.province ?? values.billing.state ?? values.billing.other}
                                    onUseMyContactInfoButton={member == null ? undefined : () => handleUseMyContactInfoButton(setFieldValue)}
                                />
                            </Box>
                            <NotificationContactsInjector />
                        </Form>
                        <AutoSubmitForm />
                        <FormStatusLogger values={values} errors={errors} />
                    </React.Fragment>
                )
            }}
        </Formik>
    )
}

export default POPaymentForm
