import { Box, CardContent, Grid, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import TransactionDetail from 'models/TransactionDetail'
import React from 'react'

import { formatCurrency } from 'utils/currency'
// import { useStyles } from './TransactionDetailCard.styles'

interface TransactionDetailCardProps {
    transactionDetail: TransactionDetail
}

const TransactionDetailCard: React.FC<TransactionDetailCardProps> = (props) => {
    // const classes = useStyles()
    const { transactionDetail: detail } = props

    if (detail == null) {
        return null
    }

    const details = [
        { label: "ID", value: detail.id, style: { } },
        { label: "Order ID", value: detail.orderId },
        { label: "Order Date", value: detail.orderDate },
        { label: "First Name", value: detail.registeredInfo.firstName },
        { label: "Last Name", value: detail.registeredInfo.lastName },
        { label: "Email", value: detail.registeredInfo.email },
        { label: "Payment Type", value: detail.paymentType },
        { label: "Currency", value: detail.currency },
        { label: "Line Item Count", value: detail.lineItemCount },
        { label: "Sub Total", value: formatCurrency(detail.subTotal, detail.currency) },
        { label: "Tax", value: formatCurrency(detail.tax, detail.currency) },
        { label: "Grand Total", value: formatCurrency(detail.grandTotal, detail.currency) },
    ]

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Box display="flex" justifyContent='space-between'>
                        <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold' }} variant="overline">Transaction Details</Typography>
                    </Box>
                    <Grid container justify="flex-start" spacing={2}>
                        {details.map((detail, index) => (
                            <Grid key={detail.label} item xs={5} sm={4}>
                                <Box display="flex" flexDirection="column">
                                    <Typography component="p" variant="overline">{detail.label.toUpperCase()}</Typography>
                                    <Typography style={detail.style} component="p" variant="body1">{detail.value}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default TransactionDetailCard
