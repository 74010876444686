import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    icon: {
    },
    action: {
        '&.MuiGrid-item': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    nameText: {
        fontWeight: 700
    },
    emailText: {
    },
}))
