import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { AppBar, Badge, Box, Button, IconButton, Menu, MenuItem, Select, Toolbar, Typography, useTheme, Hidden, Drawer, Divider } from '@material-ui/core'
import { IconFlagCA, IconFlagUS } from 'material-ui-flags'
import { addDays } from 'date-fns'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import Config from 'config/Config'
import { useStyles } from './TopAppBar.styles'
import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import config from 'config/Config'
import ECLogo from 'assets/images/ec_logo-clr.svg' 
import { logout } from 'api/memberApi'
import { fetchContentPages } from 'slices/contentSlice'

interface TopAppBarProps { }

const TopAppBar: React.FC<TopAppBarProps> = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(null)
    const [resourcesAnchorEl, setResourceAnchorEl] = useState<null | HTMLElement>(null)
    const [trainingAnchorEl, setTrainingAnchorEl] = useState<null | HTMLElement>(null)
    const [cookies, setCookie] = useCookies(['_region'])
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )
    const customPages = useSelector(
        (state: RootState) => state.content.pages
    )
    const order = useSelector(
        (state: RootState) => state.order
    )

    const handleLogin = () => {
        if (window.location.hostname === 'localhost') {
            history.push("/login", { action: 'login', next: location })
        } else {
            window.location.href = config.BaseName + "login"
        }
    }

    useEffect(() => {
        dispatch(fetchContentPages())
    }, [dispatch])

    const handleMobileMenu = () => {
        setShowMobileMenu(true)
    }

    const handleMobileMenuClose = () => {
        setShowMobileMenu(false)
    }

    const handleLogout = async () => {
        handleAccountMenuClose()
        // history.replace("/login", { action: 'logout', next: { pathname: '/', search: '' }})
        await logout()
        window.location.href = (config.BaseName ? config.BaseName : "/")
    }

    const handleAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAccountAnchorEl(event.currentTarget)
    }

    const handleAccountMenuClose = () => {
        setAccountAnchorEl(null)
    }

    const handleResourceMenu = (event: React.MouseEvent<HTMLElement>) => {
        setResourceAnchorEl(event.currentTarget)
    }

    const handleResourceMenuClose = () => {
        setResourceAnchorEl(null)
    }

    const handleTrainingMenu = (event: React.MouseEvent<HTMLElement>) => {
        setTrainingAnchorEl(event.currentTarget)
    }

    const handleTrainingMenuClose = () => {
        setTrainingAnchorEl(null)
    }

    const handleEditProfile = () => {
        handleAccountMenuClose()
        handleMobileMenuClose()
        history.push("/account")
    }

    const handleChangePassword = () => {
        handleAccountMenuClose()
        handleMobileMenuClose()
        history.push("/password")       
    }

    const handleReportsButton = () => {
        handleAccountMenuClose()
        handleMobileMenuClose()
        history.push("/reports")
    }

    // const handleDocumentsButton = () => {
    //     handleAccountMenuClose()
    //     handleMobileMenuClose()
    //     history.push("/documents")
    // }

    const handleContactsButton = () => {
        handleAccountMenuClose()
        handleMobileMenuClose()
        history.push("/contacts")
    }

    const handleCreateContact = () => {
        handleAccountMenuClose()
        handleMobileMenuClose()
        history.push("/contact")
    }

    const handleCartButton = () => {
        handleMobileMenuClose()
        history.push("/order")
    }

    const handleECLogoClick = () => {
        window.open('https://www.exitcertified.com/', '_blank')
    }

    const handleHome = () => {
        history.push("/")
    }

    const handleContentPageClick = (urlSegment: string) => {
        handleResourceMenuClose()
        handleMobileMenuClose()
        history.push(`/content/${urlSegment}`)
    }

    const handleRequestCourse = () => {
        handleTrainingMenuClose()
        handleMobileMenuClose()
        history.push("/requests/course")
    }

    const handleTrainingNavigation = (path:string) => {
        handleTrainingMenuClose()
        handleMobileMenuClose()
        const fullPath = "/it-training/" + path
        if( location.pathname !== fullPath) {
            history.push(fullPath)
        }
    }

    const handleTrainingResourcesNavigation = () => {
        handleTrainingMenuClose()
        handleMobileMenuClose()
        history.push("/it-training-resources")
    }

    const handleRegisterCoursesNavigation = () => {
        handleTrainingMenuClose()
        handleMobileMenuClose()
        history.push("/register/interest")
    }

    const handleCountryChange = (event: ChangeEvent<any>) => {
        setRegion(event.target.value)
        window.location.reload()
        handleMobileMenuClose()
    }

    const setRegion = (value: string) => {
        let path = Config.BaseName?.replace(/^(.+?)\/*?$/, "$1") ?? "/"
        path = path === "" ? "/" : path;
        const expires = addDays(new Date(), 30);
        if( cookies["_region"] !== value ) {
            setCookie("_region", value, { path: path,  expires: expires });
        }
    }

    const reseller = settings.reseller
    const trainingMenuRequired = reseller.hasBundles || reseller.hasPrivateEvents || reseller.showTrainingRequestForm || reseller.hasResources || reseller.registerCourseInterest
    const isReportingPortal = reseller.portalType === "Reporting"
    const manageAccountRequired = member != null && (settings.reseller.enableNotificationContacts || settings.reseller.showAccountBillingForm)
    const accountMenuRequired = manageAccountRequired || !isReportingPortal || member != null
    const country = settings.reseller.country ? settings.reseller.country : ['US'];

    if(country.length===1) {
        setRegion(country[0]);
    }
    if(!cookies["_region"]) {
        setRegion('US');
    }

    const addContact = (
        <IconButton aria-label="Create Student" title="Create Student" onClick={handleCreateContact} >
            <PersonAddIcon />
        </IconButton>
    )

    const shoppingCart = (
        !isReportingPortal &&
        <IconButton aria-label="Shopping Cart" title="Shopping Cart" onClick={handleCartButton}>
            <Badge badgeContent={order.items.length} color="error">
                <ShoppingCartIcon />
            </Badge>
        </IconButton>
    )

    const accountMenu = (
        accountMenuRequired &&
        <>
            <Button className={classes.toolbarButton} variant="text" onClick={handleAccountMenu}>My Account</Button>
            <Menu
                id="menu-account"
                anchorEl={accountAnchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                style={{ zIndex: theme.zIndex.modal + 2 }}
                keepMounted
                open={Boolean(accountAnchorEl)}
                onClose={handleAccountMenuClose}
                MenuListProps={{
                    className: classes.menu
                }}
            >
                {manageAccountRequired &&
                    <MenuItem onClick={handleEditProfile}>Manage Account</MenuItem>
                }

                {!isReportingPortal &&
                    <MenuItem disabled={location.pathname === '/contacts'} onClick={handleContactsButton}>Manage Students</MenuItem>
                }

                {member != null && (member.isAdmin || member.canHazSfReports) &&
                    <MenuItem onClick={handleReportsButton}>Reporting</MenuItem>
                }

                {/* {member != null && member.canHazDocuments &&
                    <MenuItem onClick={handleDocumentsButton}>Documents</MenuItem>
                } */}

                {member != null &&
                    <MenuItem key={0} disabled={location.pathname === '/password'} onClick={handleChangePassword}>Change Password</MenuItem>
                }
            </Menu>
        </>
    )
    
    const countrySelector = (
        config.ShowRegionFlag &&
        <Select
            name="countrySelect"
            value={cookies["_region"] ?? ''}
            onChange={handleCountryChange}
            className={country.length===1?'single_option':''}
            disableUnderline
            style={{
                pointerEvents: (country.includes('CA') && country.includes('US')) ? 'all' : 'none'
            }}
            MenuProps={{
                className: classes.countryMenu,
                style: {
                    zIndex: theme.zIndex.modal + 2,
                },
            }}
        >
            { country.includes('CA') &&
                <MenuItem value="CA"><IconButton><IconFlagCA />&nbsp;Canada</IconButton></MenuItem>
            }
            { country.includes('US') &&
                <MenuItem value="US"><IconButton><IconFlagUS />&nbsp;United States</IconButton></MenuItem>
            }
        </Select>
    )

    const resellerLogo = (
        < Box className = { [classes.uiBrand__root, classes.uiBrand_start, classes.title].join(' ') } onClick = { handleHome } title = { config.ResellerName } >
        {
            config.ResellerLogo?.length ?
                <img src={config.ResellerLogo} className={classes.uiBrand__media} alt={config.ResellerName} />
                :
                <Typography color='textPrimary'>{config.ResellerName ? config.ResellerName : "Reseller Name"}</Typography>
        }
        </Box >
    )

    const ecLogo = (
        config.ShowECLogo &&
        <Box className={classes.uiBrand__root} onClick={handleECLogoClick} title={'ExitCertified'}>
            <img src={ECLogo} className={classes.uiBrand__media} alt={'ExitCertified'} />
        </Box>
    )

    const accelebrateLogo = (
        config.ShowAccelebrateLogo &&
        <Box className={classes.uiBrand__root} title={'Accelebrate'}>
            <img src={require('../../assets/images/Accelebrate-Logo.png')} className={classes.uiBrand__media} alt={'Accelebrate'} />
        </Box>
    )
    
    const webagesolutionsLogo = (
        config.ShowWebAgeSolutionsLogo && 
        <Box className={classes.uiBrand__root} title={'Web Age Solutions'}>
            <img src={require('../../assets/images/WebAgeSolutions.png')} className={classes.uiBrand__media} alt={'Web Age Solutions'} />
        </Box>
    )

    const resourceMenu = (
        customPages?.length > 0 && (
        customPages.length === 1 ?
            <Typography className={classes.menuText} onClick={() => handleContentPageClick(customPages[0].urlSegment)}>{customPages[0].label}</Typography>
            :
            <>
                <Typography className={classes.menuText} onClick={handleResourceMenu}>RESOURCES</Typography>
                <Menu
                    id="resources"
                    anchorEl={resourcesAnchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    keepMounted
                    style={{zIndex: theme.zIndex.modal + 2 }}
                    open={Boolean(resourcesAnchorEl)}
                    onClose={handleResourceMenuClose}
                    MenuListProps={{
                        className: classes.menu
                    }}
                >
                    {customPages?.map(page => (
                        <MenuItem onClick={() => handleContentPageClick(page.urlSegment)}>{page.label}</MenuItem>
                    ))}
                </Menu>
            </>
        )
    )

    const trainingMenu = (
        !isReportingPortal &&
        <>
            {!trainingMenuRequired ?
                <Typography className={classes.menuText} onClick={() => handleTrainingNavigation("")}>TRAINING</Typography>
                :
                <>
                    <Typography className={classes.menuText} onClick={handleTrainingMenu}>TRAINING</Typography>
                    <Menu
                        id="training"
                        anchorEl={trainingAnchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        keepMounted
                        style={{ zIndex: theme.zIndex.modal + 2 }}
                        open={Boolean(trainingAnchorEl)}
                        onClose={handleTrainingMenuClose}
                        MenuListProps={{
                            className: classes.menu
                        }}
                    >
                        <MenuItem onClick={() => handleTrainingNavigation("")}>IT Training</MenuItem>
                        {settings.reseller.hasBundles &&
                            <MenuItem onClick={() => handleTrainingNavigation("bundles")}>Bundles</MenuItem>
                        }
                        {settings.reseller.hasPrivateEvents &&
                            <MenuItem onClick={() => handleTrainingNavigation("private-courses")}>Private Courses</MenuItem>
                        }
                        {settings.reseller.showTrainingRequestForm &&
                            <MenuItem onClick={handleRequestCourse}>Request Course or Topic</MenuItem>
                        }
                        {settings.reseller.hasResources &&
                            <MenuItem onClick={handleTrainingResourcesNavigation}>Training Resources</MenuItem>
                        }
                        {settings.reseller.registerCourseInterest &&
                            <MenuItem onClick={handleRegisterCoursesNavigation}>Register Course Interest</MenuItem>
                        }
                    </Menu>
                </>
            }
            <div className={classes.xSpacer} />
        </>
    )
    
    const loginButton = (
        location.pathname !== '/login' && (
            member == null ? (
                <Button variant="contained" color="primary" onClick={handleLogin}>LOGIN</Button>
            ) : (
                <Button variant="contained" color="primary" onClick={handleLogout}>Sign Out</Button>
            )
        )
    )

    return (
        <AppBar position="relative" className={classes.appBar} >            
            {/* Mobile toolbar */}
            <Hidden smUp={true}>
                <Toolbar>
                    {resellerLogo}
                    <IconButton onClick={showMobileMenu ? handleMobileMenuClose : handleMobileMenu}>
                        {showMobileMenu ? <CloseIcon/> : <MenuIcon />}
                    </IconButton>
                </Toolbar>
                <Drawer 
                    anchor="top"
                    open={showMobileMenu}
                    onClose={handleMobileMenuClose} >
                    <Box className={classes.mobileMenu}>
                        <br /><br /><br />
                        {trainingMenu}
                        <Divider />
                        {accountMenu}
                        <div style={{ paddingTop: 8 }} />
                        <Box display="flex" flexDirection="row">
                            {shoppingCart}
                            {addContact}
                            <div className={classes.xSpacer} />
                            {loginButton}
                        </Box>
                        {countrySelector}
                    </Box>
                </Drawer>
            </Hidden>
            {/* Desktop toolbars */}
            <Hidden xsDown={true}>
                <Toolbar className={classes.toolbar1}>
                    {addContact}
                    {shoppingCart}
                    {accountMenu}
                    {countrySelector}
                </Toolbar>
                <Toolbar>
                    {resellerLogo}
                    <div className={classes.xSpacer} />
                    {ecLogo}                   
                    <div className={classes.xSpacer} />
                    {accelebrateLogo}                   
                    <div className={classes.xSpacer} />
                    {webagesolutionsLogo}
                    <div className={classes.xSpacer} />
                    {resourceMenu}
                    <div className={classes.xSpacer} />
                    {trainingMenu}
                    {loginButton}
                </Toolbar>
            </Hidden>
        </AppBar>
    )
}

export default TopAppBar
