import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { fetchPaymentInfo } from 'slices/paymentInfoSlice'
import { Appearance, Stripe, StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CreditCardPaymentForm from 'components/cart/payment/CreditCardPaymentForm'
import theme from 'styles'

interface StripeFormWrapperProps {
}

const StripeFormWrapper: React.FC<StripeFormWrapperProps> = (props) => {
    const dispatch = useThunkDispatch()
    const [clientSecret, setClientSecret] = useState("")
    const [stripePromise, setStripePromise] = useState<Stripe | PromiseLike<Stripe | null> | null>(null)
    const paymentInfo = useSelector(
        (state: RootState) => state.paymentInfo
    )

    useEffect(() => {
        console.log("Fetching payment info...")
        dispatch(fetchPaymentInfo())
    }, [dispatch])

    useEffect(() => {
        if (paymentInfo) {
            console.log("Initializing Stripe...")

            const stripePromise = loadStripe(paymentInfo.publishableStripeKey)
            setStripePromise(stripePromise)

            console.log("Stripe Key",paymentInfo.publishableStripeKey)
            
            setClientSecret(paymentInfo.paymentIntentSecret)
        }
    }, [paymentInfo])

    const appearance: Appearance = {
        theme: 'stripe',
        variables: {
            borderRadius: '0',
            colorBackground: "#f2f4f5",
            fontFamily: '"Roboto", sans-serif',
            spacingUnit: '3px',
            gridRowSpacing:'33px',
            gridColumnSpacing: '30px',
            colorPrimary: theme.palette.primary.main
        },
        rules: {
            '.Label': {
                color: '#757575',
                fontSize: '12.3px',
                fontWeight: '400',
                marginBottom: '8px'
            },
            '.Input': {
                borderColor: 'transparent',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '12px',
                paddingRight: '12px',
            },
            '.Input--invalid': {
                color:'#000000',
                borderColor: 'transparent',
                boxShadow: '0px',
            },
            '.Input:hover': {
                borderBottomColor: '#000000',
            },
            '.Input:focus': {
                borderBottomColor: '#000000',
            },
            '.Error': {
                lineHeight: '1.66',
                color: '#F44336',
                fontSize: '12px'
            }
        }
    }

    const options: StripeElementsOptions = {
        clientSecret,
        appearance:appearance,
        fonts: [
            {
                cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'
            }
        ]
    }

    return (
        <>
        {clientSecret && 
            <Elements options={options} stripe={stripePromise}>
                <CreditCardPaymentForm />
            </Elements>}
        </>
    )
}

export default StripeFormWrapper
