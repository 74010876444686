import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Container, Typography, Button } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { performChangePassword } from 'slices/memberSlice'
import ErrorMessageWidget, { setError } from 'components/widgets/ErrorMessageWidget'
import ECTextField from 'components/widgets/ECTextField'

interface CartProps { }

const ChangePasswordPage: React.FC<CartProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    // console.log("LOCATION.STATE", location.state)


    const handleCurrentPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(event.target.value)
    }

    const handleNewPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value)
    }

    const handleConfirmPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value)
    }

    const handleChangePassword = async () => {
        if (currentPassword.length === 0) {
            dispatch(setError("You must provide the current password"))
            return
        }

        if (newPassword.length < 7) {
            dispatch(setError("Password must be at least 7 characters."))
            return
        }

        let passwordStrength = 0
        if (newPassword.match(/[A-Z]+/) != null) {
            passwordStrength++
        }
        if (newPassword.match(/[a-z]+/) != null) {
            passwordStrength++
        }
        if (newPassword.match(/[0-9]+/) != null) {
            passwordStrength++
        }
        if (newPassword.match(/[!@#$%^&\\*()\-_+={}|[\]\\:;"',.\\/<>?]+/) != null) {
            passwordStrength++
        }     
        console.log("Password strength", newPassword, passwordStrength)

        if (passwordStrength < 2) {
            dispatch(setError("Please increase password strength by adding some of the following characters: uppercase, digits, punctuation"))
            return
        }

        if (newPassword !== confirmPassword) {
            dispatch(setError("New and confirm passwords must match."))
            return
        }

        if (await dispatch(performChangePassword(currentPassword, newPassword))) {
            history.push("/")
        }
    }

    const handleCancelButton = () => {
        history.goBack()
    }

    if (member == null) {
        history.goBack()
        return null
    }

    return (
        <React.Fragment>
            <Box component="main">
                <Container maxWidth='sm' fixed={true} style={{ marginTop: 240, display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                    <Typography align="center" variant="h5" color="secondary" style={{ marginBottom: 48 }}>
                        Change Password
                    </Typography>
                    <ECTextField
                        label="Current Password"
                        type='password'
                        style={{ marginTop: 20 }}
                        onChange={handleCurrentPasswordChanged}
                    />
                    <ECTextField
                        label="New Password"
                        type='password'
                        style={{ marginTop: 20 }}
                        onChange={handleNewPasswordChanged}
                    />
                    <ECTextField
                        label="Confirm Password"
                        type='password'
                        style={{ marginTop: 20 }}
                        onChange={handleConfirmPasswordChanged}
                    />
                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button style={{ marginTop: 20, marginRight: 8, alignSelf: 'flex-end' }} onClick={handleCancelButton}>Cancel</Button>
                        <Button style={{ marginTop: 20, alignSelf: 'flex-end' }} color='primary' variant='contained' onClick={handleChangePassword}>Change Password</Button>
                    </Box>
                </Container>
            </Box>
            <ErrorMessageWidget />
        </React.Fragment>
    )
}

export default ChangePasswordPage
