import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import { setLocalSubmit } from 'slices/cartSubmitSlice'
import { setError } from 'slices/errorSlice'

const LocalAutoSubmitForm: React.FC = () => {
    const dispatch = useThunkDispatch()
    const { submitForm, errors } = useFormikContext()
    const localSubmit = useSelector(
        (state: RootState) => state.cartSubmit.localSubmit
    )

    useEffect(() => {
        if (localSubmit) {
            console.log("Local submit form")
            submitForm()
                .catch(err => { // Formik is broken - will never fire https://github.com/jaredpalmer/formik/issues/1580
                    console.log("*** Local submit form error", err)
                })
                .finally(() => { 
                    console.log("Reset local submit")
                    if (Object.keys(errors).length > 0) {
                        dispatch(setError("Please ensure that all required fields have been filled in."))
                    }
                    dispatch(setLocalSubmit(false))
                })
        }
    }, [dispatch, localSubmit, errors, submitForm])

    return null
}

export default LocalAutoSubmitForm
