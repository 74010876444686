import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import { useStyles } from './CurrencySelectorDialog.styles'
import { CurrencyFilter } from 'models/Settings'

export type CurrencySelectCallback = (value: string) => void

interface CurrencySelectorDialogProps {
    open: boolean
    currency: string
    currencyFilter: CurrencyFilter
    onCurrencyChange: CurrencySelectCallback
}

const CurrencySelectorDialog: React.FC<CurrencySelectorDialogProps> = (props) => {
    const classes = useStyles()
    const { currency, currencyFilter } = props

    const handleCurrencyButton = (currency: string) => {
        props.onCurrencyChange(currency)
    }

    return (
        <Dialog
            open={props.open}
            onClose={e => handleCurrencyButton(currency)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Select Currency</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Select the currency for your shopping cart
                </DialogContentText>
                <Box className={classes.currencyButtonList} display="flex" flexDirection="column">
                    {Object.keys(currencyFilter).map(key =>
                        <Button key={key} className={classes.currencyButton} variant="contained" color="primary" onClick={e => handleCurrencyButton(key)}>{currencyFilter[key]}</Button>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>{/* Just for some spacing */}</DialogActions> 
        </Dialog>
    )
}

export default CurrencySelectorDialog
