import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Container, Typography } from '@material-ui/core'

import { RootState } from 'app/rootReducer'
import AppHeader from 'components/widgets/AppHeader'
import ManageContactsListView from 'components/contacts/ManageContactsListView'

interface ManageContactsProps { }

const ManageContactsPage: React.FC<ManageContactsProps> = (props) => {
    const custom = useSelector(
        (state: RootState) => state.content.custom
    )

    const customContent = custom != null ? custom["ContactPage"] : null

    return (
        <>
            <AppHeader style={(customContent && customContent.backgroundImage)?{backgroundImage:'url('+customContent.backgroundImage+')'}:{}}>
                <Typography style={{textAlign: 'center'}} variant="h3">Manage Students</Typography>
            </AppHeader>
            <Box component="main">
                <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column' }}>
                    <ManageContactsListView />
                </Container>
            </Box>
        </>
    )
}

export default ManageContactsPage
