import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { CourseListParams, CourseListResponse, getCourses } from 'api/coursesApi'
import CourseList from 'models/CourseList'
import { setError } from './errorSlice'

export interface Breadcrumb {
    name: string,
    url: string,
    params: CourseListParams
}

interface CourseListPayload extends CourseListResponse {
    breadcrumbs: Breadcrumb[]
}

export interface CourseListState extends CourseList {
    breadcrumbs: Breadcrumb[]
}

const initialState: CourseListState = {
    courses: [],
    categories: [],
    filters: {
        formats: [],
        locations: [],
        status: undefined
    },
    breadcrumbs: [],
    terms: "",
    categoryId: 0,
    page: 1,
    pageSize: 15,
    count: 0
}

const coursesSlice = createSlice({
    name: 'courseList',
    initialState,
    reducers: {
        setCourses(state, action: PayloadAction<CourseListPayload>) {
            return action.payload
        },
        addCourses(state, action: PayloadAction<CourseListPayload>) {
            return {
                courses: state.courses.concat(action.payload.courses),
                categories: action.payload.categories,
                filters: action.payload.filters,
                categoryId: action.payload.categoryId,
                terms: action.payload.terms,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                count: action.payload.count,
                breadcrumbs: state.breadcrumbs
            }
        },
    }
})

export const { setCourses, addCourses } = coursesSlice.actions

export default coursesSlice.reducer

export const fetchCourses = (params: CourseListParams, breadcrumbs: Breadcrumb[]): AppThunk<Promise<void>> => async dispatch => {
    try {
        const courses = await getCourses(params)
        
        if (params.page && params.page > 1) {
            dispatch(addCourses({ ...courses, breadcrumbs }))
        } else {
            dispatch(setCourses({ ...courses, breadcrumbs }))
        }
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
