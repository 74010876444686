import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { CategoryResponse, getCategories } from 'api/categoriesApi'
import Category from 'models/Category'
import { setError } from './errorSlice'

type CategoriesState = Category[]

const initialState: CategoriesState = []

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategories(state, action: PayloadAction<CategoryResponse[]>) {
            return action.payload
        }
    }
})

export const { setCategories } = categoriesSlice.actions

export default categoriesSlice.reducer

export const fetchCategories = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const categories = await getCategories()
        dispatch(setCategories(categories))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
