import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'

interface ContentDialogProps { 
    open: boolean
    title: string
    message: string
    buttonText?: string
    onButtonClicked?: () => void
}

const defaultProps: ContentDialogProps = {
    open: false,
    title: "",
    message: "",
    buttonText: "Close"
}

const ContentDialog: React.FC<ContentDialogProps> = (props) => {
    const handleButton = () => {
        props.onButtonClicked?.()
    }

    const getMessage = () => {
        return (
            < div dangerouslySetInnerHTML={{ __html: props.message }} />
        )
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            open={props.open}
            onClose={handleButton}
            scroll='paper'
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {getMessage()}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleButton} color="primary">
                    {props.buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ContentDialog.defaultProps = defaultProps

export default ContentDialog
