import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Breadcrumbs, Container, Link, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { RootState } from 'app/rootReducer'

import SearchResults from 'components/training/SearchResults'
import { CourseListState } from 'slices/courseListSlice'

interface CategoryPageProps { }

const CategoryPage: React.FC<CategoryPageProps> = (props) => {
    const [courseList, setCourseList] = useState<CourseListState | undefined>(undefined) 
    const history = useHistory()
    const custom = useSelector(
        (state: RootState) => state.content.custom
    )

    useEffect(() => {
        console.log("HISTORY", history)
    }, [history])

    const handleBreadcrumbClick = (index: number) => {
        if (courseList) {
            const breadcrumbs = courseList.breadcrumbs.slice(0, index + 1)
            console.log(breadcrumbs)
            history.push(breadcrumbs[index].url, [breadcrumbs[index].params, breadcrumbs[index].name, breadcrumbs])
        }
    }

    const handleOnCourseList = (courseList: CourseListState) => {
        setCourseList(courseList)
    }

    const customContent = custom != null ? custom["CategoryPage"] : null

    return (
        <>
            <Container maxWidth="lg" fixed={true}>
                <Box style={{ marginTop: 20 }}>
                    {courseList && 
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            {courseList.breadcrumbs.length === 1 ?
                                <Typography variant="body1" key={courseList.breadcrumbs[0].name}>{courseList.breadcrumbs[0].name}</Typography>
                                :
                                courseList.breadcrumbs.map((crumb, index) =>
                                    index !== (courseList.breadcrumbs.length-1) ?
                                        <Link color="inherit" key={crumb.name} onClick={() => handleBreadcrumbClick(index)}>{crumb.name}</Link>
                                        :
                                        <Typography variant="body1" key={crumb.name}>{crumb.name}</Typography>
                                )
                            }
                        </Breadcrumbs>
                    }
                    {custom != null && ( // custom is null until load attempt has completed. Don't render anything until we know for sure whether we have content
                        customContent && customContent.header ? (
                            < div dangerouslySetInnerHTML={{ __html: customContent.header }} />
                        ) : (
                            <>
                                <Typography variant="h2" color='primary'>Explore Our IT Training Courses</Typography>
                                <Typography variant="body1" style={{ color: 'inherit' }}>
                                        Booking a course offers more than just training. Here, you're getting authorized content from the world's leading IT vendors. Award-winning instructors and superior training ensure that you'll leave with the right skills to benefit your organization — and your career.
                                </Typography>
                            </>
                        )
                    )}
                </Box>
            </Container>
            <SearchResults onCourseList={handleOnCourseList}/>
        </>
    )
}

export default CategoryPage
