import { AppThunk } from 'app/store'
import { setError } from 'slices/errorSlice'
import RestUtils from 'utils/RestUtils'

export interface CourseRequestParams {
    firstName: string
    lastName: string
    email: string
    type: string
    request: string
    comments: string
}

export async function postCourseRequest(params: CourseRequestParams): Promise<void> {
    const url = "/training/trainingrequest"

    const { data } = await RestUtils.post<void>(url, params)
    return data
}

export const sendCourseRequest = (params: CourseRequestParams): AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await postCourseRequest(params)
        return true
    } catch (err) {
        dispatch(setError(err.toString()))
        return false
    }
}