import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Hidden } from '@material-ui/core'

import Category from 'models/Category'
import CategoryCard from './CategoryCard'
import Carousel from 'components/widgets/Carousel'

interface CategoryCardProps {
    categories: Category[]
}

const BrandGrid: React.FC<CategoryCardProps> = (props) => {
    const history = useHistory()
    const { categories } = props

    const handleCategory = (category: Category) => {
        history.push(category.url, [{ categoryID: category.id }, category.name, [{
            name: category.name,
            url: category.url,
            params: { categoryID: category.id }
        }]])
    }

    const items = categories.map(category => (
        <Grid key={category.id} item xs={12} sm={3} md={2}>
            <CategoryCard category={category} onClick={() => handleCategory(category)} />
        </Grid>
    ))

    return (
        <>
            <Hidden xsDown>
                <Grid container justify="center" spacing={3} style={{ marginBottom: 24 }}>
                    {items}
                </Grid>
            </Hidden>
            <Hidden smUp>
                <Carousel items={items} />
            </Hidden>
        </>
    )
}

export default BrandGrid
