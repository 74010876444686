import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import baseTheme from "styles/baseTheme"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    faderContainer: {
        position: 'relative',
        overflow: 'hidden',
        "&.inactive .fader": {
            display: 'none'
        },
        "& a": {
            color: baseTheme.palette.primary.main,
            textDecoration: 'none',
            transition: 'color 300ms ease',
            "&:hover": {
                color: baseTheme.palette.primary.dark
            },
            "&:active": {
                color: baseTheme.palette.primary.dark
            }
        }
    },
    faderInactive: {
        display: 'none'
    },
    fader: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100px',
        zIndex: 2,
        background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)"
    }
}))
