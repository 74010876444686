import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { ContentResponse, getContent, ContentPageResponse, getContentPages } from 'api/contentApi'
import Content from 'models/Content'
import { setError } from './errorSlice'
import ContentPage from 'models/ContentPage'

interface ContentState {
    custom?: Content
    pages: ContentPage[]
}

const initialState: ContentState = {
    custom: undefined,
    pages: []
}

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        setContent(state, action: PayloadAction<ContentResponse>) {
            state.custom = action.payload
        },
        setContentPages(state, action: PayloadAction<ContentPageResponse[]>) {
            state.pages = action.payload
        },
    }
})

export const { setContent, setContentPages } = contentSlice.actions

export default contentSlice.reducer

export const fetchContent = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const content = await getContent()
        dispatch(setContent(content))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchContentPages = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const contentPages = await getContentPages()
        dispatch(setContentPages(contentPages))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
