import React from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

import config from 'config/Config';

let gaInitialized = false;

if (!gaInitialized && config.GoogleAnalyticsTrackingId) {
    console.log("Initializing GA " + config.GoogleAnalyticsTrackingId)
    ReactGA.initialize(config.GoogleAnalyticsTrackingId)
    gaInitialized = true
}

export interface GoogleAnalyticsWidgetProps {}

const GoogleAnalyticsWidget: React.FC<GoogleAnalyticsWidgetProps> = (props) => {

    const location = useLocation()

    if (gaInitialized) {
        ReactGA.pageview(location.pathname)
    }

    return null
}

export default GoogleAnalyticsWidget