import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Container, List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles, createStyles, Theme  } from '@material-ui/core'
import DocumentIcon from '@material-ui/icons/Description'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { fetchSFReportList } from 'slices/reportsSlice'

const useStyles = makeStyles((theme: Theme) => createStyles({
    list: {
        '& .MuiListItem-root': {
            '& span': {
                fontSize: '1.25rem'
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: 48
        }
    }
}))

interface SFReportListPageProps { }

const SFReportListPage: React.FC<SFReportListPageProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const history = useHistory()

    const custom = useSelector(
        (state: RootState) => state.content.custom
    )
   const reports = useSelector(
        (state: RootState) => state.reports.reportList
    )
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const [loading, setLoading] = useState(false)

    const hasReports = member?.canHazSfReports ?? false

    useEffect(() => {
        const loadAsync = async () => {
            setLoading(true)
            await dispatch(fetchSFReportList())
            setLoading(false)
        }
        if (hasReports) {
            loadAsync()
        }
    }, [dispatch, hasReports])

    const handleReportClick = (reportId: number) => {
        history.push(`/reports/${reportId}`)
    }

    const handleTransactionReportClick = () => {
        history.push("/reports/transactions")
    }

    const customContent = custom != null ? custom["ReportingPage"] : null

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                {custom != null && ( // custom is null until load attempt has completed. Don't render anything until we know for sure whether we have content
                    customContent && customContent.header && (
                        <Box dangerouslySetInnerHTML={{ __html: customContent.header }} />
                    )
                )}
                <Typography style={{ textAlign: 'center' }} color="primary" variant="h3">Available Reports</Typography>
                {loading ? (
                    <LoadingWidget />
                ) : (
                        <List className={classes.list} >
                        { member?.isAdmin && 
                            <ListItem button onClick={handleTransactionReportClick}>
                                <ListItemIcon>
                                    <DocumentIcon fontSize="large" color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Transaction Report" />
                            </ListItem>
                        }
                        {member?.canHazSfReports && reports.map(report =>
                            <ListItem button key={report.id} onClick={e => handleReportClick(report.id)}>
                                <ListItemIcon>
                                    <DocumentIcon fontSize="large" color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary={report.name} />
                            </ListItem>
                        )}
                    </List>
                )}
                {custom != null && (
                    customContent && customContent.footer && <Box dangerouslySetInnerHTML={{ __html: customContent.footer }} />
                )}
            </Container>
        </Box>
    )
}

export default SFReportListPage
