import React, { useState, KeyboardEvent } from 'react'
import { useSelector } from 'react-redux';
import { Box, TextField, IconButton, Typography } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import MoneyIcon from '@material-ui/icons/MonetizationOnOutlined'
import DeleteIcon from '@material-ui/icons/HighlightOff'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer';
import { validatePromoCode, removePromoCode } from 'slices/orderSlice'
import OrderItem from 'models/OrderItem'
import { setError } from 'slices/errorSlice'

interface PromoCodeFieldProps {
    style?: object
    label: string
    item: OrderItem
    onPromoCodeStatus: (status: boolean) => void 
}

const PromoCodeField: React.FC<PromoCodeFieldProps> = (props) => {
    const dispatch = useThunkDispatch()
    const [promoCode, setPromoCode] = useState("")
    const [promoCodeError, setPromoCodeError] = useState<string | undefined>()
    const error = useSelector(
        (state: RootState) => state.error
    )

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setPromoCodeError(undefined)
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        handleValidatePromoCode()
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const promoCode = event.target.value
        setPromoCode(promoCode)
        setPromoCodeError(undefined)
        dispatch(setError(null))
        props.onPromoCodeStatus(promoCode.length === 0) // true if not validated yet
    }

    const handleValidatePromoCode = async () => {
        if (promoCode && promoCode.length >= 3) {
            const result = await dispatch(validatePromoCode(props.item, promoCode))
            setPromoCode("")
            props.onPromoCodeStatus(result)
        } else if (promoCode.length > 0) {
            setPromoCodeError("Promo code must be at least 3 characters")
            props.onPromoCodeStatus(false)
        } else {
            props.onPromoCodeStatus(true)
        }
    }

    const handleRemovePromoCode = () => {
        dispatch(removePromoCode(props.item))
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleValidatePromoCode()
        }
    }

    return (
        props.item.promoCode ?
            <Box style={{ marginTop: 20}}>
                <Typography component="p" variant="overline">{"Promo Code".toUpperCase()}</Typography>
                <Box display="flex">
                    <Typography variant="body1" color="primary">{props.item.promoCode}</Typography>
                    &nbsp;
                    <DeleteIcon color="action" onClick={handleRemovePromoCode} />
                </Box>
            </Box>
            :
            <TextField
                id="outlined-promoCode"
                label={props.label}
                helperText={promoCodeError ? promoCodeError : error ? error : ""}
                type="promoCode"
                style={props.style}
                margin="normal"
                variant="outlined"
                fullWidth={true}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                error={!!promoCodeError || !!error}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="promoCode"
                            onClick={handleValidatePromoCode}
                        >
                            <MoneyIcon />
                        </IconButton>
                    </InputAdornment>
                }}
            />
    )
}

export default PromoCodeField
