import RestUtils from 'utils/RestUtils';
import Course from 'models/Course';
import Addon from 'models/Addon';

export interface AddonResponse extends Course{ 
}

export interface AddonOrderParams {
    courseID?: number
}

export async function getAddons(courseId?: number): Promise<Addon> {
    const endPoint = "training/addons";
    const { data } = await RestUtils.get<Addon>(endPoint, { courseID: courseId });
    return data;
}