import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

interface CustomTheme {
    toolbarText: string,
    toolbarBackground: string,
    toolbarHoverColor: string,
    buttonColorStart: string,
    buttonColorEnd: string,
    checkoutBarBackground: string,
    checkoutBarAccent: string,
    checkoutContentBoxText: string,
    checkoutLabelText: string,
    checkoutButtonLabelText: string
    headerColor: string
    headerTitleColor: string
    headerTextColor: string
    footerColor: string
    footerTextColor: string
    footerBorderColor: string
    contentFooterColor: string
    contentFooterTextColor: string
}

interface Config {
    Theme?: ThemeOptions,
    CustomTheme?: CustomTheme
    API_BASE?: string,
    BaseName?: string,
    ShowECLogo?: boolean,
    ShowWebAgeSolutionsLogo?: boolean,
    ShowAccelebrateLogo?: boolean,
    HasPrivateEvents?: boolean,
    HasBundles?: boolean,
    ResellerName?: string
    ResellerLogo?: string,
    GoogleAnalyticsTrackingId?: string,
    ShowRegionFlag: boolean,
    Env?: string
}

declare global {
    interface Window {
        _resellerConfig?: Config;
    }
}

let config: Config = {
    API_BASE: process.env.REACT_APP_API_BASE,
    BaseName: "/",
    Env: "dev",
    ShowRegionFlag: true,
    ShowECLogo: true,
    ShowWebAgeSolutionsLogo: false,
    ShowAccelebrateLogo: false
    //GoogleAnalyticsTrackingId:"UA-16372093-6",
};

if (!!window._resellerConfig) {
    config = { ...config, ...window._resellerConfig };
}

console.log("Config Loaded", config);

export default config;