import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
    MuiPaper: {
        // background: 'transparent',
        backgroundColor: theme.palette.background.default,
        // borderRadius: 16,
        border: '1px solid',
        borderColor: theme.palette.grey[400],
        boxShadow: 'none'
    }
}))

