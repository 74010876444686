import React from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Typography} from '@material-ui/core'

import { useStyles } from './TransactionReport.styles'
import Transaction from 'models/Transaction'
import { formatCurrency } from 'utils/currency'

type ColumnId = 'id' | 'orderId' | 'orderDate' | 'registeredInfo' | 'paymentType' | 'subTotal' | 'tax' | 'grandTotal' | 'currency' | 'lineItemCount'
type RegInfo = 'firstName' | 'lastName' | 'email' | 'team' 

interface Column {
    id: ColumnId
    child?: RegInfo
    label: string
    minWidth?: number
    align?: 'right' | 'center'
    format?: (value: number, currency: string) => string
}

const columns: Column[] = [
    { id: 'id', label: 'ID' },
    { 
        id: 'orderId', 
        label: 'Order ID' },
    {
        id: 'orderDate',
        label: 'Order Date',
        minWidth: 170,
    },
    {
        id: 'registeredInfo',
        child: 'firstName',
        label: 'First Name',
        minWidth: 110,
    },
    {
        id: 'registeredInfo',
        child: 'lastName',
        label: 'Last Name',
        minWidth: 110,
    },
    {
        id: 'registeredInfo',
        child: 'team',
        label: 'Team',
    },
    {
        id: 'registeredInfo',
        child: 'email',
        label: 'Email',
    },
    {
        id: 'paymentType',
        label: 'Payment Type',
        minWidth: 130,
        align: 'right',
    },
    {
        id: 'subTotal',
        label: 'Sub Total',
        align: 'right',
        format: (value: number, currency: string) => formatCurrency(value, currency),
    },
    {
        id: 'tax',
        label: 'Tax',
        align: 'right',
        format: (value: number, currency: string) => formatCurrency(value, currency),
    },
    {
        id: 'grandTotal',
        label: 'Grand Total',
        align: 'right',
        format: (value: number, currency: string) => formatCurrency(value, currency),
    },
    {
        id: 'currency',
        label: 'Currency',
        align: 'right',
    },
    {
        id: 'lineItemCount',
        label: 'Line Item Count',
        align: 'center',
    },
]

interface TransactionReportProps {
    transactionCount: number
    transactions: Transaction[]
    page: number
    onTransactionClick: (transactionId: number) => void
    onPageChanged: (page: number) => void
    skipTeam?: boolean
    customTeamLabel: string
}

const TransactionReport: React.FC<TransactionReportProps> = (props) => {
    const classes = useStyles()
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const { page, transactionCount, transactions, skipTeam, customTeamLabel } = props

    const handleTransactionClick = (transactionId: number) => {
        props.onTransactionClick(transactionId)
    }
    
    const handleChangePage = (event: unknown, newPage: number) => {
        props.onPageChanged(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        props.onPageChanged(0)
    }

    const skipTeamInfo = (column: Column) => {
        if (skipTeam && column.id === 'registeredInfo' && column.child === 'team') {
            return false
        }
        return true
    }

    if (transactions.length === 0) {
        return (
            <React.Fragment>
                <Typography align="center">
                    None Found
                </Typography>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <TableContainer className={classes.container}>
                <Table size="small">
                    <TableHead className={classes.tableHeaders}>
                        <TableRow>
                            {columns.filter(column => {
                                return skipTeamInfo(column)
                            }).map(column => {
                                const label = column.id === 'registeredInfo' && column.child === 'team' ? customTeamLabel : column.label
                                return (
                                    <TableCell
                                        key={column.id+column.child}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth ?? 'auto' }}
                                    >
                                        {label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                         {/* slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                        {transactions.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={e => handleTransactionClick(row.id)}>
                                    {columns.filter(column => {
                                        return skipTeamInfo(column)
                                    }).map(column => {                                      
                                        const value = column.id === 'registeredInfo' && column.child != null ? row.registeredInfo[column.child] : row[column.id]
                                        return (
                                            <TableCell key={column.id+column.child} align={column.align} style={{whiteSpace: "nowrap"}}>
                                                {column.format && typeof value === 'number' ? column.format(value, row.currency) : value}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={transactionCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </React.Fragment>
    )
}

export default TransactionReport
