import RestUtils from 'utils/RestUtils'
import Member from 'models/Member'
import PaymentInfo from 'models/PaymentInfo'
import NotificationContact from 'models/NotificationContact'

export interface LoginResponse extends Member { }
export interface LoginParams {
    Email: string
    Password: string
}

export async function getMembers(): Promise<LoginResponse> {
    const url = "/members"

    const { data } = await RestUtils.get<LoginResponse>(url)

    return data
}

export async function login(params: LoginParams): Promise<LoginResponse> {
    const url = "/members/login"

    const { data } = await RestUtils.post<LoginResponse>(url, params)

    return data
}

export async function logout(): Promise<void> {
    const url = "/members/logout"

    await RestUtils.post<void>(url)
}

export async function changePassword(currentPassword: string, newPassword: string): Promise<void> {
    const url = "/members/password"

    const { data } = await RestUtils.post<void>(url, { CurrentPassword: currentPassword, NewPassword: newPassword})

    return data
}

export interface PaymentInfoResponse extends PaymentInfo {}

export async function getPaymentInfo(): Promise<PaymentInfoResponse> {
    const url = "/members/paymentinfo"

    const { data } = await RestUtils.get<PaymentInfoResponse>(url)

    return data
}

export interface NotificationContactsResponse extends NotificationContact {}
export interface NotificationContactsRequest extends NotificationContact {}

export async function getNotificationContacts(): Promise<NotificationContactsResponse[]> {
    const url = "/members/notificationcontacts"

    const { data } = await RestUtils.get<NotificationContactsResponse[]>(url)

    return data
}

export async function postNotificationContacts(contacts: NotificationContactsRequest[]): Promise<NotificationContactsResponse[]> {
    const url = "/members/notificationcontacts"

    const { data } = await RestUtils.post<NotificationContactsResponse[]>(url, {NotificationContacts: contacts })

    return data
}
