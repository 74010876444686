import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    searchBox: {
        '& .MuiInputLabel-root': {
            fontSize: '1.4rem',
            '@media(max-width: 599px)': {
                left: 'inherit',
                transform: 'inherit'
            }
        }
    },
    createButton: {
        width: "100%",
    },
}))
