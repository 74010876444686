import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginTop: 40
    },
    divider: {
        marginTop: 10,
        marginBottom: 30
    },
    buttonBar: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 40
    }
}))
