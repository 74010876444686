import RestUtils from 'utils/RestUtils'
import Category from 'models/Category'

export interface CategoryResponse extends Category {}

export async function getCategories(): Promise<CategoryResponse[]> {
    const endPoint = "/training/categories"

    const { data } = await RestUtils.get<CategoryResponse[]>(endPoint)
    return data
}
