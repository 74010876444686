import React from 'react'
import { Grid, MenuItem, Typography, Hidden, Box } from '@material-ui/core'

import { useStyles } from './ContactForm.styles'
import FormTextField from 'components/widgets/form/FormTextField'
import FormSelect from 'components/widgets/form/FormSelect'

interface ContactDetailsProps {
    name?: string
    prefix: string,
    showPosition: boolean,
    showPhone: boolean,
    showEmail: boolean,
    addressType?: string,
    isMe: boolean
}

const ContactDetails: React.FC<ContactDetailsProps> = (props) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            { props.name && 
                <Box className={classes.title} display="flex" flexDirection="row">
                    <Typography variant="h6" color="primary">{props.name}</Typography>
                </Box>
            }
            <Grid container spacing={4}>
                {props.addressType != null &&
                    <React.Fragment>
                        <Grid item xs={12} sm={4}>
                            <FormSelect
                                id={`${props.prefix}.addressType`}
                                label="Address Type"
                                name="shipping.addressType"
                                value={props.addressType}
                            >
                                <MenuItem value="business">Business</MenuItem>
                                <MenuItem value="home">Home</MenuItem>
                            </FormSelect>
                        </Grid>
                        <Hidden xsDown>
                            <Grid item sm={4}>
                                {/* Nothing goes here */}
                            </Grid>
                            <Grid item sm={4}>
                                {/* Nothing goes here */}
                            </Grid>
                        </Hidden>
                    </React.Fragment>
                }
                <Grid item xs={12} sm={4}>
                    <FormTextField label="First Name" name={`${props.prefix}.firstName`} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormTextField label="Last Name" name={`${props.prefix}.lastName`} />
                </Grid>
                {props.showEmail && 
                    <Grid item xs={12} sm={4}>
                        <FormTextField label="Email" name={`${props.prefix}.email`} disabled={props.isMe} />
                    </Grid>
                }
                {(props.addressType == null || props.addressType === "business") &&
                    <Grid item xs={12} sm={4}>
                            <FormTextField label="Company" name={`${props.prefix}.company`} />
                    </Grid>
                }
                {props.showPosition &&
                    <Grid item xs={12} sm={4}>
                            <FormTextField label="Position" name={`${props.prefix}.position`} />
                    </Grid>
                }
                {props.showPhone &&
                    <Grid item xs={12} sm={4}>
                            <FormTextField label="Phone" name={`${props.prefix}.phone`} />
                    </Grid>
                }
            </Grid>
        </React.Fragment>
    )
}

export default ContactDetails
