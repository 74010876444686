import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Container, Typography } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { fetchPrivateCourses } from 'slices/privateCoursesSlice'
import AppHeader from 'components/widgets/AppHeader'
import CompactCourseListView from 'components/courses/CompactCourseListView'
import CourseSummary from 'models/CourseSummary'

interface PrivateCoursePageProps { }

const PrivateCoursePage: React.FC<PrivateCoursePageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const [loadingCourses, setLoadingCourses] = useState(false)

    const courses = useSelector(
        (state: RootState) => state.privateCourses.courses
    )

    const custom = useSelector(
        (state: RootState) => state.content.custom
    )

    const customContent = custom != null ? custom["PrivateEventsPage"] : null

    useEffect(() => {
        const loadAsync = async () => {
            setLoadingCourses(true)
            await dispatch(fetchPrivateCourses())
            setLoadingCourses(false)
        }
        loadAsync()
    }, [dispatch])

    const handleEnrollCourse = (course: CourseSummary) => {
        history.push(`/it-training/private-events/${course.id}`)
    }

    return (
        <>
            <AppHeader style={(customContent && customContent.backgroundImage)?{backgroundImage:'url('+customContent.backgroundImage+')'}:{}}>
                <Typography style={{ textAlign: 'center' }} variant="h3">Private Courses</Typography>
            </AppHeader>
            <Box component="main">
                <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                    {loadingCourses ? (
                        <LoadingWidget />
                    ) : (
                        <CompactCourseListView courses={courses} courseCount={courses.length} courseAction="Enroll" onCourseClick={handleEnrollCourse} />
                    )}
                </Container>
            </Box>
        </>
    )
}

export default PrivateCoursePage
