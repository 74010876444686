import React from 'react'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'

import { useStyles } from './ContactCard.styles'
import Contact from 'models/Contact'

interface ContactCardProps {
    contact: Contact
    onEditContact: (cotact: Contact) => void
    onDeleteContact: (contact: Contact) => void
}

const ContactCard: React.FC<ContactCardProps> = (props) => {
    const classes = useStyles()

    const handleEditButton = () => {
        props.onEditContact(props.contact)
    }

    const handleDeleteButton = () => {
        props.onDeleteContact(props.contact)
    }

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.nameText} variant="body1">{props.contact.lastName}, {props.contact.firstName}</Typography>
                        <Typography className={classes.emailText} variant="body2" >{props.contact.email}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.action}>
                        <PersonIcon className={classes.icon} color="primary" fontSize="large" />
                        <Button style={{ paddingLeft: 0 }} onClick={handleEditButton}>Edit</Button>
                        {!props.contact.isMe &&
                            <Button onClick={handleDeleteButton}>Delete</Button>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ContactCard
