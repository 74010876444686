import React from 'react'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'

import CourseSummary from 'models/CourseSummary'

interface StandaloneCourseCardProps {
    course: CourseSummary
    onSelectClick: (course: CourseSummary) => void
}

const StandaloneCourseCard: React.FC<StandaloneCourseCardProps> = (props) => {
    const { course } = props
    
    const handleSelectCourse = (course: CourseSummary) => {
        props.onSelectClick(course)
    }

    return (
        <Card style={{ marginTop: 30 }}>
            <CardContent>
                <Grid container alignItems='flex-start'>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6">{`${course.name} (${course.code})`}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardContent>
                <Grid container alignItems='flex-start'>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="overline">Code:</Typography>
                        <Typography>{course.code}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="overline">Type:</Typography>
                        <Typography>{course.formats[0].name}</Typography>
                    </Grid>
                    <Grid item sm={4}></Grid>
                    <Grid item xs={12} sm={2} style={{ alignSelf: "center" }}>
                        <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={e => handleSelectCourse(course)}>Select</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default StandaloneCourseCard
