import React from 'react'
import { Box, IconButton, Button, Popover, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TableSortLabel, useTheme } from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

import { useStyles } from './SFReportTable.styles'
import SFReportData, { SFReportHeader, SFReportAction } from 'models/SFReportData'
import { formatCurrency } from 'utils/currency'
import DocumentListView from 'components/documents/DocumentListView'
import ReportDocument from 'models/ReportDocument'
import { downloadDocument } from 'api/reportsApi'

export type SortOrder = 'asc' | 'desc'

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>
    onSortColumn: (event: React.MouseEvent<unknown>, property: string) => void
    headers: SFReportHeader[],
    orderBy: string
    order: SortOrder
    rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, headers, order, orderBy, onSortColumn } = props
    
    const handleSortClick = (property: string) => (event: React.MouseEvent<unknown>) => {
        onSortColumn(event, property)
    }

    return (
        <TableHead className={classes.tableHeaders}>
            <TableRow>
                {headers.map((header, h_index) =>
                    <TableCell 
                        key={h_index}
                        sortDirection={orderBy === header.name ? order : false} // Don't need to check is header sortable here as it will never be sorted by this column
                    >
                        {header.sortable ?
                            <TableSortLabel
                                active={orderBy === header.name}
                                direction={orderBy === header.name ? order : 'asc'}
                                onClick={handleSortClick(header.name)}
                            >
                                <Typography variant="body2">{header.name}</Typography>
                            </TableSortLabel>
                        :
                            <Typography variant="body2">{header.name}</Typography>
                        }

                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    )
}

interface TablePaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles()
    const theme = useTheme()
    const { count, page, rowsPerPage, onChangePage } = props

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0)
    }

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1)
    }

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <div className={classes.tablePaginationActions}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}

interface SFReportTableProps {
    reportData: SFReportData
    page: number
    limit: number
    orderBy: string,
    order: SortOrder
    onPageChange: (page: number) => void
    onLimitChange: (limit: number) => void
    onSortChange: (orderBy: string, order: SortOrder) => void
}

const SFReportTable: React.FC<SFReportTableProps> = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const { reportData, page, limit, orderBy, order } = props
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleDocumentClick = async (documentId: string) => {
        handlePopoverClose()
        const response = await downloadDocument(documentId)
        const fileName = response.headers['content-disposition'].split('filename="')[1].split('"')[0]
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
        a.remove()
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        props.onPageChange(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onPageChange(0)
        props.onLimitChange(parseInt(event.target.value, 10))
    }

    const handleSortColumn = (event: React.MouseEvent<unknown>, column: string) => {
        const isAsc = orderBy === column && order === 'asc'
        props.onSortChange(column, isAsc ? 'desc' : 'asc')
    }

    const grouped: any[] = []

    const renderData = (data: any, header: SFReportHeader, index: number): string | null => {
        if (header.grouped) {
            if (grouped[index] !== data) {               
                grouped[index] = data
                for (let clearIndex = index + 1; clearIndex < grouped.length; clearIndex++) {
                    delete grouped[clearIndex]
                }
            } else {
                return null
            }
        }

        if (header.lookupData) {
            return header.lookupData[data as string]
        }

        switch (header.format) {
            case "YesNo": return data ? "Yes" : "No"
            case "Currency": return formatCurrency(data, "USD")
        }
        
        return data ? data.toString() : null
    }

    const renderAction = (action: SFReportAction, index: number, buttonStyle: string)  => {
        return (
            <React.Fragment key={action.name}>
                <Button id={action.name + index} className={buttonStyle} onClick={handlePopoverOpen} variant="outlined">
                    {action.name}
                </Button>
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    style={{ zIndex: theme.zIndex.modal + 2 }}
                    open={anchorEl && anchorEl.id === action.name + index ? true : false}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    {action.format === "HTML" &&
                        <Box dangerouslySetInnerHTML={{ __html: action.value as string }} />
                    }
                    {action.format === "Documents" &&
                        <DocumentListView documents={action.value as ReportDocument[]} onDocumentClick={handleDocumentClick} />
                    }
                </Popover>
            </React.Fragment>
        )
    }

    function isRowAction(data: any): data is SFReportAction {
        if (data === null) {
            return false
        }
        return (data as SFReportAction).name !== undefined
    }

    return (
        reportData.rowCount === 0 ?
            <Box display="flex" justifyContent="center">
                <Typography variant="h5" color="primary">No Results Found</Typography>
            </Box>
        :
            <React.Fragment>
                <TableContainer>
                    <Table size="small">
                        <EnhancedTableHead
                            classes={classes}
                            headers={reportData.headers}
                            order={order}
                            orderBy={orderBy}
                            onSortColumn={handleSortColumn}
                            rowCount={reportData.rowCount}
                        />
                        <TableBody className={classes.tableBody}>
                            {reportData.data.map((dataResult, dataIndex) =>
                                <React.Fragment key={dataIndex}>
                                    {dataResult.sectionHeader &&
                                        <TableRow>
                                            <TableCell className={classes.sectionHeader} colSpan={reportData.headers.length}>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Box dangerouslySetInnerHTML={{ __html: dataResult.sectionHeader }} />
                                                    <Box display="flex">
                                                        {dataResult.sectionHeaderActions.map((headerAction) =>
                                                            renderAction(headerAction, dataIndex, classes.headerActionButton)
                                                        )}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {dataResult.results.map((row, rowIndex) =>
                                        <TableRow key={rowIndex}>
                                                {row.map((data: any, columnIndex: number) =>
                                                    <TableCell key={columnIndex} style={{ whiteSpace: reportData.headers[columnIndex].noWrap ? "nowrap" : "inherit" }}>
                                                        { isRowAction(data) ?
                                                            renderAction(data, rowIndex, classes.actionButton)
                                                        :
                                                            <Typography variant="body2" align={reportData.headers[columnIndex].alignment}>{renderData(data, reportData.headers[columnIndex], columnIndex)}</Typography>
                                                        }
                                                    </TableCell>
                                                )}
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100, 1000]}
                    component="div"
                    count={reportData.rowCount}
                    rowsPerPage={limit}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </React.Fragment>
    )
}

export default SFReportTable
