import React from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './AppHeader.styles'

export interface AppFooterProps extends React.HTMLAttributes<HTMLElement> { }

const AppHeader: React.FC<AppFooterProps> = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.root} style={props.style}>
            {props.children}
        </Box>
    )
}

export default AppHeader
