import React, { useState } from 'react';
import { Button, List, ListItem, ListItemText, ListItemIcon, Checkbox, Typography, Divider, IconButton, Box } from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'

import { useStyles } from './SubCategoryView.styles'
import Filter from 'models/Filter'

interface FilterViewProps {
    name: string,
    filters: Filter[],
    onFilterClick: (filter: Filter) => void
}

const FilterView: React.FC<FilterViewProps> = (props) => {
    const classes = useStyles()
    const [showMore, setShowMore] = useState(props.filters.length > 5)
    const [open, setOpen] = useState(false)

    const handleArrowClick = () => {
        setOpen(!open)
    }

    const handleFilterClick = (filter: Filter) => {
        props.onFilterClick(filter)
    }

    const handleShowMore = () => {
        setShowMore(!showMore)
    }

    if (props.filters.length === 0) {
        return null
    }
    
    // console.log("SHOWMORE", props.name, showMore, props.filters.length)
    
    return (
        <Box className={classes.panel}>
            <Typography className={classes.title} variant="body1">{props.name}</Typography>
            <IconButton className={classes.downArrow} onClick={handleArrowClick}>
                <ArrowDownIcon
                    color="primary"
                    style={{
                        transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: '0.2s',
                    }}
                />
            </IconButton>
            <Divider />
            {open && (
                <>
                    <List dense component="div" role="list">
                        {props.filters.slice(0, showMore ? 5 : props.filters.length).map(filter => (
                            <ListItem
                                key={filter.name}
                                role="listitem"
                                style={{ cursor: 'pointer' }} 
                                onClick={() => handleFilterClick(filter)}
                                disableGutters
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={filter.selected}
                                        tabIndex={-1}
                                        disableRipple
                                        color='primary'
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${filter.value}` }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={filter.value as string} primary={filter.name} />
                                <Typography>({filter.count})</Typography>
                            </ListItem>
                        ))}
                    </List>
                    { props.filters.length > 5 &&
                        <Button color="primary" style={{paddingLeft: 0, minWidth: 40}} onClick={handleShowMore}>{showMore ? "View More" : "View Less"}</Button>
                    }
                </>
            )}
        </Box>
    )
}

export default FilterView
