import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    panel: {
        position: 'relative',
        backgroundColor: theme.palette.grey[300],
        marginTop: '1rem',
        padding: '0.5rem'
    },
    title: {
        textAlign: 'center'
    },
    downArrow: {
        position: 'absolute',
        right: 0,
        top: -4,
    }
}))
