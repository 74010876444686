import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    datePicker: {
        margin: 0
    },
    verticalSeparator: {
        borderRight: `1px solid ${theme.palette.grey[500]}`
    }
}))
