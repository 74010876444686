import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Container } from '@material-ui/core'

import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'

interface HomePageProps { }

const HomePage: React.FC<HomePageProps> = (props) => {

    const custom = useSelector(
        (state: RootState) => state.content.custom
    )

    const customContent = custom != null ? custom["HomePage"] : null

    return (
        <Box component="main">
            <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column' }}>
                {customContent != null && (
                    customContent && customContent.header ? (
                        <Box dangerouslySetInnerHTML={{ __html: customContent.header }} />
                    ) : (
                        <LoadingWidget />
                    )
                )}
            </Container>
        </Box>
    )
}

export default HomePage
