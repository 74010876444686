import React, { useState } from 'react'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import CreateIcon from '@material-ui/icons/Create'
import clsx from 'clsx'

import { useStyles } from './StudentListView.styles'
import Address from 'models/Address'
import Contact from 'models/Contact'
import OrderItem from 'models/OrderItem'
import Student from 'models/Student'
import { formatCurrency } from 'utils/currency'
import ExtraInfoDialog from './ExtraInfoDialog'
import { useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'

interface StudentListViewProps {
    item: OrderItem 
    onRemoveStudent: (item: OrderItem, contactId: number) => void
    onEditStudent: (item: OrderItem, contact: Contact) => void
    onUpdateStudentExtraInfo: (item: OrderItem, contactId: number, extraInfo: any) => void
}

const StudentListView: React.FC<StudentListViewProps> = (props) => {
    const classes = useStyles()
    const [activeStudent, setActiveStudent] = useState<Student | undefined>(undefined)

    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )
   
    const handleRemoveStudent = (item: OrderItem, contactId: number) => {
        props.onRemoveStudent(item, contactId)
    }

    const handleEditStudent = (item: OrderItem, contact: Contact) => {
        props.onEditStudent(item, contact)
    }

    const handleExtraInfo = (student: Student) => {
        setActiveStudent(student)
    }

    const handleSaveExtraInfo = (item: OrderItem, contactId: number, extraInfo: any) => { 
        setActiveStudent(undefined)      
        props.onUpdateStudentExtraInfo(item, contactId, extraInfo)
    }

    const handleCancelExtraInfo = () => {
        setActiveStudent(undefined)
    }
    
    const formatAddress = (address: Address) => {
        return `${address.street}, ${address.city}, ${address.state ?? address.province ?? address.other}, ${address.zip}, ${address.country}`
    }

    const studentAddress = (contact: Contact) => {
        let address = contact.addresses.shipping ?? contact.addresses.contact

        if (address) {
            return (
                <Typography variant="body2">{formatAddress(address)}</Typography>
            )
        }
    }

    const renderRequirements = (item: OrderItem, student: Student) => {
        if (student.requirements) {
            const requirements = student.requirements
            const needExtraInfo = requirements?.fields.reduce((acc, field) => (field.required && field.value === "") ? acc + 1 : acc, 0)

            return (
                <React.Fragment>
                    <Grid item xs={3}>
                        {requirements.fields.length > 0 && (
                            needExtraInfo > 0 ? (
                                <Button className={clsx(classes.leftCardButton, classes.cardButton, classes.redButton)} onClick={e => handleExtraInfo(student)}>Attention Needed</Button>
                            ): (
                                <Button className={clsx(classes.leftCardButton, classes.cardButton, classes.blueButton)} onClick={e => handleExtraInfo(student)}>Extra info</Button>
                            )
                        )}
                    </Grid>
                    <Grid item xs={5}>
                        {requirements?.addressRequired &&
                            <Button className={clsx(classes.leftCardButton, classes.cardButton, classes.redButton)} onClick={() => handleEditStudent(item, student.contact)}>Address Required</Button>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        {/* Placeholder */}
                    </Grid>
                </React.Fragment>
            )
        }

        return null
    }

    return (
        <React.Fragment>
            <Grid container className={classes.grid}>
                <Grid item sm={4}>
                    <Typography variant='h6'>Student</Typography>
                </Grid>
                <Grid item sm={5}>
                    <Typography variant='h6'>Shipping Address</Typography>
                </Grid>
                {!settings.reseller.hideAllPricing && 
                    <React.Fragment>
                        <Grid item sm={1}>
                            <Typography variant='h6'>Taxes</Typography>
                        </Grid>
                        <Grid item sm={1}>
                            <Typography variant='h6'>Discount</Typography>
                        </Grid>    
                    </React.Fragment>
                }
                <Grid item sm={settings.reseller.hideAllPricing ? 3 : 1}>
                    {/* Placeholder */}
                </Grid>
                { props.item.students.map(student => 
                    <React.Fragment key={student.contact.id}>
                        <Grid item sm={4} className={classes.row}>
                            <Typography style={{fontWeight: 700}}>{student.contact.firstName} {student.contact.lastName}</Typography>
                            <Typography variant="body1">{student.contact.email}</Typography>
                        </Grid>
                        <Grid item sm={5}>
                            {studentAddress(student.contact)}
                        </Grid>
                        {!settings.reseller.hideAllPricing && 
                            <React.Fragment>
                                <Grid item sm={1}>
                                    {student.tax > 0 && (
                                        <React.Fragment>
                                            <Typography variant="overline">{student.taxText}</Typography>
                                            <Typography>{formatCurrency(student.tax, props.item.price.currency)}</Typography>
                                        </React.Fragment>
                                    )}
                                </Grid>
                                <Grid item sm={1}>
                                    {student.discount && student.discount !== 0 && (
                                        <React.Fragment>
                                            <Typography variant="overline">{student.discountText}</Typography>
                                            <Typography>{formatCurrency(student.discount, props.item.price.currency)}</Typography>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item sm={settings.reseller.hideAllPricing ? 3 : 1} style={{ textAlign:"right" }}>
                            <IconButton aria-label="edit" onClick={() => handleEditStudent(props.item, student.contact)}>
                                <CreateIcon />
                            </IconButton>
                            <IconButton aria-label="remove" onClick={() => handleRemoveStudent(props.item, student.contact.id)}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>

                        {student.requirements &&
                            renderRequirements(props.item, student)
                        }
                    </React.Fragment>
                )}
            </Grid>
            { activeStudent &&
                <ExtraInfoDialog
                    open={!!activeStudent}
                    extraInfoFields={activeStudent.requirements?.fields}
                    onCancelClicked={handleCancelExtraInfo}
                    onSaveClicked={values => handleSaveExtraInfo(props.item, activeStudent.contact.id, values)}
                />
            }
        </React.Fragment>
    )
}

export default StudentListView
