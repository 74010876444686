import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import * as Sentry from '@sentry/browser'

import Config from 'config/Config'

const stdConfig: AxiosRequestConfig = {
    baseURL: Config.API_BASE,
    withCredentials: true,
    transformResponse: [
        (data) => {
            if (data) {
                return camelcaseKeys(JSON.parse(data), { deep: true })
            }

            return data
        }
    ]
}

export const noCamelCaseConfig: AxiosRequestConfig = {
    transformResponse: [
        (data) => {
            if (data) {
                return JSON.parse(data)
            }

            return data
        }
    ]
}

export const excludeCamelCaseConfig = (stopPaths: string[]): AxiosRequestConfig => {
    return {
        transformResponse: [
            (data) => {
                if (data) {
                    return camelcaseKeys(JSON.parse(data), { deep: true, stopPaths })
                }

                return data
            }
        ]
    }
}

const get = async <T = any, R = AxiosResponse<T>>(url: string, params?: any, config?: AxiosRequestConfig): Promise<R> => {
    try {
        return await axios.get(url, Object.assign({}, stdConfig, config, { params }))
    } catch (err) {
        throw processError(err)
    }
}

const post = async <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> => {
    try {
        return await axios.post(url, data, Object.assign({}, stdConfig, config))
    } catch (err) {
        throw processError(err)
    }
}

const put = async < T = any, R = AxiosResponse < T >> (url: string, data ?: any, config ?: AxiosRequestConfig): Promise < R > => {
    try {
        return await axios.put(url, data, Object.assign({}, stdConfig, config))
    } catch (err) {
        throw processError(err)
    }
}

const patch = async <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> => {
    try {
        return await axios.patch(url, data, Object.assign({}, stdConfig, config))
    } catch (err) {
        throw processError(err)
    }
}

const deleteOp = async <T = any, R = AxiosResponse < T >> (url: string, params ?: any, config ?: AxiosRequestConfig): Promise < R > => {
    try {
        return await axios.delete(url, Object.assign({}, stdConfig, config, { params }))
    } catch (err) {
        throw processError(err)
    }
}

const processError = (err: any): any => {
    // For API error extract the message from the ApiError payload an use that as the error message
    if (err.response === undefined) {
        Sentry.captureMessage(err.message)
    }
    if (err.response && err.response.status === 422) {
        err.origErrMessage = err.message
        err.message = err.response?.data.message
    }
    return err
}

export default {
    get,
    post,
    put,
    patch,
    delete: deleteOp
}
