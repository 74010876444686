import Course from "models/Course"

export const hasPrice = (course: Course) => {
    // The determining factor is any format price has a zero amount and originalAmount in which case it has NO price
    const hasPrice = course.formats.some((format) => {
        let noPrices:boolean[] = []
        format.prices.map((price,index) => (
            noPrices.push( (!price.originalAmount && price.amount === 0) || (price.amount === 0 && price.originalAmount === 0) )
        ))

        return noPrices.includes(false)
    })

    return hasPrice || course.isFree
}