import React from 'react'
import { useField } from 'formik'
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import Select, { SelectProps as MuiSelectProps } from '@material-ui/core/Select'
import { ECInput } from '../ECTextField'

type SelectProps = MuiSelectProps & {
    name: string
}

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '70vh'
      }
    }
};

const FormSelect: React.FC<SelectProps> = ({
    id,
    label,
    onChange,
    ...props
}) => {
    const [field, meta] = useField(props.name)
    const errorText = meta.error && meta.touched ? meta.error : undefined
    return (
        <FormControl fullWidth={true}>
            { label && <InputLabel shrink id={`${id}.label`}>{label}</InputLabel> }
            <Select
                labelId={`${id}.label`}
                id={id}
                {...field}
                onChange={ (event, child) => {
                    if (onChange) {
                        onChange(event, child)
                    } else {
                        field.onChange(event)
                    }
                }}
                input={<ECInput />}
                MenuProps={MenuProps}
            >
                {props.children}
            </Select>
            {errorText && 
                <FormHelperText error={!!meta.error}>{errorText}</FormHelperText>
            }           
        </FormControl>
    )
}

export default FormSelect
