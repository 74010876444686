import React, { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useFormikContext, Field } from 'formik'
import { Box, Button, Grid, MenuItem, Typography, Checkbox, FormControlLabel } from '@material-ui/core';

import { useThunkDispatch } from 'app/store'
import { useStyles } from './BillingAddressForm.styles'
import { RootState } from 'app/rootReducer'
import { fetchCountries, fetchStatesForCountry } from 'slices/lookupsSlice'
import FormTextField from 'components/widgets/form/FormTextField'
import FormSelect from 'components/widgets/form/FormSelect'
import CountryState from 'models/CountryState'
import { pascalCase } from 'utils/pascalCase'
import { stateFieldForCountry } from 'utils/addressUtils'
import Billing from 'models/Billing'

export interface BillingValues extends Billing {}

interface BillingAddressFormProps { 
    country?: string
    stateProvince?: string
    onUseMyContactInfoButton?: () => void
    saveBillingAddress?: boolean
}

const BillingAddressForm: React.FC<BillingAddressFormProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const { handleChange, setFieldValue } = useFormikContext()

    const member = useSelector(
        (state: RootState) => state.member.member
    )

    const countries = useSelector(
        (state: RootState) => state.lookups.countries
    )
    const statesForCountry = useSelector(
        (state: RootState) => state.lookups.statesForCountry
    )

    useEffect(() => {
        console.log("Loading countries...")
        dispatch(fetchCountries())
    }, [dispatch])

    useEffect(() => {
        if (props.country != null && props.country.length > 0) {
            console.log("Loading states for", props.country)
            dispatch(fetchStatesForCountry(props.country))
        }
    }, [dispatch, props.country])

    const handleCountryChange = (event: ChangeEvent<any>) => {
        setFieldValue("billing.state", "")

        // Let formik do its thing
        handleChange(event)
    }

    const handleUseMyContactInfoButton = () => {
        props.onUseMyContactInfoButton?.()
    }

    const handleSaveAddressChange = (event: ChangeEvent<any>) => {
        if (event.target.checked) {
            setFieldValue("saveBillingAddress", true)
        } else {
            setFieldValue("saveBillingAddress", false)
        }

        handleChange(event)
    }

    const stateField = stateFieldForCountry(props.country)
    const states: CountryState = props.country ? (statesForCountry[props.country] ? statesForCountry[props.country] : {}) : {}

    // console.log("PROPS", props)

    return (
        <React.Fragment>
            <Box className={classes.section}>
                <Box display="flex" flexDirection="row">
                    <Typography variant="h6">Billing Address</Typography>
                    {props.onUseMyContactInfoButton != null &&
                        <Button className={classes.useMyContactButon} color="primary" onClick={handleUseMyContactInfoButton}>Use My Contact Info</Button>
                    }
                </Box>
                <Grid container spacing={4} className={classes.grid}>
                    <Grid item xs={4}>
                        <FormTextField
                            name="billing.firstName"
                            label="First Name"
                            placeholder="First Name"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            name="billing.lastName"
                            label="Last Name"
                            placeholder="Last Name"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {/* Empty slot */}
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            name="billing.company"
                            label="Company"
                            placeholder="Company"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            name="billing.email"
                            label="Email"
                            placeholder="Email"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            name="billing.phone"
                            label="Phone"
                            placeholder="Phone"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            name="billing.street"
                            label="Address"
                            placeholder="Address"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            name="billing.city"
                            label="City"
                            placeholder="City"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {Object.keys(states).length > 0 &&
                            <FormSelect
                                id={`billing.${stateField}`}
                                placeholder={pascalCase(stateField)}
                                name={`billing.${stateField}`}
                                value={props.stateProvince}
                                label="State / Provence">
                                {Object.keys(states).map(key =>
                                    <MenuItem key={key} value={key}>{states[key]}</MenuItem>
                                )}
                            </FormSelect>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        {Object.keys(countries).length > 0 &&
                            <FormSelect
                                id={"billing.country"}
                                placeholder="Country"
                                label="Country"
                                name={"billing.country"}
                                value={props.country}
                                onChange={handleCountryChange}>
                                {Object.keys(countries).map(key =>
                                    <MenuItem key={key} value={key}>{countries[key]}</MenuItem>
                                )}
                            </FormSelect>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            name="billing.zip"
                            label="Postal/ZIP Code"
                            placeholder="Postal/ZIP Code"
                        />
                    </Grid>
                    { member != null && 
                        <Grid item xs={12}>
                            <FormControlLabel
                                control = {
                                    <Field
                                        name="saveBillingAddress"
                                        type="checkbox"
                                        checked={props.saveBillingAddress}
                                        as={Checkbox}
                                        value="1"
                                        style={{ textAlign: 'center' }}
                                        onChange={handleSaveAddressChange}
                                    />
                                }
                                label = "Save Billing Address for future use?"
                            />
                        </Grid>
                    }
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export default BillingAddressForm
