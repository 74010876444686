import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Checkbox, Grid, IconButton } from '@material-ui/core'
import { Formik, FormikHelpers, Form, Field, FieldArray } from 'formik'
import ClearIcon from '@material-ui/icons/Clear';
import * as yup from 'yup'

import { useThunkDispatch } from 'app/store'
import { useStyles } from './NotificationContactsEditor.styles'
import { RootState } from 'app/rootReducer'
import FormTextField from 'components/widgets/form/FormTextField'
import { fetchNotificationContacts, saveNotificationContacts } from 'slices/memberSlice'
import LoadingWidget from 'components/widgets/LoadingWidget'
import MessageWidget from 'components/widgets/MessageWidget'
import ErrorWidget from 'components/widgets/ErrorMessageWidget'
import LocalAutoSubmitForm from 'components/widgets/form/LocalAutoSubmitForm'
import { setCartNotificationContacts } from 'slices/cartSubmitSlice'
import NotificationContact from 'models/NotificationContact'

interface FormValues {
    notificationContacts: NotificationContact[]
}

const validationSchema = yup.object().shape({
    notificationContacts: yup.array()
        .of(
            yup.object().shape({
                firstName: yup.string().required("First Name is a required field."),
                lastName: yup.string().required("Last Name is a required field."),
                email: yup.string().required("Email is a required field."),
            })
        )
})


interface ManageContactsProps { 
    additional?: boolean
}

const NotificationContactsEditor: React.FC<ManageContactsProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const notificationContacts = useSelector(
        (state: RootState) => state.member.notificationContacts
    )
    const [message, setMessage] = useState(null as string | null)

    const newContact: NotificationContact = {
        firstName: "",
        lastName: "",
        email: "",
        sendSetup: false,
        sendConfirmation: false
    }

    const initialValues = {
        notificationContacts: member != null ? 
            notificationContacts != null && notificationContacts.length > 0 ? notificationContacts : [ newContact ] 
            : [ ]
    }

    useEffect(() => {
        if (member != null) {
            dispatch(fetchNotificationContacts())
        }
    }, [dispatch, member])

    const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        if (props.additional) {
            // Store contacts locally for later submission
            dispatch(setCartNotificationContacts(values.notificationContacts))

        } else { 
            setSubmitting(true)
            if (await dispatch(saveNotificationContacts(values.notificationContacts))) {
                setMessage("Notification Student Saved")
            }
            setSubmitting(false)
        }
    }

    const handleSetMessage = (message: string | null) => {
        setMessage(message)
    }

    return (
        <Box style={{marginBottom: '2rem'}}>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {({ 
                    values,
                    dirty,
                    isSubmitting
                }) => (
                    <React.Fragment>
                        <Form>
                            <FieldArray
                                name="notificationContacts"
                                render={arrayHelpers => (
                                    <React.Fragment>
                                        <Grid container spacing={4} >
                                            {values.notificationContacts != null ? (
                                                <React.Fragment>
                                                    {values.notificationContacts.length > 0 && (
                                                        <React.Fragment>
                                                            <Grid item xs={3} style={{ paddingBottom: 0 }}>
                                                            </Grid>
                                                            <Grid item xs={3} style={{ paddingBottom: 0 }}>
                                                            </Grid>
                                                            <Grid item xs={3} style={{ paddingBottom: 0 }}>
                                                            </Grid>
                                                            <Grid item xs={1} style={{ paddingBottom: 0 }}>
                                                                <Box display="flex" flexDirection="row" justifyContent="center" alignContent="flex-end">Confirmation</Box>
                                                            </Grid>
                                                            <Grid item xs={1} style={{ paddingBottom: 0 }}>
                                                                <Box display="flex" flexDirection="row" justifyContent="center" alignContent="flex-end">Setup</Box>
                                                            </Grid>
                                                            <Grid item xs={1} style={{ paddingBottom: 0 }}>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )}
                                                    {values.notificationContacts.map((contact, index) => (
                                                        <React.Fragment key={index}>
                                                            <Grid item xs={3}>
                                                                <FormTextField
                                                                    name={`notificationContacts[${index}].firstName`}
                                                                    label="First Name"
                                                                    value={contact.firstName}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <FormTextField
                                                                    name={`notificationContacts[${index}].lastName`}
                                                                    label="Last Name"
                                                                    value={contact.lastName}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <FormTextField
                                                                    name={`notificationContacts[${index}].email`}
                                                                    label="Email"
                                                                    value={contact.email}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Box display="flex" flexDirection="row" justifyContent="center" alignContent="flex-end">
                                                                    <Field
                                                                        name={`notificationContacts[${index}].sendConfirmation`}
                                                                        type="checkbox"
                                                                        checked={contact.sendConfirmation}
                                                                        as={Checkbox}
                                                                        style={{ textAlign: 'center' }}
                                                                        className={classes.checkbox}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Box display="flex" flexDirection="row" justifyContent="center" alignContent="flex-end">
                                                                    <Field
                                                                        name={`notificationContacts[${index}].sendSetup`}
                                                                        type="checkbox"
                                                                        checked={contact.sendSetup}
                                                                        as={Checkbox}
                                                                        className={classes.checkbox}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <IconButton className={classes.closeButton} aria-label="remove" onClick={() => arrayHelpers.remove(index)}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                            ) : (
                                                <LoadingWidget />
                                            )}
                                        </Grid>
                                        { props.additional ? (
                                            <Box display="flex" justifyContent="flex-end" className={classes.addContactButton}>
                                                <Button variant="contained" color="primary" onClick={() => arrayHelpers.push(newContact)}>Add Student</Button>
                                            </Box>
                                        ) : (
                                            <Box display="flex" justifyContent="space-between" className={classes.addContactButton}>
                                                <Button style={{ justifySelf: "flex-start" }} variant="contained" color="primary" onClick={() => arrayHelpers.push(newContact)}>Add Student</Button>
                                                <Box>
                                                    <Button type="reset" className={classes.cancelButton}>Cancel</Button>
                                                    <Button type="submit" disabled={!dirty || isSubmitting} variant="contained" color="primary">Save</Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </React.Fragment>
                                )}
                            />
                        </Form>
                        <LocalAutoSubmitForm />
                    </React.Fragment>
                )} 
            </Formik>
            <MessageWidget message={message} onSetMessage={handleSetMessage}/>
            <ErrorWidget />
        </Box>
    )
}

NotificationContactsEditor.defaultProps = {
    additional: false
}

export default NotificationContactsEditor
