import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import Category from 'models/Category';
import { useStyles } from './CategoryCard.styles';

interface CategoryCardProps {
    category: Category
    onClick: () => void
}

const CategoryCard: React.FC<CategoryCardProps> = (props) => {
    const classes = useStyles()

    return (
        <Card className={classes.MuiPaper}>
            <CardActionArea onClick={props.onClick}>
                <CardMedia
                    component="img"
                    image={props.category.logo}
                    title={props.category.name}
                />
            </CardActionArea>
        </Card>
    )
}

export default CategoryCard
