import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Container, Typography } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import LoadingWidget from 'components/widgets/LoadingWidget'
import { fetchPrivateEvents, fetchPrivateEventsForCourse } from 'slices/privateEventsSlice'
import CourseEvent from 'models/CourseEvent'
import PrivateEventListView from 'components/privates/PrivateEventListView'
import AppHeader from 'components/widgets/AppHeader'

interface CourseParams {
    courseId?: string
}

interface PrivateEventPageProps { }

const PrivateEventPage: React.FC<PrivateEventPageProps> = (props) => {
    const dispatch = useThunkDispatch()
    const history = useHistory()
    const { courseId } = useParams<CourseParams>()
    const [loadingEvents, setLoadingEvents] = useState(false)

    const events = useSelector(
        (state: RootState) => state.privateEvents.events
    ) ?? []

    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )

    const custom = useSelector(
        (state: RootState) => state.content.custom
    )

    const customContent = custom != null ? custom["PrivateEventsPage"] : null;

    useEffect(() => {
        const loadAsync = async () => {
            setLoadingEvents(true)
            if (courseId) {
                await dispatch(fetchPrivateEventsForCourse(Number(courseId)))
            } else {
                await dispatch(fetchPrivateEvents())
            }
            setLoadingEvents(false)
        }
        loadAsync()
    }, [dispatch, courseId])

    const handleEnrollEvent = (event: CourseEvent, waitList?: boolean) => {
        const course = null
        history.push("/order", { item: { course, event, waitList } })
    }

    const handleOtherDateRequest = () => {
        history.push("/requests/course", { item: {type: "OtherDate"} })
    }

    return (
        <>
            <AppHeader style={(customContent && customContent.backgroundImage)?{backgroundImage:'url('+customContent.backgroundImage+')'}:{}}>
                <Typography style={{ textAlign: 'center' }} variant="h3">Private Events</Typography>
            </AppHeader>
            <Box component="main">
                <Container maxWidth='lg' fixed={true} style={{ display: 'flex', flexDirection: 'column' }}>
                    {customContent != null && (
                        customContent && customContent.content ? (
                            <Box dangerouslySetInnerHTML={{ __html: customContent.content }} />
                        ) : (
                            <LoadingWidget />
                        )
                    )}
                    {loadingEvents ? (
                        <LoadingWidget />
                    ) : (
                        <PrivateEventListView events={events} eventCount={events.length} onSelectClick={handleEnrollEvent} requestOtherDateEmail={settings.reseller.requestOtherDateEmail} onRequestDateClick={handleOtherDateRequest}/>
                    )}
                </Container>
            </Box>
        </>
    )
}

export default PrivateEventPage
