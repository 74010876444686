import React, { useEffect, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { Box, Button, Grid, MenuItem, Typography } from '@material-ui/core'

import { useThunkDispatch } from 'app/store'
import { useStyles } from './ContactForm.styles'
import { RootState } from 'app/rootReducer'
import { fetchCountries, fetchStatesForCountry } from 'slices/lookupsSlice'
import FormTextField from 'components/widgets/form/FormTextField'
import FormSelect from 'components/widgets/form/FormSelect'
import FormValues from './ContactFormTypes'
import { pascalCase } from 'utils/pascalCase'
import CountryState from 'models/CountryState'
import {stateFieldForCountry} from 'utils/addressUtils'

interface ContactAddressProps {
    name?: string
    prefix: string
    country?: string
    stateProvince?: string
    isMe: boolean
}

const ContactAddress: React.FC<ContactAddressProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const { handleChange, setFieldValue } = useFormikContext<FormValues>()
    const countries = useSelector(
        (state: RootState) => state.lookups.countries
    )
    const statesForCountry = useSelector(
        (state: RootState) => state.lookups.statesForCountry
    )
    const settings = useSelector(
        (state: RootState) => state.lookups.settings
    )
    const member = useSelector(
        (state: RootState) => state.member.member
    )

    useEffect(() => {
        console.log("Loading countries...")
        dispatch(fetchCountries())
    }, [dispatch])

    useEffect(() => {
        if (props.country != null && props.country.length > 0) {
            console.log("Loading states for", props.country)
            dispatch(fetchStatesForCountry(props.country))
        }
    }, [dispatch, props.country])

    const handleCountryChange = (event: ChangeEvent<any>) => {
        setFieldValue(`${props.prefix}.state`, "") 

        // Let formik do its thing
        handleChange(event)
    }
   
    const handleUseMyAddressButton = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        if (member != null) {
            const contact = member.contact.addresses.contact ?? member.contact.addresses.billing

            if (contact) {
                setFieldValue("contact.street", contact.street)
                setFieldValue("contact.city", contact.city)
                setFieldValue("contact.state", contact.state)
                setFieldValue("contact.province", contact.province)
                setFieldValue("contact.country", contact.country)
                setFieldValue("contact.zip", contact.zip)
            }
        }
    }

    const stateField = stateFieldForCountry(props.country)
    const states: CountryState = props.country ? (statesForCountry[props.country] ? statesForCountry[props.country] : {}) : {} 

    return (
        <React.Fragment>
            {props.name ? 
                <Box className={classes.title} display="flex" flexDirection="row">
                    <Typography variant="h6" color="primary">{props.name}</Typography>
                    {settings.contact.enableCopyAddress && member && !props.isMe &&
                        <Button className={classes.useMyAddressButon} variant="outlined" color="primary" onClick={() => handleUseMyAddressButton(setFieldValue)}>Use My Address</Button>
                    }
                </Box>
                :
                <Box style={{ padding: 6 }}>&nbsp;</Box>
            }
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                    <FormTextField label="Street" name={`${props.prefix}.street`} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormTextField label="City" name={`${props.prefix}.city`} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    {Object.keys(states).length > 0 ? (
                        <FormSelect
                            id={`${props.prefix}.${stateField}`}
                            label={pascalCase(stateField)}
                            name={`${props.prefix}.${stateField}`}
                            value={props.stateProvince}
                        >
                            {Object.keys(states).map(key =>
                                <MenuItem key={key} value={key}>{states[key]}</MenuItem>
                            )}
                        </FormSelect>
                    ) : ( props.country &&
                        <FormTextField 
                            name={`${props.prefix}.other`}
                            label="State"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {Object.keys(countries).length > 0 &&
                        <FormSelect
                            id={`${props.prefix}.country`}
                            label="Country"
                            name={`${props.prefix}.country`}
                            value={props.country}
                            onChange={handleCountryChange}
                        >
                            {Object.keys(countries).map(key =>
                                <MenuItem key={key} value={key}>{countries[key]}</MenuItem>
                            )}
                        </FormSelect>
                    }
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormTextField label="Postal / Zip Code" name={`${props.prefix}.zip`} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ContactAddress
