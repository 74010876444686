import RestUtils from 'utils/RestUtils'
import Order from 'models/Order'

export interface OrderResponse extends Order { }
export interface OrderParams {
    courseID?: number
    eventID?: number
    bundleID?: number
    contactIDs?: number[]
    waitList?: boolean
}

export interface PromoCodeParams extends Omit<OrderParams, "contactIDs"> {
    promoCode: string
}
    
export async function getOrder(currency?: string): Promise<OrderResponse> {
    const url = currency ? `/orders?currency=${currency}`: "/orders"

    const { data } = await RestUtils.get<OrderResponse>(url)

    return data
}

export async function createOrder(params: OrderParams): Promise<OrderResponse> {
    const url = "/orders"

    const { data } = await RestUtils.post<OrderResponse>(url, params)

    return data
}

export async function deleteFromOrder(params: OrderParams): Promise<OrderResponse> {
    const url = "/orders"

    const { data } = await RestUtils.delete<OrderResponse>(url, params)

    return data
}

export async function createPromoCode(params: PromoCodeParams): Promise<OrderResponse> {
    const url = "/orders/promocode"

    const { data } = await RestUtils.post<OrderResponse>(url, params)

    return data
}

export async function deletePromoCode(params: OrderParams): Promise<OrderResponse> {
    const url = "/orders/promocode"

    const { data } = await RestUtils.delete<OrderResponse>(url, params)

    return data
}

export async function getOrderFinish(orderId?: string, orderHash?: string): Promise<OrderResponse> {
    let url = "/orders/finish"
    
    if (orderId) {
        url = `${url}/${orderId}`
    }
    
    if (orderHash) {
        url = `${url}/${orderHash}`
    }

    const { data } = await RestUtils.get<OrderResponse>(url)

    return data
}
