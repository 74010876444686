import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'

import { setError } from './errorSlice'
import { changePassword, login, logout, getMembers, LoginResponse, getNotificationContacts, postNotificationContacts, NotificationContactsRequest, NotificationContactsResponse } from 'api/memberApi'
import Member from 'models/Member'
import NotificationContact from 'models/NotificationContact'


interface MemberState {
    member: Member | null
    notificationContacts: NotificationContact[] | null
}

const initialState: MemberState = {
    member: null as Member | null,
    notificationContacts: null as NotificationContact[] | null
}

const memmberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        setMember(state, action: PayloadAction<LoginResponse | null>) {
            state.member = action.payload
        },
        setNotificationContacts(state, action: PayloadAction<NotificationContactsResponse[]>) {
            state.notificationContacts = action.payload
        }
    }
})

export const { setMember, setNotificationContacts } = memmberSlice.actions

export default memmberSlice.reducer

export const fetchMembers = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const member = await getMembers()
        dispatch(setMember(member))
    } catch (err) {
        console.log("fetchMembers", err.toString())
        if (err.response && err.response.status !== 422){
            dispatch(setError(err.toString()))
        }
    }
}

export const performChangePassword = (currentPassword: string, newPassword: string): AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await changePassword(currentPassword, newPassword)
        return true
    } catch (err) {
        dispatch(setError(err.toString()))
        return false
    }
}

export const performLogin = (userName: string, password: string): AppThunk<Promise<void>> => async dispatch => {
    try {
        const member = await login({Email: userName, Password: password})
        dispatch(setMember(member))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const performLogout = (): AppThunk<Promise<void>>=> async dispatch => {
    try {
        await logout()
        dispatch(setMember(null))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const fetchNotificationContacts = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const contacts = await getNotificationContacts()
        dispatch(setNotificationContacts(contacts))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}

export const saveNotificationContacts = (notificationContacts: NotificationContactsRequest[]): AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const contacts = await postNotificationContacts(notificationContacts)
        dispatch(setNotificationContacts(contacts))       
        return true
    } catch (err) {
        dispatch(setError(err.toString()))
        return false
    }
}
