import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const contactDrawerWidth = 450

export const useStyles = makeStyles((theme: Theme) => createStyles({
    drawer: {
        flexShrink: 0,
    },
    topBar: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        position: 'sticky', top: 0, zIndex: 1, backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(16),
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.grey[400]}`,  
    },
    content: {
        width: contactDrawerWidth,
        paddingTop: 0,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },  
    buttonBar: {
        display: "flex",
        justifyContent: "space-between",
        position: 'sticky', bottom: 0, zIndex: 1, backgroundColor: theme.palette.background.default,
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3)
    },
    leftButton: {
        marginLeft: -8
    },
    rightButton: {
        marginLeft: 20
    }
}))
