import React from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './AppCustomFooter.styles'

export interface AppFooterProps { }

const AppCustomFooter: React.FC<AppFooterProps> = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            {props.children}
        </Box>
    )
}

export default AppCustomFooter
