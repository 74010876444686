import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { PrivateCoursesListResponse, getPrivateCourses } from 'api/privateCoursesApi'
import { setError } from './errorSlice'

export type PrivateCoursesState = PrivateCoursesListResponse | null

const initialState: PrivateCoursesState = {
    courses: []
}

const privateCoursesSlice = createSlice({
    name: 'privateCourses',
    initialState,
    reducers: {
        setPrivateCourses(state: PrivateCoursesState, action: PayloadAction<PrivateCoursesListResponse>) {
            return action.payload
        }
    }
})

const { setPrivateCourses } = privateCoursesSlice.actions

export default privateCoursesSlice.reducer

export const fetchPrivateCourses = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        const courses = await getPrivateCourses()
        dispatch(setPrivateCourses(courses))
    } catch (err) {
        dispatch(setError(err.toString()))
    }
}
