import React from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form, FormikHelpers } from 'formik'
import { Box } from '@material-ui/core'
import * as yup from 'yup'

import { useStyles } from './VendorPaymentForm.styles'
import { useThunkDispatch } from 'app/store'
import { RootState } from 'app/rootReducer'
import AutoSubmitForm from 'components/widgets/form/AutoSubmitForm'
import FormStatusLogger from 'components/widgets/form/FormStatusLogger'
import FormTextField from 'components/widgets/form/FormTextField'
import { createPayment } from 'slices/paymentSlice'
import { ContactMeBilling } from 'models/Billing'
import YourContactInfoForm, { YourContactInfoValues } from './YourContactInfoForm'
import NotificationContact from 'models/NotificationContact'
import NotificationContactsInjector from 'components/members/NotificationContactsInjector'

interface ContactMeValues {
    notes: string
}

interface FormValues {
    me: YourContactInfoValues
    contactMe: ContactMeValues
    notificationContacts: NotificationContact[] | null
}

interface ContactMePaymentFormProps { }

const ContactMePaymentForm: React.FC<ContactMePaymentFormProps> = (props) => {
    const classes = useStyles()
    const dispatch = useThunkDispatch()
    const member = useSelector(
        (state: RootState) => state.member.member
    )
    const notificationContacts = useSelector(
        (state: RootState) => state.cartSubmit.notificationContacts
    )
    const contacts = useSelector(
        (state: RootState) => state.contacts
    )

    let validationSchema = yup.object({
        contactMe: yup.object({
        })
    })

    let me = {
        firstName: "",
        lastName: "",
        email: ""
    }

    if (member == null && contacts != null) {
        const filteredContacts = contacts.filter(contact => contact.isMe)

        if (filteredContacts.length > 0) {
            me = {
                firstName: filteredContacts[0].firstName,
                lastName: filteredContacts[0].lastName,
                email: filteredContacts[0].email
            }

            const validateMe = yup.object({
                me: yup.object({
                    firstName: yup.string().required("First Name is a required field."),
                    lastName: yup.string().required("Last Name is a required field."),
                    email: yup.string().required("Email is a required field.")
                })
            })

            validationSchema = validationSchema.concat(validateMe)
        }
    }

    const initialValues: FormValues = {
        me: me,
        contactMe: {
            notes: ""
        },
        notificationContacts: notificationContacts
    }

    const handleCartSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        setSubmitting(true)

        const billing: ContactMeBilling = member == null ? 
            Object.assign({}, values.contactMe, { me: values.me, notificationContacts: values.notificationContacts, paymentType: "OTHER" }) :
            Object.assign({}, values.contactMe, { notificationContacts: values.notificationContacts, paymentType: "OTHER" })

        await dispatch(createPayment(billing))
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCartSubmit}
        >
            {({
                values,
                errors
            }) => {
                return (
                    <React.Fragment>
                        <Form>
                            <YourContactInfoForm />
                            <Box className={classes.root}>
                                <Box className={classes.commentSection}>
                                    <FormTextField
                                        name="contactMe.notes"
                                        placeholder="Additional training requirements or comments"
                                        multiline={true}
                                    />
                                </Box>
                            </Box>
                            <NotificationContactsInjector />
                        </Form>
                        <AutoSubmitForm />
                        <FormStatusLogger values={values} errors={errors} />
                    </React.Fragment>
                )
            }}
        </Formik>
    )
}

export default ContactMePaymentForm
